import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vueDebounce from "vue-debounce";
import axios from "axios";
import SuiVue from "semantic-ui-vue";
import "semantic-ui-css/semantic.min.css";
import Dayjs from "vue-dayjs";
import VueSimpleAlert from "vue-simple-alert";
import VueToast from "vue-toast-notification";
import VueApexCharts from "vue-apexcharts";

import DefaultLayout from "./layouts/DefaultLayout";
import FullPageLayout from "./layouts/FullPageLayout";
import Warning from "./components/global/Warning.vue";

import { namespaced } from "./store/utils";
import { NS_USER } from "./store/namespace.names";
import { GET_TOKEN_FROM_LOCAL_STORE } from "./store/action.names";
import { GET_TOKEN, GET_PROFILE } from "./store/getter.names";
import { SET_SEARCH_TEXT } from "./store/mutation.names";

import Vue2Editor from "vue2-editor";
import "vue-toast-notification/dist/theme-sugar.css";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/en";
import moment from "moment";

Vue.component("default-layout", DefaultLayout);
Vue.component("fullpage-layout", FullPageLayout);
Vue.component("warning", Warning);
Vue.component("datepicker", DatePicker);
Vue.component("apexchart", VueApexCharts);

Vue.use(vueDebounce);
Vue.use(SuiVue);
Vue.use(Dayjs);
Vue.use(VueSimpleAlert);
Vue.use(Vue2Editor);
Vue.use(VueToast);
Vue.use(VueApexCharts);

// custom datetime formatter
Vue.filter("formatDate", function (value) {
	if (value) {
		return moment(String(value)).format("lll");
	}
});

window.axios = axios;

store.dispatch(namespaced(NS_USER, GET_TOKEN_FROM_LOCAL_STORE));
router.beforeEach((to, from, next) => {
	const loggedIn = store.getters[namespaced(NS_USER, GET_TOKEN)] != null;
	if (to.meta?.loginRequired) {
		if (loggedIn) {
			next();
		} else {
			next("/login");
		}
	} else {
		next();
	}
});

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
