<template>
	<div class="single-table-row">
		<div>
			{{ res[0] }}
		</div>
		<div>
			<div class="col-header">Price value</div>
			<div class="col-value">{{ res[1] }} /-</div>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Icon } from "@iconify/vue2";
import { namespaced } from "../../../store/utils";
import { API_HOST } from "../../../global";

@Component({
	name: "CardOrderProductItem",
	components: {
		Icon,
	},
})
export default class CardOrderProductItem extends Vue {
	HOST = API_HOST;
	@Prop() res;
}
</script>

<style scoped lang="scss">
.single-table-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	min-height: 40px;
	text-decoration: none;
	background: #f2f6ff;
	padding: 15px;
	border-radius: 10px;
	margin: 15px 0px;
	div {
		// display: flex;
		// justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 16px;
		color: #12233a;
		.col-header {
			color: #74777e;
			text-align: center;
			padding: 5px 0;
		}
		.col-value {
			text-align: center;
			padding: 5px 0;
			font-weight: 600;
		}
	}
}
</style>
