<template>
	<div class="wrapper">
		<div class="">
			<Sidebar />
		</div>
		<div class="body-wrapper">
			<Topbar />
			<slot />
		</div>
		<!-- <Footer /> -->
	</div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/Sidebar";
import Topbar from "@/components/Topbar";
// import Footer from "@/components/Footer";

@Component({
	name: "DefaultLayout",
	components: {
		Sidebar,
		Topbar,
		// Footer
	},
})
export default class DefaultLayout extends Vue {
	get layout() {
		return this.$route.meta.layout;
	}
}
</script>

<style scoped lang="scss">
.wrapper {
	display: flex;
}
.body-wrapper {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	overflow-y: scroll;
	padding-left: 20px;
	padding-right: 70px;
	padding-top: 40px;
}
</style>
