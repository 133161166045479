<template>
	<div id="app">
		<component :is="layout">
			<router-view></router-view>
		</component>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "./store/utils";
import { NS_SETTINGS, NS_USER } from "./store/namespace.names";
import { SETTINGS } from "./store/action.names";
import { GET_TOKEN, GET_PROFILE } from "./store/getter.names";
import { SET_TOKEN_ERROR, SET_SEARCH_TEXT } from "./store/mutation.names";

@Component({
	name: "App",
	components: {},
})
export default class App extends Vue {
	@Getter(namespaced(NS_USER, GET_TOKEN)) token;
	@Getter(namespaced(NS_USER, GET_PROFILE)) profile;

	@Mutation(namespaced(NS_USER, SET_TOKEN_ERROR)) setTokenError;
	@Mutation(namespaced(NS_USER, SET_SEARCH_TEXT)) setSearchText;

	get layout() {
		return this.$route.meta.layout;
	}

	@Watch("$route")
	handleRouteChange(val, oldVal) {
		if (val.name != oldVal.name) {
			this.setSearchText("");
		}
	}

	@Watch("token")
	hadleTokenChange(val, oldVal) {
		if (val != oldVal) {
			if (val == null) {
				this.setTokenError();
				if (this.$route.meta.loginRequired === true) {
					this.$router.push("/login");
				}
			}
		}
	}

	@Watch("profile")
	handleChangeProfile(val, oldVal) {
		if (val != oldVal) {
			if (val?.is_superuser || val?.is_staff) {
			} else {
				this.setTokenError();
				if (this.$route.meta.loginRequired === true) {
					this.$router.push("/login");
				}
			}
		}
	}
}
</script>

<style lang="scss">
@import "./assets/css/style.scss";
</style>
