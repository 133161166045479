<template>
	<div class="table-container" v-if="results.length > 0">
		<div class="c-table">
			<div class="c-head">
				<div>Image</div>
				<div>Name</div>
				<div></div>
			</div>
			<!-- <sui-divider /> -->
			<div class="table-body-container" v-if="results">
				<BrandTableSingleRow
					class="table-row"
					v-for="(res, idx) in results"
					:key="'res_' + idx"
					:res="res"
					@brandDelete="$emit('brandDelete')"
				/>
			</div>
		</div>
	</div>
	<!-- <div v-else class="empty-wishlist">
    <img  src="@/assets/img/empty_cart.svg">
    <p>Not any order found!</p>
  </div> -->
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Icon } from "@iconify/vue2";
import { API_HOST } from "../../../global";
import BrandTableSingleRow from "./BrandTableSingleRow";

@Component({
	name: "TableBrand",
	components: {
		Icon,
		BrandTableSingleRow,
	},
})
export default class TableBrand extends Vue {
	@Prop() results;
	HOST = API_HOST;
}
</script>

<style scoped lang="scss">
.table-container {
	.c-table {
		width: 100%;
		border: 1px solid #a4a4a4;
		box-sizing: border-box;
		border-radius: 20px;
		background-color: #f7f7f7;
		.c-head {
			display: grid;
			grid-template-columns: 2fr 2fr 2fr;
			gap: 10px;
			height: 60px;
			background-color: black;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			div {
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 600;
				font-size: 18px;
				color: white;
			}
		}
		.table-body-container {
			.table-row {
				.single-table-row {
					display: grid;
					grid-template-columns: 2fr 2fr 2fr;
					gap: 10px;
					min-height: 40px;
					border-bottom: 1px solid grey;
					text-decoration: none;
					// &:hover{
					//   background-color: #DEE9FE;
					//   cursor: pointer;
					// }
					div {
						display: flex;
						justify-content: center;
						align-items: center;
						font-weight: 400;
						font-size: 16px;
						color: #12233a;
					}
					.image-name {
						display: flex;
						justify-content: flex-start;
						gap: 10px;
						padding: 10px;
						.image {
							width: 60px;
							height: 60px;
							img {
								height: 100%;
								width: 100%;
							}
						}
					}
					.action-column {
						position: relative;
						&:hover {
							.action-dropdown {
								display: block;
							}
						}
						.action-dropdown {
							width: 136px;
							z-index: 999;
							position: absolute;
							display: none;
							top: 30px;
							background: #f7f7f7;
							border: 1px solid #a4a4a4;
							box-sizing: border-box;
							box-shadow: 0px 4px 10px rgba(162, 162, 162, 0.25);
							border-radius: 6px;
							.dropdown-item {
								font-size: 14px;
								font-weight: 600;
								color: #62b37c;
								text-align: left;
								display: flex;
								justify-content: flex-start;
								padding: 10px;
								padding-top: 15px;
								padding-bottom: 15px;
								padding-left: 15px;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}
}

.detail-link {
	display: none !important;
}

// .single-table-row{
//   &:hover{
//     background-color: #DEE9FE;
//     cursor: pointer;
//     .detail-link{
//       display: flex !important;
//     }
//   }
// }
.icon-font {
	font-size: 25px;
}

.ui.divider {
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	border-top: 1px solid #a4a4a4 !important;
}
.active {
	color: black !important;
}
.order-number {
	font-size: 14px !important;
}
.empty-wishlist {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	img {
		height: 100%;
	}
	p {
		margin-top: 30px;
		font-size: 18px;
		font-weight: 300;
	}
}
.icon {
	margin: 0px !important;
	padding: 0px !important;
	margin-top: -10px !important;
	cursor: pointer;
}
</style>
