import { GET_AUTH_HEADER } from "../getter.names";
import {
	FETCH_BUSINESS_ANALYTICS_SUMMARY,
	FETCH_PRODUCT_ANALYTICS_SUMMARY,
	OUT_OF_STOCK_PRODUCT,
	FETCH_ORDER_ANALYTICS_SUMMARY,
	FETCH_FINANCE_ANALYTICS_SUMMARY,
	FETCH_CUSTOMER_ANALYTICS_SUMMARY,
	FETCH_DASHBOARD_ANALYTICS_SUMMARY,
} from "../action.names";
import {
	ADMIN_ANALYTICS_ENDPOINT,
	ADMIN_OUT_OF_STOCK_ENDPOINT,
} from "../endpoints";
import {} from "../mutation.names";
import { namespaced, buildParams } from "../utils";
import { NS_USER } from "../namespace.names";

export default {
	namespaced: true,
	state: {},
	getters: {},
	actions: {
		async [FETCH_BUSINESS_ANALYTICS_SUMMARY]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${ADMIN_ANALYTICS_ENDPOINT}/business_analytics_summary/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [FETCH_PRODUCT_ANALYTICS_SUMMARY]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${ADMIN_ANALYTICS_ENDPOINT}/product_analytics_summary/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [OUT_OF_STOCK_PRODUCT]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${ADMIN_OUT_OF_STOCK_ENDPOINT}/${buildParams(payload)}`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [FETCH_ORDER_ANALYTICS_SUMMARY]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${ADMIN_ANALYTICS_ENDPOINT}/order_analytics_summary/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [FETCH_FINANCE_ANALYTICS_SUMMARY]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${ADMIN_ANALYTICS_ENDPOINT}/finance_analytics_summary/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [FETCH_CUSTOMER_ANALYTICS_SUMMARY]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${ADMIN_ANALYTICS_ENDPOINT}/customer_analytics_summary/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [FETCH_DASHBOARD_ANALYTICS_SUMMARY](
			{ commit, rootGetters },
			payload
		) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${ADMIN_ANALYTICS_ENDPOINT}/dashboard_analytics_summary/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
	},
	mutations: {},
};
