import { API_HOST } from "../global";

export const API_ROOT = `${API_HOST}/api/v1`;
export const PAGE_API_ROOT = `${API_HOST}/api/v2/pages/`;
export const PUBLIC_API_ROOT = `${API_ROOT}/public`;
export const ADMIN_API_ROOT = `${API_ROOT}/admin`;
export const ACCOUNT_API_ROOT = `${API_HOST}/account/api`;

export const REGISTRATION_ENDPOINT = `${ACCOUNT_API_ROOT}/signup/`;
export const LOGIN_ENDPOINT = `${ACCOUNT_API_ROOT}/signin/`;
export const PROFILE_ENDPOINT = `${ACCOUNT_API_ROOT}/profile/`;

export const ADMIN_GENERAL_ENDPOINT = `${ADMIN_API_ROOT}/general/`;
export const ADMIN_USER_ENDPOINT = `${ADMIN_API_ROOT}/users/`;
export const FETCH_CONTACT_ENDPOINT = `${ADMIN_API_ROOT}/contacts/`;

export const FETCH_PRODUCT_ENDPOINT = `${ADMIN_API_ROOT}/products/`;
export const FETCH_CATEGORY_ENDPOINT = `${ADMIN_API_ROOT}/categories/`;
export const FETCH_BRAND_ENDPOINT = `${ADMIN_API_ROOT}/brands/`;

export const FETCH_ATTRIBUTE_ENDPOINT = `${ADMIN_API_ROOT}/attributes/`;
export const FETCH_ATTRIBUTE_VALUE_ENDPOINT = `${ADMIN_API_ROOT}/attribute/values/`;

// order endpoints
export const FETCH_ORDER_ENDPOINT = `${ADMIN_API_ROOT}/orders/`;
export const FETCH_WARRANTY_OR_RETURN_ENDPOINT = `${ADMIN_API_ROOT}/warranty-or-returns/`;

export const FETCH_CAMPAIGNS_ENDPOINT = `${ADMIN_API_ROOT}/collections/`;
export const FETCH_COUPONS_ENDPOINT = `${ADMIN_API_ROOT}/coupons/`;
export const FETCH_JOBS_ENDPOINT = `${ADMIN_API_ROOT}/jobs/`;
export const FETCH_REVIEW_ENDPOINT = `${ADMIN_API_ROOT}/reviews/`;
export const FETCH_IMAGE_ENDPOINT = `${ADMIN_API_ROOT}/wg-images/`;

ADMIN_GENERAL_ENDPOINT;

export const ADMIN_VARIANT_UPDATE_ENDPOINT = `${ADMIN_API_ROOT}/variants/`;
export const ADMIN_ANALYTICS_ENDPOINT = `${ADMIN_API_ROOT}/analytics`;
export const ADMIN_OUT_OF_STOCK_ENDPOINT = `${ADMIN_API_ROOT}/out-of-stocks`;
