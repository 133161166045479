import { GET_AUTH_HEADER } from "../getter.names";
import { Vue } from "vue-property-decorator";

import {
	FETCH_PRODUCTS,
	FETCH_PRODUCT_DETAIL,
	PRODUCT_UPDATE,
	CHANGE_IS_PUBLISH,
	CHANGE_IN_STOCKED,
	DELETE_PRODUCT,
	//category
	FETCH_CATEGORY,
	FETCH_CATEGORY_DETAIL,
	CREATE_CATEGORY,
	UPDATE_CATEGORY,
	DELETE_CATEGORY,
	// brand
	FETCH_BRAND,
	FETCH_ALL_BRAND,
	FETCH_BRAND_DETAIL,
	ADD_BRAND,
	UPDATE_BRAND,
	DELETE_BRAND,
	// attribute
	FETCH_ATTRIBUTE_ALL,
	FETCH_ATTRIBUTE,
	FETCH_ATTRIBUTE_DETAIL,
	DELETE_ATTRIBUTE,
	UPDATE_ATTRIBUTE,
	// attribute value
	FETCH_ATTRIBUTE_VALUE,
	ADD_ATTRIBUTE_VALUE,
	DELETE_ATTRIBUTE_VALUE,
	UPDATE_ATTRIBUTE_VALUE,
	VARIANT_UPDATE,
} from "../action.names";
import {
	FETCH_PRODUCT_ENDPOINT,
	FETCH_CATEGORY_ENDPOINT,
	FETCH_BRAND_ENDPOINT,
	FETCH_ATTRIBUTE_ENDPOINT,
	FETCH_ATTRIBUTE_VALUE_ENDPOINT,
	ADMIN_VARIANT_UPDATE_ENDPOINT,
} from "../endpoints";
import {} from "../mutation.names";
import { namespaced, buildParams } from "../utils";
import { NS_USER } from "../namespace.names";

export default {
	namespaced: true,
	state: {},
	getters: {},
	actions: {
		async [FETCH_PRODUCTS]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				let relativeUrl = `${FETCH_PRODUCT_ENDPOINT}${buildParams(payload)}`;
				if (payload.in_stock) {
					relativeUrl = `${FETCH_PRODUCT_ENDPOINT}${buildParams(
						payload
					)}&in_stock=${payload.in_stock}`;
				}

				axios
					.get(relativeUrl, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [FETCH_PRODUCT_DETAIL]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_PRODUCT_ENDPOINT}${payload.id}`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [PRODUCT_UPDATE]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.patch(`${FETCH_PRODUCT_ENDPOINT}${payload.id}/`, payload.formData, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [CHANGE_IS_PUBLISH]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.post(
						`${FETCH_PRODUCT_ENDPOINT}${payload.id}/change_is_publish/`,
						payload.data,
						{
							headers: {
								...authHeader,
							},
						}
					)
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [CHANGE_IN_STOCKED]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.post(
						`${FETCH_PRODUCT_ENDPOINT}${payload.id}/change_in_stocked/`,
						payload.data,
						{
							headers: {
								...authHeader,
							},
						}
					)
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [DELETE_PRODUCT]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.delete(`${FETCH_PRODUCT_ENDPOINT}${payload.id}/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		//category
		async [FETCH_CATEGORY]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_CATEGORY_ENDPOINT}${buildParams(payload)}`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [CREATE_CATEGORY]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.post(`${FETCH_CATEGORY_ENDPOINT}`, payload, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						Vue.$toast.success("Category Created successfully!", {});
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [FETCH_CATEGORY_DETAIL]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_CATEGORY_ENDPOINT}${payload.id}`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [UPDATE_CATEGORY]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.patch(`${FETCH_CATEGORY_ENDPOINT}${payload.id}/`, payload.formData, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						Vue.$toast.success("Category Updated successfully!", {});
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [DELETE_CATEGORY]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.delete(`${FETCH_CATEGORY_ENDPOINT}${payload.id}/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						Vue.$toast.error("Category deleted successfully!", {});
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		// brand actions start
		async [FETCH_BRAND]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_BRAND_ENDPOINT}${buildParams(payload)}`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [FETCH_ALL_BRAND]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_BRAND_ENDPOINT}all_brand/${buildParams(payload)}`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [FETCH_BRAND_DETAIL]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_BRAND_ENDPOINT}${payload.id}`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [ADD_BRAND]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.post(`${FETCH_BRAND_ENDPOINT}`, payload, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						Vue.$toast.success("Brand Created successfully!", {});
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [DELETE_BRAND]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.delete(`${FETCH_BRAND_ENDPOINT}${payload.id}/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						Vue.$toast.error("Brand deleted successfully!", {});
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [UPDATE_BRAND]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.patch(`${FETCH_BRAND_ENDPOINT}${payload.id}/`, payload.formData, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						Vue.$toast.success("Brand Updated successfully!", {});
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		// brand actions end

		// attribute actions
		async [FETCH_ATTRIBUTE]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_ATTRIBUTE_ENDPOINT}${buildParams(payload)}`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [FETCH_ATTRIBUTE_ALL]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_ATTRIBUTE_ENDPOINT}all_attr/${buildParams(payload)}`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [FETCH_ATTRIBUTE_DETAIL]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_ATTRIBUTE_ENDPOINT}${payload.id}/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [DELETE_ATTRIBUTE]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.delete(`${FETCH_ATTRIBUTE_ENDPOINT}${payload.id}/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [UPDATE_ATTRIBUTE]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.patch(`${FETCH_ATTRIBUTE_ENDPOINT}${payload.id}/`, payload.data, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [VARIANT_UPDATE]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.patch(
						`${ADMIN_VARIANT_UPDATE_ENDPOINT}${payload.id}/`,
						payload.data,
						{
							headers: {
								...authHeader,
							},
						}
					)
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		// attribute value actions start
		async [FETCH_ATTRIBUTE_VALUE]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_ATTRIBUTE_VALUE_ENDPOINT}${buildParams(payload)}`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [ADD_ATTRIBUTE_VALUE]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.post(`${FETCH_ATTRIBUTE_VALUE_ENDPOINT}`, payload, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						Vue.$toast.success("Attribute value created successfully!", {});
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [DELETE_ATTRIBUTE_VALUE]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.delete(`${FETCH_ATTRIBUTE_VALUE_ENDPOINT}${payload.id}/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						Vue.$toast.error("Attribute value deleted successfully!", {});
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [UPDATE_ATTRIBUTE_VALUE]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.patch(
						`${FETCH_ATTRIBUTE_VALUE_ENDPOINT}${payload.id}/`,
						payload.formData,
						{
							headers: {
								...authHeader,
							},
						}
					)
					.then(({ data }) => {
						Vue.$toast.success("Attribute value updated successfully!", {});
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		// attribute value actions end
	},
	mutations: {},
};
