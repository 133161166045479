<template>
	<div class="user-wrapper">
		<div class="top">
			<div class="left">
				<h3>Contact Messages ({{ count }})</h3>
			</div>
			<div class="right"></div>
		</div>
		<div class="content">
			<TableContact v-if="results" :results="results" />
			<div class="c-pagination" v-if="paginationSteps.length > 1">
				<a class="item" @click.prevent="handleDecrement"> Prev </a>
				<a
					:class="{ active: page == pag, item: true }"
					v-for="(pag, idx) in paginationSteps"
					:key="'pag_' + idx"
					@click.prevent="updatePage(pag)"
				>
					{{ pag }}
				</a>
				<a class="item" @click.prevent="handleIncreament"> Next </a>
			</div>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced, deepCopy } from "../../store/utils";
import { API_HOST } from "../../global";
import { NS_USER, NS_SETTINGS } from "../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../store/mutation.names";
import { FETCH_CONTACTS } from "../../store/action.names";
import { GET_SEARCH_TEXT } from "../../store/getter.names";
import { Icon } from "@iconify/vue2";
import TableContact from "../../components/tables/contact/TableContact.vue";
@Component({
	name: "PageMessages",
	components: {
		Icon,
		TableContact,
	},
})
export default class PageMessages extends Vue {
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;
	@Getter(namespaced(NS_USER, GET_SEARCH_TEXT)) getSearchText;
	@Action(namespaced(NS_SETTINGS, FETCH_CONTACTS)) fetchContacts;

	HOST = API_HOST;
	page = this.$route.query.page ? this.$route.query.page : 1;
	results = [];
	count = "";
	paginationSteps = [this.page];
	totalPageCount = "";
	current_route = "admin-messages";

	@Watch("$route")
	handleChangeRoute(val, oldVal) {
		this.page = this.$route.query.page ? this.$route.query.page : 1;
		if (val != oldVal) {
			this.fetchData();
			this.paginationSteps = [this.page];
		}
	}

	@Watch("getSearchText")
	handleChangeSearchText(val, oldVal) {
		if (val != oldVal) {
			this.fetchData();
			this.paginationSteps = [this.page];
			this.page = 1;
		}
	}

	handleIncreament() {
		if (this.page < this.totalPageCount) {
			var query = deepCopy(this.$route.query);
			query["page"] = parseInt(this.page) + 1;
			this.$router.push({ name: "admin-messages", query: query });
		} else {
			var query = deepCopy(this.$route.query);
			query["page"] = this.totalPageCount;
			this.$router.push({ name: "admin-messages", query: query });
		}
	}

	handleDecrement() {
		if (this.page > 1) {
			var query = deepCopy(this.$route.query);
			query["page"] = parseInt(this.page) - 1;
			this.$router.push({ name: "admin-messages", query: query });
		} else {
			var query = deepCopy(this.$route.query);
			query["page"] = 1;
			this.$router.push({ name: "admin-messages", query: query });
		}
	}

	calculateNext(totalPage) {
		if (totalPage > 1) {
			var next = this.page;
			for (var i = 0; i < totalPage; i++) {
				if (i > 3) {
					break;
				}
				if (this.page < totalPage) {
					next = parseInt(next) + 1;
					if (next > totalPage) {
						break;
					}
					this.paginationSteps.push(next);
				}
			}
		}
	}

	calculatePrev() {
		if (this.page > 1) {
			var counter = 0;
			for (var i = this.page - 1; i > 0; i--) {
				if (counter > 3) {
					break;
				}
				this.paginationSteps.unshift(i);
				counter += 1;
			}
		}
	}

	calculatePage() {
		const totalPage = Math.ceil(this.count / 15);
		this.totalPageCount = totalPage;
		if (totalPage > 1) {
			this.calculateNext(totalPage);
			this.calculatePrev();
		}
	}

	updatePage(pageNo) {
		this.$router.push(`/${this.current_route}?page=${pageNo}`);
	}

	fetchData() {
		let params = {
			page: this.page,
		};
		if (this.status) {
			params.status = this.status;
		}

		if (this.getSearchText.length > 0) {
			params["search"] = this.getSearchText;
		}

		this.fetchContacts(params).then((data) => {
			this.results = data.results;
			this.count = data.count;
			this.calculatePage();
		});
	}

	mounted() {
		this.setActiveSidebar("admin-messages");
		this.setActiveSubmenu("");
		this.fetchData();
	}
}
</script>

<style scoped lang="scss">
.content {
	margin-top: 30px;
}
.c-pagination {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	margin-top: 15px;
	a {
		color: #737373;
		font-size: 14px;
		font-weight: 600;
		cursor: pointer;
	}
}
.active {
	color: black !important;
}
</style>
