<template>
  <div class="user-wrapper">
    <div v-if="status == 'product-analytics'">
      <product-analytics></product-analytics>
    </div>
    <div v-else-if="status == 'customer-analytics'">
      <customer-analytics></customer-analytics>
    </div>
    <div v-else-if="status == 'order-analytics'">
      <order-analytics></order-analytics>
    </div>
    <div v-else-if="status == 'financial-analytics'">
      <financial-analytics></financial-analytics>
    </div>
    <div v-else>
      <business-analytics></business-analytics>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../../store/utils";
import { NS_USER, NS_ORDERS } from "../../store/namespace.names";
import {
  SET_ACTIVE_SIDEBAR,
  SET_ACTIVE_SUBMENU,
} from "../../store/mutation.names";
import { Icon } from "@iconify/vue2";
import BusinessAnalytics from "../../components/analytics/BusinessAnalytics.vue";
import CustomerAnalytics from "../../components/analytics/CustomerAnalytics.vue";
import FinancialAnalytics from "../../components/analytics/FinancialAnalytics.vue";

import OrderAnalytics from "../../components/analytics/OrderAnalytics.vue";

import ProductAnalytics from "../../components/analytics/ProductAnalytics.vue";

@Component({
  name: "PageAnalytics",
  components: {
    CustomerAnalytics,
    FinancialAnalytics,
    OrderAnalytics,
    ProductAnalytics,
    BusinessAnalytics,
    Icon,
  },
})
export default class PageAnalytics extends Vue {
  @Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
  @Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;

  current_route = "business-analytics";
  status = "";

  @Watch("$route")
  handleChangeRoute(val, oldVal) {
    this.setStatus();
  }

  setStatus() {
    let sub_route = "";
    if (this.$route) {
      sub_route = this.$route.params.slug;
    }
    if (sub_route && sub_route != "business-analytics") {
      this.status = sub_route;
      this.current_route = `business-analytics/${sub_route}`;
    } else {
      this.status = "";
      this.current_route = "business-analytics";
    }
  }

  mounted() {
    this.setActiveSidebar("business-analytics");
    this.setActiveSubmenu("");
    this.setStatus();
  }
}
</script>

<style scoped lang="scss"></style>
