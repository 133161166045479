<template>
	<div>
		<div @click.prevent="toggle" class="add-new-user-btn">
			<Icon class="add-user-icon" icon="material-symbols:add-box-outline" />
			Add New coupon
		</div>
		<sui-modal v-model="open_modal">
			<sui-modal-header class="modal-header">
				<div>Add Coupon</div>
				<div class="text-right">
					<Icon
						@click.native="toggle"
						class="add-user-icon"
						icon="charm:circle-cross"
					/>
				</div>
			</sui-modal-header>
			<sui-form class="custom-form">
				<sui-modal-content scrolling>
					<label>Coupon Name</label>
					<sui-form-field required>
						<input
							required
							v-model="formCoupon.name"
							placeholder="Coupon name"
						/>
					</sui-form-field>

					<label>Code</label>
					<sui-form-field required>
						<input v-model="formCoupon.code" placeholder="Coupon code" />
					</sui-form-field>

					<label>Price</label>
					<sui-form-field required>
						<input
							type="number"
							step="1"
							v-model="formCoupon.price"
							placeholder="Price"
						/>
					</sui-form-field>

					<label>Quantity</label>
					<sui-form-field required>
						<input
							type="number"
							step="1"
							v-model="formCoupon.quantity"
							placeholder="Quantity"
						/>
					</sui-form-field>

					<label>Start Date</label>
					<sui-form-field required>
						<datepicker
							v-model="formCoupon.start_date"
							type="datetime"
							valueType="format"
							:use12h="true"
						>
						</datepicker>
					</sui-form-field>

					<label>End Date</label>
					<sui-form-field required>
						<datepicker
							v-model="formCoupon.end_date"
							type="datetime"
							valueType="format"
							:use12h="true"
						>
						</datepicker>
					</sui-form-field>

					<sui-segment>
						<sui-form-field>
							<sui-checkbox
								toggle
								label="Coupon Activation Status"
								v-model="formCoupon.is_active"
							/>
						</sui-form-field>
					</sui-segment>
				</sui-modal-content>
				<sui-modal-actions>
					<sui-button
						color="teal"
						style="margin-top: 20px"
						floated="right"
						@click.prevent="handleCreateCoupon"
					>
						Save
					</sui-button>
				</sui-modal-actions>
			</sui-form>
		</sui-modal>
	</div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { Icon } from "@iconify/vue2";
import { API_HOST } from "../../../global";
import { namespaced } from "../../../store/utils";
import { NS_COUPONS } from "../../../store/namespace.names";
import { CREATE_COUPONS } from "../../../store/action.names";
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";

@Component({
	name: "ModalAddCoupon",
	components: {
		Icon,
		ValidationObserver,
		ValidationProvider,
	},
})
export default class ModalAddCoupon extends Vue {
	@Prop() res;

	@Action(namespaced(NS_COUPONS, CREATE_COUPONS)) createCoupons;
	HOST = API_HOST;
	open_modal = false;
	formCoupon = {
		name: "",
		code: "",
		price: "",
		quantity: "",
		is_active: true,
		start_date: this.$dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss"),
		end_date: this.$dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss"),
	};
	mounted() {}
	toggle() {
		this.open_modal = !this.open_modal;
	}

	handleCreateCoupon() {
		if (this.formCoupon.name == "") {
			Vue.$toast.error("You need to enter name!", {});
			return false;
		}
		if (this.formCoupon.code == "") {
			Vue.$toast.error("You need to enter code!", {});
			return false;
		}
		if (this.formCoupon.price == "") {
			Vue.$toast.error("You need to enter price!", {});
			return false;
		}
		if (this.formCoupon.quantity == "") {
			Vue.$toast.error("You need to enter quantity!", {});
			return false;
		}
		if (this.formCoupon.start_date == "") {
			Vue.$toast.error("You need to select start date!", {});
			return false;
		}
		if (this.formCoupon.end_date == "") {
			Vue.$toast.error("You need to select end date!", {});
			return false;
		}

		this.createCoupons(this.formCoupon).then((data) => {
			Vue.$toast.success("Coupon created successfully!", {});
			if (data) {
				this.toggle();
			}
		});
	}
}
</script>

<style scoped lang="scss">
.modal-header {
	display: grid !important;
	grid-template-columns: 6fr 6fr !important;
	gap: 10px;
	div {
		display: flex;
		font-size: 18px;
	}
	.text-right {
		justify-content: right;
		color: red;
	}
}
.add-new-user-btn {
	height: 36px;
	min-width: 150px;
	background-color: #edf1fe;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 10px;
	padding-right: 10px;
	color: #5c83f3;
	cursor: pointer;
	font-weight: 500;
	.add-user-icon {
		font-size: 20px;
		font-weight: 500;
		margin-right: 5px;
	}
	.status-display {
		margin-left: 10px;
		background: #5b83f3;
		padding: 5px 15px;
		border-radius: 10px;
		color: #f1f1f1;
	}
}
.custom-form {
	margin-top: 10px;
	display: grid;
	grid-template-columns: 12fr;
	padding: 20px 40px;
	label {
		color: black;
		margin-bottom: 5px;
		font-size: 16px;
	}
}
.mx-datepicker {
	width: 100%;
}
</style>
