<template>
	<div>
		<div class="sidebar">
			<div class="logo">
				<Icon icon="fa:angle-left" style="color: white; font-size: 26px" />
				<img src="@/assets/images/logo.png" />
			</div>
			<div class="main-sidebar" v-if="menus">
				<div
					v-for="(menu, idx) in menus"
					:key="'menu' + idx"
					:class="{ item: true, active: getActiveSidebar == menu.name }"
				>
					<div class="main-menu" @click.prevent="handleMainMenuClick(menu)">
						<Icon
							:icon="menu.icon"
							:class="{
								'icon-style-active': getActiveSidebar == menu.name,
								'icon-style-deactive': getActiveSidebar != menu.name,
							}"
						/>
						<div class="text">{{ menu.label }}</div>
					</div>
					<div
						class="submenu"
						v-if="menu.childs.length > 0 && getActiveSidebar == menu.name"
					>
						<div
							:class="{
								subitem: true,
								'active-subitem': getActiveSubmenu == child.name,
							}"
							v-for="(child, cidx) in menu.childs"
							:key="'child' + cidx"
							@click.prevent="handleSubmenuClick(child)"
						>
							<div class="text">{{ child.label }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../store/utils";
import { API_HOST } from "../global";
import { Icon } from "@iconify/vue2";
import { menus } from "../store/utils";
import { GET_ACTIVE_SIDEBAR, GET_ACTIVE_SUBMENU } from "../store/getter.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../store/mutation.names";
import { NS_USER } from "../store/namespace.names";

@Component({
	name: "Sidebar",
	components: {
		Icon,
	},
})
export default class Sidebar extends Vue {
	@Getter(namespaced(NS_USER, GET_ACTIVE_SIDEBAR)) getActiveSidebar;
	@Getter(namespaced(NS_USER, GET_ACTIVE_SUBMENU)) getActiveSubmenu;

	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;

	HOST = API_HOST;
	menus = menus;

	handleMainMenuClick(menu) {
		this.setActiveSidebar(menu.name);
		this.setActiveSubmenu("");
		this.$router.push(menu.link);
	}

	handleSubmenuClick(submenu) {
		this.setActiveSubmenu(submenu.name);
		this.$router.push(submenu.link);
	}

	isEmpty(obj) {
		return Object.keys(obj).length === 0;
	}
}
</script>

<style scoped lang="scss">
.sidebar {
	width: 303px;
	height: 100vh;
	background-color: #12233a;
	color: white;
	overflow: hidden;
	padding-bottom: 20px;
	overflow-y: scroll;
	// margin-bottom: 20px;
}
.logo {
	display: flex;
	gap: 40px;
	padding-left: 40px;
	padding-top: 44px;
	img {
		height: 27px;
		width: 128px;
	}
	.icon-left {
		cursor: pointer;
	}
}
.main-sidebar {
	min-height: 400px;
	margin-top: 60px;
	padding-left: 40px;
	padding-right: 10px;
	.active {
		background-color: #dee9fe;
		color: black !important;
	}
	.item {
		min-height: 48px;
		color: white;
		display: flex;
		flex-direction: column;
		padding-left: 20px;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		cursor: pointer;
		margin-top: 15px;
		margin-bottom: 15px;

		.main-menu {
			display: flex;
			gap: 20px;
			padding-top: 13px;
		}
		.submenu {
			.subitem {
				padding-left: 40px;
				padding-top: 20px;
				&:last-child {
					padding-bottom: 20px;
				}
			}
			.active-subitem {
				color: grey;
			}
		}

		.text {
			font-weight: 700;
			font-size: 18px;
		}
	}
}
.icon-style-deactive {
	color: white;
	font-size: 20px;
}
.icon-style-active {
	color: black;
	font-size: 20px;
}
</style>
