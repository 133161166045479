<template>
  <div class="table-container" v-if="results.length > 0">
    <div class="c-table">
      <div class="c-head">
        <div>
          Name
        </div>
        <div>
          Email
        </div>
        <div>
          Contact number
        </div>
        <div>
          User role
        </div>
        <div>
          HealthCoin
        </div>
        <div>
          Status
        </div>
        <div>
        </div>
      </div>
      <!-- <sui-divider /> -->
      <div class="table-body-container" v-if="results">
        <div 
          class="table-row"
          v-for="(res, idx) in results"
          :key="'res_' + idx"
        >
          <router-link
            :to="{name: 'user-detail', params: {id: res.id}}" 
            class="single-table-row"
          >
            <div>
              <span v-if="res.full_name">
                {{ res.full_name }}
              </span>
              <span v-else>
                Not yet set
              </span>
            </div>
            <div class="order-number">
              <span v-if="res.email">
                {{ res.email }}
              </span>
              <span v-else>
                Not yet set
              </span>
            </div>
            <div>
              <span v-if="res.phone">
                {{ res.phone }}
              </span>
              <span v-else>
                Not yet set
              </span>
            </div>
            <div>
              <span v-if="res.is_superuser || res.is_staff">
                <span v-if="res.is_superuser && res.is_staff">
                  Admin
                </span>
                <span v-if="res.is_staff && !res.is_superuser">
                  Staff
                </span>
              </span>
              <span v-else>
                Customer
              </span>
            </div>
            <div>
              <span v-if="res.supercoin">
                {{ res.supercoin }}
              </span>
              <span v-else>
                0
              </span>
            </div>
            <div>
              <span v-if="res.is_active">
                Active
              </span>
              <span v-else>
                Deactive
              </span>
            </div>
            <div class="detail-link">
              <Icon class="icon-font" icon="bi:arrow-right-circle-fill" />
            </div>
          </router-link>
        </div>  
      </div>
    </div>
  </div>
  <!-- <div v-else class="empty-wishlist">
    <img  src="@/assets/img/empty_cart.svg">
    <p>Not any order found!</p>
  </div> -->
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Icon } from '@iconify/vue2';
import { API_HOST } from "../../global";

@Component({
  name: "UserTable",
  components: {
    Icon,
  }
})
export default class UserTable extends Vue {
  @Prop() results;

  HOST = API_HOST;
}
</script>

<style scoped lang="scss">
  .table-container{
    .c-table{
      width: 100%;
      border: 1px solid #A4A4A4;
      box-sizing: border-box;
      border-radius: 20px;
      background-color: #F7F7F7;
      .c-head{
        display: grid;
        grid-template-columns: 1fr 1fr .5fr .5fr .5fr .5fr .1fr;
        gap: 10px;
        height: 60px;
        background-color: black;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        div{
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 18px;
          color: white;
        }
      }
      .table-body-container{
        .table-row{
          .single-table-row{
            display: grid;
            grid-template-columns: 1fr 1fr .5fr .5fr .5fr .5fr .1fr;
            gap: 30px;
            height: 40px;
            border-bottom: 1px solid grey;
            text-decoration: none;
            // &:hover{
            //   background-color: #DEE9FE;
            //   cursor: pointer;
            // }
            div{
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 400;
              font-size: 16px;
              color: #12233A;
            }
            .action-column{
              position: relative;
              &:hover{
                .action-dropdown{
                  display: block;                  
                }
              }
              .action-dropdown{
                width: 136px;
                z-index: 999;
                position: absolute;
                display: none;
                top: 30px;
                background: #F7F7F7;
                border: 1px solid #A4A4A4;
                box-sizing: border-box;
                box-shadow: 0px 4px 10px rgba(162, 162, 162, 0.25);
                border-radius: 6px;
                .dropdown-item{
                  font-size: 14px;
                  font-weight: 600;
                  color: #62B37C;
                  text-align: left;
                  display: flex;
                  justify-content: flex-start;
                  padding: 10px;
                  padding-top: 15px;
                  padding-bottom: 15px;
                  padding-left: 15px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }

  .detail-link{
    display: none !important;
  }

  .single-table-row{
    &:hover{
      background-color: #DEE9FE;
      cursor: pointer;
      .detail-link{
        display: flex !important;
      }
    }
  }
  .icon-font{
    font-size: 25px;
  }

  .ui.divider{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border-top: 1px solid #A4A4A4 !important;
  }
  .active{
    color: black !important;
  }
  .order-number{
    font-size: 14px !important;
  }
  .empty-wishlist{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    img{
      height: 100%;
    }
    p{
      margin-top: 30px;
      font-size: 18px;
      font-weight: 300;
    }
  }
  .icon{
    margin: 0px !important;
    padding: 0px !important;
    margin-top: -10px !important;
    cursor: pointer;
  }
</style>