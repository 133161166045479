<template>
	<div class="user-wrapper">
		<div class="top">
			<div class="left">Category ({{ results.length }})</div>
			<div class="right">
				<div class="add-new-user-btn">
					<Icon class="add-user-icon" icon="material-symbols:add-box-outline" />
					<modal-add-category @createdCategory="handleCreatedCategory">
					</modal-add-category>
				</div>
			</div>
		</div>
		<div class="content">
			<TableCategory
				v-if="results"
				:results="results"
				@categoryDelete="handleCategoryDelete"
			/>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../../../store/utils";
import { API_HOST } from "../../../global";
import { NS_USER, NS_PRODUCTS } from "../../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../../store/mutation.names";
import { FETCH_CATEGORY } from "../../../store/action.names";
import { Icon } from "@iconify/vue2";
import TableCategory from "../../../components/tables/category/TableCategory.vue";
import ModalAddCategory from "./ModalAddCategory.vue";
@Component({
	name: "PageCategory",
	components: {
		Icon,
		TableCategory,
		ModalAddCategory,
	},
})
export default class PageCategory extends Vue {
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;
	@Action(namespaced(NS_PRODUCTS, FETCH_CATEGORY)) fetchCategory;

	HOST = API_HOST;
	results = [];

	@Watch("$route")
	handleChangeRoute(val, oldVal) {
		if (val != oldVal) {
			this.fetchData();
		}
	}

	fetchData() {
		this.fetchCategory({
			has_parent: true,
		}).then((data) => {
			this.results = data;
		});
	}

	handleCategoryDelete() {
		this.fetchData();
	}

	handleCreatedCategory(value) {
		if (value) {
			this.results.unshift(value);
		}
	}

	mounted() {
		this.setActiveSidebar("products");
		this.setActiveSubmenu("category");
		this.fetchData();
	}
}
</script>

<style scoped lang="scss">
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.left {
		font-weight: 700;
		font-size: 20px;
	}
	.right {
		display: flex;
		gap: 40px;
		.user-filter {
			height: 36px;
			width: 200px;
			background-color: #edf1fe;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 10px;
			padding-right: 10px;
			.label {
				font-weight: 500;
				font-size: 16px;
				color: #5c83f3;
			}
			.dropdown-wrapper {
				.dropdown {
					width: 93px;
					height: 28px;
					background-color: #5c83f3;
					color: white;
					font-weight: 500;
					border-radius: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					position: relative;
				}
				.dropdown-content {
					position: absolute;
					width: 93px;
					margin-top: 5px;
					background-color: white;
					z-index: 999;
					box-shadow: 5px 5px 4px 5px #f1f1f1;
					.item {
						padding: 10px;
						cursor: pointer;
					}
				}
			}
		}
		.add-new-user-btn {
			height: 36px;
			min-width: 150px;
			background-color: #edf1fe;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-left: 10px;
			padding-right: 10px;
			color: #5c83f3;
			cursor: pointer;
			font-weight: 500;
			.add-user-icon {
				font-size: 20px;
				font-weight: 500;
				margin-right: 5px;
			}
		}
	}
}
.content {
	margin-top: 30px;
}
.c-pagination {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	margin-top: 15px;
	a {
		color: #737373;
		font-size: 14px;
		font-weight: 600;
		cursor: pointer;
	}
}
.active {
	color: black !important;
}
</style>
