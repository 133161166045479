<template>
  <div class="user-wrapper">
    
    <div class="content">
      <div class="left">
        <!-- section one // analytic section -->
        <div class="header">Customer Review</div>
        <div class="summary-card">
          <card-review-analytics
            card-header="Total Review"
            card-icon="icon-park-outline:transaction-order"
            count-value="10"
            :is-upword="true"
            percentage-value="32"
          >
          </card-review-analytics>
          <card-review-analytics
            card-header="New Review"
            card-icon="bi:clock"
            count-value="0"
            :is-upword="false"
            percentage-value="100"
          >
          </card-review-analytics>
          <card-review-analytics
            card-header="Pending Review"
            card-icon="clarity:date-outline-badged"
            count-value="0"
            :is-upword="true"
            percentage-value="100"
          >
          </card-review-analytics>
          <card-review-analytics
            card-header="Authorized Review"
            card-icon="bi:graph-up-arrow"
            count-value="0"
            :is-upword="true"
            percentage-value="100"
          >
          </card-review-analytics>
          <card-review-analytics
            card-header="4.5 Review"
            card-icon="icon-park-outline:cycle"
            count-value="23"
            :is-upword="true"
            percentage-value="34"
          >
          </card-review-analytics>
          <card-review-analytics
            card-header="4 Review"
            card-icon="fa6-solid:truck-arrow-right"
            count-value="23"
            :is-upword="false"
            percentage-value="100"
          >
          </card-review-analytics>
          <card-review-analytics
            card-header="3.5 Review"
            card-icon="bi:arrow-counterclockwise"
            count-value="23"
            :is-upword="true"
            percentage-value="34"
          >
          </card-review-analytics>
        </div>

        <!-- section two -->
        <div class="graph-section">
          <div class="graph-header">
            <div class="left">
              <span class="header">Review</span>
            </div>
            <div class="right">
              <!-- TODO: make a dropdown to select year -->
              Last Year
            </div>
          </div>
          <div class="finantial-description">
            <p class="total-earning">
              <span>Total earnings</span> <span class="amount">ট 148184</span>
            </p>

            <div class="">
              <graph-snippets></graph-snippets>
            </div>
          </div>
        </div>
        <!-- section three -->
        <div class="product-section">
          <div class="top">
            <div class="left">Review boards</div>
            <div class="right">
              <div class="right-items">
                <div class="import-button">
                  <Icon class="add-user-icon" icon="akar-icons:download" />
                  <span>Download</span>
                </div>
                <div class="dropdown">Below 20</div>
              </div>
            </div>
          </div>
          <div class="product-description">
            <div>
              <!-- TODO: using loop, pass the res with props -->
              <card-product-review res=""></card-product-review>
              <card-product-review res=""></card-product-review>
              <card-product-review res=""></card-product-review>
            </div>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="right-side-wrapper">
            <h3>Top Reviewers</h3>
          <div class="right-side-card order-card">
            <div class="card-items">
                <card-reviewer></card-reviewer>
                <card-reviewer></card-reviewer>
                <card-reviewer></card-reviewer>
                <card-reviewer></card-reviewer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../../store/utils";
import { API_HOST } from "../../global";
import { NS_USER, NS_ORDERS } from "../../store/namespace.names";
import {
  SET_ACTIVE_SIDEBAR,
  SET_ACTIVE_SUBMENU,
} from "../../store/mutation.names";
import { FETCH_ORDERS } from "../../store/action.names";
import { Icon } from "@iconify/vue2";
import CardReviewAnalytics from "./snippets/CardReviewAnalytics.vue";
import CardProductReview from "./snippets/CardProductReview.vue";
import GraphSnippets from "./snippets/GraphSnippets.vue";
import CardReviewer from "./snippets/CardReviewer.vue"
@Component({
  name: "CustomerReview",
  components: { GraphSnippets, CardProductReview, CardReviewAnalytics,CardReviewer, Icon },
})
export default class CustomerReview extends Vue {
  @Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
  @Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;

  @Action(namespaced(NS_ORDERS, FETCH_ORDERS)) fetchOrders;

  HOST = API_HOST;
  page = this.$route.query.page ? this.$route.query.page : 1;
  results = [];
  count = "";
  paginationSteps = [this.page];
  totalPageCount = "";
  current_route = "customer-reviews";
  status = "";

  @Watch("$route")
  handleChangeRoute(val, oldVal) {
    this.page = this.$route.query.page ? this.$route.query.page : 1;
    if (val != oldVal) {
      this.fetchData();
      this.paginationSteps = [this.page];
    }
  }

  handleDecrement() {
    if (this.page > 1) {
      this.$router.push(
        `/${this.current_route}?page=${parseInt(this.page) - 1}`
      );
    } else {
      this.$router.push(`/${this.current_route}?page=1`);
    }
  }

  handleIncreament() {
    if (this.page < this.totalPageCount) {
      this.$router.push(
        `/${this.current_route}?page=${parseInt(this.page) + 1}`
      );
    } else {
      this.$router.push(`/${this.current_route}?page=${this.totalPageCount}`);
    }
  }

  calculateNext(totalPage) {
    if (totalPage > 1) {
      var next = this.page;
      for (var i = 0; i < totalPage; i++) {
        if (i > 3) {
          break;
        }
        if (this.page < totalPage) {
          next = parseInt(next) + 1;
          if (next > totalPage) {
            break;
          }
          this.paginationSteps.push(next);
        }
      }
    }
  }

  calculatePrev() {
    if (this.page > 1) {
      var counter = 0;
      for (var i = this.page - 1; i > 0; i--) {
        if (counter > 3) {
          break;
        }
        this.paginationSteps.unshift(i);
        counter += 1;
      }
    }
  }

  calculatePage() {
    const totalPage = Math.ceil(this.count / 15);
    this.totalPageCount = totalPage;
    if (totalPage > 1) {
      this.calculateNext(totalPage);
      this.calculatePrev();
    }
  }

  updatePage(pageNo) {
    this.$router.push(`/${this.current_route}?page=${pageNo}`);
  }

  fetchData() {
    let params = {
      page: this.page,
    };
    if (this.status) {
      params.status = this.status;
    }

    this.fetchOrders(params).then((data) => {
      this.results = data.results;
      this.count = data.count;
      this.calculatePage();
    });
  }

  mounted() {
    this.setActiveSidebar("customer-reviews");
    this.setActiveSubmenu("");
    // this.fetchData();
  }
}
</script>

<style scoped lang="scss">
.header {
  font-weight: 700;
  font-size: 20px;
  margin: 0px 0px;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    font-weight: 700;
    font-size: 20px;
  }
  .right {
    display: flex;
    gap: 40px;
    .right-items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 40px;
    }
    .dropdown {
      font-size: 18px;
      color: #90a9f5;
    }
    .import-button {
      cursor: pointer;
      font-size: 18px;
      color: #f1b450;
      span {
        margin-left: 5px;
      }
    }
  }
}
.content {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 9fr 3fr;
  gap: 20px;
  .left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .summary-card {
      display: grid;
      grid-template-columns: 3fr 3fr 3fr 3fr;
      gap: 10px;
    }
    .graph-section {
      margin-top: 20px;
      .graph-header {
        display: flex;
        margin-bottom: 10px;
        .left {
        }
        .right {
          margin-left: auto;
        }
      }
      .finantial-description {
        .total-earning {
          font-size: 18px;
          color: #74777e;
          .amount {
            margin-left: 20px;
            color: #90a9f5;
            font-weight: 600;
          }
        }
      }
    }
    .product-section {
      margin-top: 20px;
      .product-description {
        .total-earning {
          margin-top: 10px;
          font-size: 18px;
          color: #74777e;
          .amount {
            margin-left: 20px;
            color: #90a9f5;
            font-weight: 600;
          }
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .right-side-wrapper {
      border-radius: 15px;
      padding: 22px;
      background: #f2f6ff;
      .divider {
        margin: 5px 0px;
        height: 2px;
        width: 100%;
        background-image: linear-gradient(
          to left,
          #c0b928,
          #c89d18,
          #cb811a,
          #c86524,
          #bf492f,
          #b24e25,
          #a5531d,
          #985617,
          #7e701d,
          #628340,
          #45916c,
          #399b95
        );
      }
      .right-side-card {
        padding: 20px;
        .card-items {
          display: grid;
          grid-template-columns: 12fr;
          font-size: 18px;
          .name {
            padding: 8px 0px;
          }
          .value {
            text-align: right;
            padding: 8px 0px;
          }
        }
      }
      .order-card {
        border-radius: 20px !important;
        background: #F2F6FF;
        box-shadow: 5px 5px 15px #5a5a5a, -5px -5px 15px #ffffff;
      }
    }
  }
}
.c-pagination {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 15px;
  a {
    color: #737373;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
}
.active {
  color: black !important;
}
</style>
