<template>
	<div>
		<div @click="toggle" class="round-orange-btn add-image-button">
			Add Product
		</div>
		<sui-modal ui large modal v-model="open_modal">
			<sui-modal-header class="modal-header">
				<div>Select Product</div>
				<div class="text-right">
					<Icon
						@click.native="toggle"
						class="add-user-icon"
						icon="charm:circle-cross"
					/>
				</div>
			</sui-modal-header>
			<sui-modal-content scrolling>
				<sui-input
					class="custom-search"
					fluid
					@keyup.enter="searchProduct()"
					v-model="search"
					placeholder="Search..."
				/>
				<!-- ============= image list showing section ============== -->
				<!-- image listing -->
				<!-- <h3 is="sui-header" dividing>Select Product</h3> -->
				<div>
					<ProductChoicesTable
						@emitSelectedProduct="getSelectedProduct"
						v-if="results"
						:results="results"
					/>
				</div>
				<!-- image pagination -->
				<div class="c-pagination" v-if="paginationSteps.length > 1">
					<sui-button class="item" @click.prevent="handleDecrement">
						Prev
					</sui-button>
					<sui-button
						:class="{ active: page == pag, item: true }"
						v-for="(pag, idx) in paginationSteps"
						:key="'pag_' + idx"
						@click.prevent="updatePage(pag)"
					>
						{{ pag }}
					</sui-button>
					<sui-button class="item" @click.prevent="handleIncreament">
						Next
					</sui-button>
				</div>
			</sui-modal-content>
			<sui-modal-actions>
				<sui-button @click.prevent="handleSelectedProduct"> Add </sui-button>
			</sui-modal-actions>
		</sui-modal>
	</div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { Icon } from "@iconify/vue2";
import { API_HOST } from "../../../global";
import { namespaced } from "../../../store/utils";
import { NS_PRODUCTS } from "../../../store/namespace.names";
import { FETCH_PRODUCTS } from "../../../store/action.names";
import ProductChoicesTable from "./ProductChoicesTable.vue";

@Component({
	name: "ModalChooseImage",
	components: {
		Icon,
		ProductChoicesTable,
	},
})
export default class ModalChooseImage extends Vue {
	@Prop() res;
	@Action(namespaced(NS_PRODUCTS, FETCH_PRODUCTS)) fetchProduct;

	HOST = API_HOST;
	open_modal = false;

	page = 1;
	results = [];
	count = "";
	paginationSteps = [this.page];
	totalPageCount = "";
	selectedProduct = null;

	search = "";

	imageFile = null;
	current_route = "wg-images";

	mounted() {
		this.fetchProductList();
	}

	toggle() {
		this.open_modal = !this.open_modal;
	}

	handleDecrement() {
		if (this.page > 1) {
			this.page = parseInt(this.page) - 1;
		} else {
			this.page = 1;
		}
		this.fetchProductList();
	}

	handleIncreament() {
		if (this.page < this.totalPageCount) {
			this.page = parseInt(this.page) + 1;
		} else {
			this.page = parseInt(this.totalPageCount);
		}
		this.fetchProductList();
	}

	calculateNext(totalPage) {
		if (totalPage > 1) {
			var next = this.page;
			for (var i = 0; i < totalPage; i++) {
				if (i > 3) {
					break;
				}
				if (this.page < totalPage) {
					next = parseInt(next) + 1;
					if (next > totalPage) {
						break;
					}
					this.paginationSteps.push(next);
				}
			}
		}
	}

	calculatePrev() {
		if (this.page > 1) {
			var counter = 0;
			for (var i = this.page - 1; i > 0; i--) {
				if (counter > 3) {
					break;
				}
				this.paginationSteps.unshift(i);
				counter += 1;
			}
		}
	}

	calculatePage() {
		const totalPage = Math.ceil(this.count / 16);
		this.totalPageCount = totalPage;
		if (totalPage > 1) {
			this.calculateNext(totalPage);
			this.calculatePrev();
		}
	}

	updatePage(pageNo) {
		this.page = parseInt(pageNo);
		this.fetchProductList();
	}

	fetchProductList() {
		let params = {
			page: this.page,
			search: this.search,
		};
		this.fetchProduct(params).then((data) => {
			this.results = [];
			this.results = data.results;
			this.count = data.count;
			this.paginationSteps = [this.page];
			this.calculatePage();
		});
	}

	searchProduct() {
		this.page = 1;
		this.fetchProductList();
	}

	getSelectedProduct(value) {
		this.selectedProduct = value;
	}

	handleSelectedProduct() {
		// Vue.$toast.success("selected image successfully!", {});
		this.$emit("passSelectedProduct", this.selectedProduct);
		this.toggle();
	}
}
</script>

<style scoped lang="scss">
// ui standard modal transition visible active
.ui.modal {
	width: 1200px !important;
	margin: 0px !important;
}

.custom-search {
	margin-bottom: 10px !important;
}

.modal-header {
	display: grid !important;
	grid-template-columns: 6fr 6fr !important;
	gap: 10px;
	div {
		display: flex;
		font-size: 18px;
	}
	.text-right {
		justify-content: right;
		color: red;
	}
}
.custom-image-item {
	height: 120px !important;
	width: 150px !important;
	cursor: pointer;

	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}
.custom-image-item:hover {
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
}
.selected-image {
	border: 1px solid red;
	border-radius: 5px;
}
.add-image-button {
	width: 150px;
	cursor: pointer;
	justify-content: center;
	font-weight: 500;
}
.add-user-icon {
	font-size: 20px;
	font-weight: 500;
	margin-right: 5px;
	cursor: pointer;
}
.custom-form {
	display: grid;
	grid-template-columns: 12fr;
	padding: 20px 5px;
	label {
		color: black;
		margin-bottom: 5px;
		font-size: 16px;
	}
}
.mx-datepicker {
	width: 100%;
}
</style>
