<template>
  <div class="wrapper-card">
    <div class="card-icon">
      <Icon class="minus-icon" :icon="cardIcon" />
    </div>
    <div class="card-detail">
      <div class="card-header">{{ cardHeader }}</div>
      <div class="card-sub-detail">
        <div class="card-count">
          <b>{{ countValue }}</b>
        </div>
        <div :class="isUpword ? 'card-arrow' : 'card-arrow text-red'">
          <span class="arrow-symbol" v-if="isUpword">↑</span>
          <span class="arrow-symbol" v-else>↓</span>
          <span> {{ percentageValue }} % </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Icon } from "@iconify/vue2";

@Component({
  name: "CardAnalytics",
  components: {
    Icon,
  },
})
export default class CardAnalytics extends Vue {
  @Prop() cardHeader;
  @Prop() cardIcon;
  @Prop() countValue;
  @Prop() isUpword;
  @Prop() percentageValue;
}
</script>

<style scoped lang="scss">
.wrapper-card {
  display: grid;
  grid-template-columns: 3fr 9fr;
  background: #f2f6ff;
  padding: 15px;
  border-radius: 20px;
  .text-red {
    color: red !important;
  }
  .card-icon {
    color: #90a9f5;
    display: flex;
    align-items: center;
    font-size: 40px;
  }
  .card-detail {
    .card-header {
      padding: 3px 0px;
      font-size: 18px;
      font-weight: 500;
    }
    .card-sub-detail {
      display: flex;
      margin-top: 8px;
      .card-count {
        color: #90a9f5;
        font-size: 18px;
      }
      .card-arrow {
        margin-left: auto;
        color: #65b781;
        .arrow-symbol {
          font-size: 20px;
          font-weight: 800;
        }
      }
    }
  }
}
</style>
