<template>
	<div>
		<div @click="toggle" class="edit-button">Edit</div>
		<sui-modal v-model="open_modal">
			<sui-modal-header class="modal-header">
				<div>Edit Coupon</div>
				<div class="text-right">
					<Icon
						@click.native="toggle"
						class="add-user-icon"
						icon="charm:circle-cross"
					/>
				</div>
			</sui-modal-header>
			<sui-modal-content scrolling>
				<ValidationObserver>
					<sui-form class="custom-form">
						<label>Coupon Name</label>
						<sui-form-field>
							<input v-model="formCoupon.name" placeholder="Coupon name" />
						</sui-form-field>

						<label>Code</label>
						<sui-form-field>
							<input v-model="formCoupon.code" placeholder="Coupon code" />
						</sui-form-field>

						<label>Price</label>
						<sui-form-field>
							<input
								type="number"
								step="1"
								v-model="formCoupon.price"
								placeholder="Price"
							/>
						</sui-form-field>

						<label>Quantity</label>
						<sui-form-field>
							<input
								type="number"
								step="1"
								v-model="formCoupon.quantity"
								placeholder="Quantity"
							/>
						</sui-form-field>

						<label>Start Date</label>
						<sui-form-field>
							<datepicker
								v-model="formCoupon.start_date"
								type="datetime"
								valueType="format"
							>
							</datepicker>
						</sui-form-field>

						<label>End Date</label>
						<sui-form-field>
							<datepicker
								v-model="formCoupon.end_date"
								type="datetime"
								valueType="format"
							>
							</datepicker>
						</sui-form-field>

						<sui-segment>
							<sui-form-field>
								<sui-checkbox
									toggle
									label="Coupon Activation Status"
									v-model="formCoupon.is_active"
								/>
							</sui-form-field>
						</sui-segment>
					</sui-form>
				</ValidationObserver>
			</sui-modal-content>
			<sui-modal-actions>
				<sui-button @click.prevent="handleCreateCoupon"> Save </sui-button>
			</sui-modal-actions>
		</sui-modal>
	</div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { Icon } from "@iconify/vue2";
import { API_HOST } from "../../../global";
import { namespaced } from "../../../store/utils";
import { NS_COUPONS } from "../../../store/namespace.names";
import { UPDATE_COUPONS } from "../../../store/action.names";
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";

@Component({
	name: "ModalEditCoupon",
	components: {
		Icon,
		ValidationObserver,
		ValidationProvider,
	},
})
export default class ModalEditCoupon extends Vue {
	@Prop() res;

	@Action(namespaced(NS_COUPONS, UPDATE_COUPONS)) updateCoupons;
	HOST = API_HOST;
	open_modal = false;
	formCoupon = {
		name: "",
		code: "",
		price: 0,
		quantity: 1,
		is_active: true,
		start_date: "",
		end_date: "",
	};
	mounted() {
		if (this.res) {
			this.setData();
		}
	}
	setData() {
		this.formCoupon.name = this.res.name;
		this.formCoupon.code = this.res.code;
		this.formCoupon.price = this.res.price;
		this.formCoupon.quantity = this.res.quantity;
		this.formCoupon.is_active = this.res.is_active;
		this.formCoupon.start_date = this.$dayjs(this.res.start_date).format(
			"YYYY-MM-DD hh:mm:ss"
		);
		this.formCoupon.end_date = this.$dayjs(this.res.end_date).format(
			"YYYY-MM-DD hh:mm:ss"
		);
	}
	toggle() {
		this.open_modal = !this.open_modal;
	}

	handleCreateCoupon() {
		let updated_data = {
			id: this.res.id,
			formData: this.formCoupon,
		};
		this.updateCoupons(updated_data).then((data) => {
			Vue.$toast.success("Coupon updated successfully!", {});
			if (data) {
				this.toggle();
			}
		});
	}
}
</script>

<style scoped lang="scss">
.modal-header {
	display: grid !important;
	grid-template-columns: 6fr 6fr !important;
	gap: 10px;
	div {
		display: flex;
		font-size: 18px;
	}
	.text-right {
		justify-content: right;
		color: red;
	}
}
.custom-form {
	margin-top: 10px;
	display: grid;
	grid-template-columns: 12fr;
	padding: 20px 40px;
	label {
		color: black;
		margin-bottom: 5px;
		font-size: 16px;
	}
}
.mx-datepicker {
	width: 100%;
}
.edit-button {
	font-size: 16px;
	font-weight: 600;
	height: 50px;
	width: 100%;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		background-color: #5c83f3;
		color: white;
	}
	&:last-child {
		&:hover {
			background-color: red;
			color: white;
		}
	}
}
</style>
