<template>
	<div class="single-table-row">
		<div class="image-name">
			<div class="image" v-if="res.product_image">
				<img :src="HOST + res.product_image.original.src" />
			</div>
		</div>
		<div>
			<!-- {{ res.product_detail.name }} -->
			{{ res.product_name }}
		</div>
		<div>
			<div class="col-header">Warranty Left</div>
		</div>
		<div>
			<div class="col-value">{{ res.duration_left }}</div>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Icon } from "@iconify/vue2";
import { namespaced } from "../../store/utils";
import { API_HOST } from "../../global";

@Component({
	name: "CardOrderProductItem",
	components: {
		Icon,
	},
})
export default class CardOrderProductItem extends Vue {
	HOST = API_HOST;
	@Prop() res;
}
</script>

<style scoped lang="scss">
.single-table-row {
	display: grid;
	grid-template-columns: 1fr 5fr 2fr 2fr;
	gap: 10px;
	min-height: 40px;
	text-decoration: none;
	background: #f2f6ff;
	padding: 15px;
	border-radius: 10px;
	margin: 15px 0px;
	div {
		// display: flex;
		// justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 16px;
		color: #12233a;
		.col-header {
			color: #74777e;
			text-align: center;
			padding: 5px 0;
		}
		.col-value {
			text-align: center;
			padding: 5px 0;
			font-weight: 600;
		}
	}
}
.image {
	height: 40px;
	width: 60px;
	img {
		height: 100%;
		width: 100%;
	}
}
</style>
