<template>
	<div class="single-table-row">
		<div class="order-id">
			<div class="name">
				{{ res.order_id }}
			</div>
		</div>
		<div>
			<span v-if="res.product_name">
				{{ res.product_name }}
			</span>
			<span v-else> Not yet set </span>
		</div>
		<div>
			<span v-if="res.duration_left"> {{ res.duration_left }} </span>
			<span v-else> Not yet set </span>
		</div>
		<div>
			<span v-if="res.date">
				{{ $dayjs(res.date).format("YYYY-MM-DD") }}
			</span>
			<span v-else> Not yet set </span>
		</div>
		<div class="detail-link">
			<sui-button
				@click.native="redirectToWarrantyOrReturnDetail()"
				color="teal"
				icon
			>
				<Icon class="icon-font expand-btn" icon="akar-icons:eye" />
			</sui-button>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { Icon } from "@iconify/vue2";
import { API_HOST } from "../../../global";
import { namespaced } from "../../../store/utils";
import { NS_PRODUCTS } from "../../../store/namespace.names";
import {
	CHANGE_IS_PUBLISH,
	CHANGE_IN_STOCKED,
	DELETE_PRODUCT,
} from "../../../store/action.names";

@Component({
	name: "WarrantyTableSingleRow",
	components: {
		Icon,
	},
})
export default class WarrantyTableSingleRow extends Vue {
	@Prop() res;

	@Action(namespaced(NS_PRODUCTS, CHANGE_IS_PUBLISH)) changeIsPublish;
	@Action(namespaced(NS_PRODUCTS, CHANGE_IN_STOCKED)) changeInStocked;
	@Action(namespaced(NS_PRODUCTS, DELETE_PRODUCT)) deleteProduct;

	HOST = API_HOST;
	id = null;
	is_published = null;
	in_stocked = null;
	showShortMenu = false;

	toggleShortMenu() {
		this.showShortMenu = !this.showShortMenu;
	}

	@Watch("is_published")
	handleIsPublish(val, oldVal) {
		if (oldVal != null) {
			this.changeIsPublish({
				id: this.id,
				data: {
					is_publish: val,
				},
			}).then((data) => {});
		}
	}

	@Watch("in_stocked")
	handleInStocked(val, oldVal) {
		if (oldVal != null) {
			this.changeInStocked({
				id: this.id,
				data: {
					in_stock: val,
				},
			}).then((data) => {});
		}
	}

	mounted() {
		this.is_published = this.res.is_publish;
		this.in_stocked = this.res.in_stock;
		this.id = this.res.id;
	}

	redirectToWarrantyOrReturnDetail() {
		let name = "warranty-claim-detail";
		if (this.res.type == "return") {
			name = "return-claim-detail";
		}

		this.$router.push({
			name: name,
			params: {
				id: this.id,
			},
		});
	}
}
</script>

<style scoped lang="scss">
.single-table-row {
	display: grid;
	grid-template-columns: 1.5fr 1.5fr 3fr 1fr 1fr;
	gap: 10px;
	min-height: 40px;
	border-bottom: 1px solid grey;
	text-decoration: none;
	// &:hover{
	//   background-color: #DEE9FE;
	//   cursor: pointer;
	// }
	div {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 16px;
		color: #12233a;
	}
	.order-id {
		display: flex;
		justify-content: flex-start;
		gap: 10px;
		padding: 10px;
		.image {
			width: 60px;
			height: 60px;
			img {
				height: 100%;
				width: 100%;
			}
		}
	}
	.action-column {
		position: relative;
		&:hover {
			.action-dropdown {
				display: block;
			}
		}
		.action-dropdown {
			width: 136px;
			z-index: 999;
			position: absolute;
			display: none;
			top: 30px;
			background: #f7f7f7;
			border: 1px solid #a4a4a4;
			box-sizing: border-box;
			box-shadow: 0px 4px 10px rgba(162, 162, 162, 0.25);
			border-radius: 6px;
			.dropdown-item {
				font-size: 14px;
				font-weight: 600;
				color: #62b37c;
				text-align: left;
				display: flex;
				justify-content: flex-start;
				padding: 10px;
				padding-top: 15px;
				padding-bottom: 15px;
				padding-left: 15px;
				cursor: pointer;
			}
		}
	}
}
.detail-link {
	position: relative;
	.expand-btn {
		cursor: pointer;
	}
	.short-menu {
		position: absolute;
		height: 50px;
		width: 230px;
		right: 80px;
		background: #f2f6ff;
		box-shadow: 0px 4px 16px rgba(64, 64, 64, 0.21);
		border-radius: 8px;
		display: flex;
		justify-content: space-around;
		a {
			font-size: 16px;
			font-weight: 600;
			height: 50px;
			width: 100%;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			&:hover {
				background-color: #5c83f3;
				color: white;
			}
			&:last-child {
				&:hover {
					background-color: red;
					color: white;
				}
			}
		}
	}
}
</style>
