import { GET_AUTH_HEADER } from "../getter.names";
import { Vue } from "vue-property-decorator";

import {} from "../getter.names";
import {
  FETCH_ORDERS,
  FETCH_ORDER_DETAIL,
  FETCH_ORDER_FORM_OPTIONS_DATA,
  UPDATE_ORDER,
  SEND_MESSAGE_TO_CUSTOMER,
  DELETE_ORDER,
} from "../action.names";
import { FETCH_ORDER_ENDPOINT } from "../endpoints";
import {} from "../mutation.names";
import { NS_USER } from "../namespace.names";
import { namespaced, buildParams } from "../utils";

export default {
  namespaced: true,
  state: {
    carts: [],
    order: {
      shippingPrice: "",
      order: {},
    },
  },
  getters: {},
  actions: {
    async [FETCH_ORDERS]({ commit, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
        let relativeUrl = `${FETCH_ORDER_ENDPOINT}${buildParams(payload)}`;
        axios
          .get(relativeUrl, {
            headers: {
              ...authHeader,
            },
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },

    async [FETCH_ORDER_DETAIL]({ commit, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
        axios
          .get(`${FETCH_ORDER_ENDPOINT}${payload.id}/`, {
            headers: {
              ...authHeader,
            },
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },

    async [FETCH_ORDER_FORM_OPTIONS_DATA]({ commit, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
        axios
          .get(`${FETCH_ORDER_ENDPOINT}order-form-options-data/`, {
            headers: {
              ...authHeader,
            },
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },

    async [UPDATE_ORDER]({ commit, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
        axios
          .patch(`${FETCH_ORDER_ENDPOINT}${payload.id}/`, payload.formData, {
            headers: {
              ...authHeader,
            },
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },

    async [SEND_MESSAGE_TO_CUSTOMER]({ commit, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
        axios
          .post(`${FETCH_ORDER_ENDPOINT}send-message-to-customer/`, payload, {
            headers: {
              ...authHeader,
            },
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },

    async [DELETE_ORDER]({ commit, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
        axios
          .delete(`${FETCH_ORDER_ENDPOINT}${payload.id}/`, {
            headers: {
              ...authHeader,
            },
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
  },

  mutations: {},
};
