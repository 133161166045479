<template>
	<div class="container">
		<div class="left">
			<input
				v-debounce:1s="handleInputSearch"
				type="text"
				v-model="searchInput"
				placeholder="Search"
			/>
		</div>
		<div class="right">
			<router-link :to="`/users/${profile.id}`" class="profile" v-if="profile">
				<img v-if="profile.thumbnail_url" :src="HOST + profile.thumbnail_url" />
				<img v-else src="@/assets/images/empty-user.png" />
			</router-link>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced, deepCopy } from "../store/utils";
import { API_HOST } from "../global";
import { Icon } from "@iconify/vue2";
import { NS_USER } from "../store/namespace.names";
import { GET_TOKEN, GET_PROFILE, GET_SEARCH_TEXT } from "../store/getter.names";
import { SET_SEARCH_TEXT } from "../store/mutation.names";

@Component({
	name: "Topbar",
	components: {
		Icon,
	},
})
export default class Topbar extends Vue {
	@Getter(namespaced(NS_USER, GET_PROFILE)) profile;
	@Getter(namespaced(NS_USER, GET_SEARCH_TEXT)) getSearchText;

	@Mutation(namespaced(NS_USER, SET_SEARCH_TEXT)) setSearchText;

	HOST = API_HOST;
	searchInput = "";

	@Watch("getSearchText", { immediate: true })
	handleSearchTextChange(val, oldVal) {
		if (val != oldVal) {
			this.searchInput = val;
		}
	}

	handleInputSearch(val, e) {
		this.searchInput = val;
		this.setSearchText(val);
		var query = deepCopy(this.$route.query);
		query["page"] = 1;
		if (val.length > 0) {
			query["searchText"] = val;
			this.$router.push({ query: query });
		} else {
			delete query["searchText"];
			this.$router.push({ query: query });
		}
	}

	mounted() {
		if (this.$route.query) {
			if (this.$route.query.searchText?.length > 0) {
				this.setSearchText(this.$route.query.searchText);
				this.searchInput = this.$route.query.searchText;
			}
		}
	}
}
</script>

<style scoped lang="scss">
.container {
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 50px;
}
.left {
	width: 100%;
	input {
		height: 40px;
		width: 60%;
		background-color: #f5f7fa;
		border-radius: 10px;
		border: 1px solid #f1f1f1;
		text-indent: 20px;
	}
}
.right {
	img {
		height: 40px;
		width: 40px;
		border-radius: 40px;
		border: 2px solid #41444d;
	}
}
</style>
