export const LOGIN = "login";
export const LOGOUT = "logout";
export const PROFILE = "profile";
export const UPDATE_PROFILE = "updateProfile";
export const DELETE_USER = "deleteUser";
export const UPDATE_SUPERCOIN = "updateSupercoin";
export const STATS_FOR_USER_DETAIL = "statsForUserDetail";
export const GET_TOKEN_FROM_LOCAL_STORE = "getTokenFromLocalStorage";

export const FETCH_USERS = "fetchUsers";
export const FETCH_USER_DETAIL = "fetchUserDetail";

export const FETCH_PRODUCTS = "fetchProducts";
export const FETCH_PRODUCT_DETAIL = "fetchProductDetail";
export const PRODUCT_UPDATE = "productUpdate";
export const CHANGE_IS_PUBLISH = "changeIsPublish";
export const CHANGE_IN_STOCKED = "changeInStocked";
export const DELETE_PRODUCT = "deleteProduct";

export const FETCH_ATTRIBUTE_ALL = "fetchAttributeAll";
export const FETCH_ATTRIBUTE = "fetchAttribute";
export const FETCH_ATTRIBUTE_DETAIL = "fetchAttributeDetail";
export const DELETE_ATTRIBUTE = "deleteAttribute";
export const UPDATE_ATTRIBUTE = "updateAttribute";

export const FETCH_ATTRIBUTE_VALUE = "fetchAttributeValue";
export const ADD_ATTRIBUTE_VALUE = "addAttributeValue";
export const DELETE_ATTRIBUTE_VALUE = "deleteAttributeValue";
export const UPDATE_ATTRIBUTE_VALUE = "updateAttributeValue";

export const FETCH_CATEGORY = "fetchCategory";
export const FETCH_CATEGORY_DETAIL = "fetchCategoryDetail";
export const CREATE_CATEGORY = "createCategory";
export const UPDATE_CATEGORY = "updateCategory";
export const DELETE_CATEGORY = "deleteCategory";

export const FETCH_BRAND = "fetchBrand";
export const FETCH_ALL_BRAND = "fetchAllBrand";
export const FETCH_BRAND_DETAIL = "fetchBrandDetail";
export const DELETE_BRAND = "deleteBrand";
export const ADD_BRAND = "addBrand";
export const UPDATE_BRAND = "updateBrand";

//general actions
export const REWORD_CUSTOMER = "rewordCustomer";

// order actions
export const FETCH_ORDERS = "fetchOrders";
export const FETCH_ORDER_DETAIL = "fetchOrderDetail";
export const FETCH_ORDER_FORM_OPTIONS_DATA = "fetchOrderFormOptionsData";
export const UPDATE_ORDER = "updateOrder";
export const DELETE_ORDER = "deleteOrder";
export const SEND_MESSAGE_TO_CUSTOMER = "sendMessageToCustomer";

// warranty or return claim
export const FETCH_WARRANTY_OR_RETURN_CALIM = "fetchWarrantyOrReturnClaim";
export const FETCH_WARRANTY_OR_RETURN_DETAIL = "fetchWarrantyOrReturnDetail";
export const FETCH_WARRANTY_OR_RETURN_OPTIONS_DATA =
	"fetchWarrantyOrReturnOptionsData";

// campaigns
export const FETCH_CAMPAIGNS = "fetchCampaigns";
export const FETCH_CAMPAIGNS_DETAIL = "fetchCampaignsDetail";
export const UPDATE_CAMPAIGNS = "updateCampaigns";
export const CREATE_CAMPAIGN = "createCampaigns";
export const DELETE_CAMPAIGN = "deleteCampaigns";
export const CREATE_CAMPAIGN_PRODUCT = "createCampaignsProduct";
export const UPDATE_CAMPAIGN_PRODUCT = "updateCampaignsProduct";
export const DELETE_CAMPAIGN_PRODUCT = "deleteCampaignsProduct";

// coupons
export const FETCH_COUPONS = "fetchCoupons";
export const FETCH_COUPONS_DETAIL = "fetchCouponsDetail";
export const UPDATE_COUPONS = "updateCoupons";
export const CREATE_COUPONS = "createCoupons";
export const DELETE_COUPONS = "deleteCoupons";

// jobs
export const FETCH_JOBS = "fetchJobs";
export const FETCH_JOBS_DETAIL = "fetchJobsDetail";
export const UPDATE_JOBS = "updateJobs";

// review
export const FETCH_REVIEWS = "fetchReviews";
export const FETCH_REVIEW_DETAIL = "fetchReviewDetail";
export const FETCH_REVIEW_FORM_OPTIONS_DATA = "fetchReviewFormOptionsData";
export const UPDATE_REVIEW = "updateReview";
export const DELETE_REVIEW = "deleteReview";

// IMAGES
export const FETCH_IMAGE = "fetchImages";
export const FETCH_IMAGE_DETAIL = "fetchImageDetail";
export const CREATE_IMAGE = "createImages";
export const VARIANT_UPDATE = "variantUpdate";

//analytics
export const FETCH_BUSINESS_ANALYTICS_SUMMARY = "fetchBusinessAnalyticsSummary";
export const FETCH_PRODUCT_ANALYTICS_SUMMARY = "fetchProductAnalyticsSummary";
export const OUT_OF_STOCK_PRODUCT = "outOfStockProduct";
export const FETCH_ORDER_ANALYTICS_SUMMARY = "fetchOrderAnalyticsSummary";
export const FETCH_FINANCE_ANALYTICS_SUMMARY = "fetchFinanceAnalyticsSummary";
export const FETCH_CUSTOMER_ANALYTICS_SUMMARY = "fetchCustomerAnalyticsSummary";
export const FETCH_DASHBOARD_ANALYTICS_SUMMARY =
	"fetchDashboardAnalyticsSummary";

// contact
export const FETCH_CONTACTS = "fetchContacts";
export const FETCH_CONTACT_DETAIL = "fetchContactDetail";
export const UPDATE_CONTACT = "updateContact";

export const UPDATE_WARRENTY_RETURN = "updateWarrentyReturn";
