import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import products from "./modules/products";
import orders from "./modules/orders";
import analytics from "./modules/analytics";
import settings from "./modules/settings";
import warranty_or_return from "./modules/warranty_or_return";
import coupons from "./modules/coupons";
import campaigns from "./modules/campaigns";
import jobs from "./modules/jobs";
import reviews from "./modules/reviews";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    user,
    products,
    orders,
    analytics,
    settings,
    warranty_or_return,
    coupons,
    campaigns,
    jobs,
    reviews
  },
});
