<template>
	<div class="user-wrapper">
		<div class="top">
			<!-- <div class="left">Financial analytics</div>
      <div class="right">
        <div class="import-button">
          <Icon class="add-user-icon" icon="akar-icons:download" />
          <span>Import/Export</span>
        </div>
      </div> -->
		</div>
		<div class="content">
			<div class="left">
				<div class="header">Summary of finance</div>
				<div class="summary-card">
					<card-analytics
						card-header="Total sell"
						card-icon="icon-park-outline:transaction-order"
						:count-value="intNumberFormat(data.analytics_summary.total_sell)"
					>
					</card-analytics>
					<card-analytics
						card-header="Today's Sell"
						card-icon="bi:clock"
						:count-value="intNumberFormat(data.analytics_summary.today_sell)"
					>
					</card-analytics>
					<card-analytics
						card-header="Total Income"
						card-icon="clarity:date-outline-badged"
						:count-value="intNumberFormat(data.analytics_summary.total_income)"
					>
					</card-analytics>
					<card-analytics
						card-header="Today's income"
						card-icon="bi:graph-up-arrow"
						:count-value="intNumberFormat(data.analytics_summary.today_income)"
					>
					</card-analytics>
				</div>
				<div class="finantical">
					<div class="finantical-header">
						<div class="left">
							<span class="header">Income analytics</span>
						</div>
						<div class="right">
							<div class="user-filter">
								<div class="label">Filter</div>
								<div class="dropdown-wrapper">
									<div @click="toggleSelectDateDropdown" class="dropdown">
										{{ dateFilter }}
										<Icon icon="gridicons:dropdown" />
									</div>
									<div v-if="showSelectDate" class="dropdown-content">
										<div
											@click="toggleSelectDateDropdown(null, 'Last Month')"
											class="item"
										>
											Last Month
										</div>
										<div
											@click="toggleSelectDateDropdown(null, '3 Month')"
											class="item"
										>
											3 Month
										</div>
										<div
											@click="toggleSelectDateDropdown(null, '6 Month')"
											class="item"
										>
											6 Month
										</div>
										<div
											@click="toggleSelectDateDropdown(null, '12 Month')"
											class="item"
										>
											12 Month
										</div>
										<div
											@click="toggleSelectDateDropdown(null, 'All')"
											class="item"
										>
											All
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="finantial-description">
						<p class="total-earning">
							<span>Total income</span>
							<span class="amount">{{ intNumberFormat(totalEarning) }}</span>
						</p>
						<div>
							<FinanceAnalyticsChart v-if="data" :graphData="graphData" />
						</div>
					</div>
				</div>
				<!-- <div class="product-section">
					<div class="top">
						<div class="left">Expenses</div>
						<div class="right">
							<div class="right-items">
								<div class="import-button">
									<Icon class="add-user-icon" icon="akar-icons:download" />
									<span>Download Excel</span>
								</div>
								<div class="dropdown">Popular</div>
							</div>
						</div>
					</div>
					<div class="product-description">
						<div>
							<card-financial-analytics res=""></card-financial-analytics>
							<card-financial-analytics res=""></card-financial-analytics>
							<card-financial-analytics res=""></card-financial-analytics>
						</div>
					</div>
				</div> -->
			</div>
			<div class="right" v-if="data">
				<div class="right-side-wrapper">
					<div class="right-side-card order-card">
						<h3>Orders(Cash on delivery)</h3>
						<div class="card-items">
							<div class="name">Processing order</div>
							<div class="value">
								{{
									intNumberFormat(
										data.analytics_right_side_snippet.cod.processing
									)
								}}
							</div>
							<div class="name">In Transit</div>
							<div class="value">
								{{
									intNumberFormat(
										data.analytics_right_side_snippet.cod.in_transit
									)
								}}
							</div>
							<div class="name">Delivered</div>
							<div class="value">
								{{
									intNumberFormat(
										data.analytics_right_side_snippet.cod.delivered
									)
								}}
							</div>
						</div>
					</div>
					<div class="right-side-card">
						<h3>Paid Order</h3>
						<div class="card-items">
							<div class="name">Processing order</div>
							<div class="value">
								{{
									intNumberFormat(
										data.analytics_right_side_snippet.paid.processing
									)
								}}
							</div>
							<div class="name">In Transit</div>
							<div class="value">
								{{
									intNumberFormat(
										data.analytics_right_side_snippet.paid.in_transit
									)
								}}
							</div>
							<div class="name">Delivered</div>
							<div class="value">
								{{
									intNumberFormat(
										data.analytics_right_side_snippet.paid.delivered
									)
								}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced, intNumberFormat } from "../../store/utils";
import { API_HOST } from "../../global";
import { NS_USER, NS_ORDERS, NS_ANALYTICS } from "../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../store/mutation.names";
import {
	FETCH_ORDERS,
	FETCH_FINANCE_ANALYTICS_SUMMARY,
} from "../../store/action.names";
import { Icon } from "@iconify/vue2";
import CardAnalytics from "./CardAnalytics.vue";
import CardFinancialAnalytics from "./snippets/CardFinancialAnalytics.vue";
import FinanceAnalyticsChart from "./snippets/FinanceAnalyticsChart";

@Component({
	name: "PageAnalytics",
	components: {
		FinanceAnalyticsChart,
		CardAnalytics,
		CardFinancialAnalytics,
		Icon,
	},
})
export default class PageAnalytics extends Vue {
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;

	@Action(namespaced(NS_ANALYTICS, FETCH_FINANCE_ANALYTICS_SUMMARY))
	fetchFinanceAnalyticsSummary;

	HOST = API_HOST;
	current_route = "business-analytics";
	status = "";
	data = null;
	intNumberFormat = intNumberFormat;
	dateFilter = "Last Month";
	showSelectDate = false;

	@Watch("$route")
	handleChangeRoute(val, oldVal) {
		this.setStatus();
	}

	toggleSelectDateDropdown(value, data) {
		if (data) {
			this.dateFilter = data;
		}
		this.showSelectDate = !this.showSelectDate;
	}

	get graphData() {
		if (this.dateFilter == "Last Month") {
			return this.data.graph.last_month.graph;
		} else if (this.dateFilter == "3 Month") {
			return this.data.graph.last_3_month.graph;
		} else if (this.dateFilter == "6 Month") {
			return this.data.graph.last_6_month.graph;
		} else if (this.dateFilter == "12 Month") {
			return this.data.graph.last_12_month.graph;
		} else if (this.dateFilter == "All") {
			return this.data.graph.all.graph;
		} else {
			return [];
		}
	}

	get totalEarning() {
		if (this.dateFilter == "Last Month") {
			return this.data.graph.last_month.total_income;
		} else if (this.dateFilter == "3 Month") {
			return this.data.graph.last_3_month.total_income;
		} else if (this.dateFilter == "6 Month") {
			return this.data.graph.last_6_month.total_income;
		} else if (this.dateFilter == "12 Month") {
			return this.data.graph.last_12_month.total_income;
		} else if (this.dateFilter == "All") {
			return this.data.graph.all.total_income;
		} else {
			return 0;
		}
	}

	setStatus() {
		let sub_route = "";
		if (this.$route) {
			sub_route = this.$route.params.slug;
		}
		if (sub_route && sub_route != "business-analytics") {
			this.status = sub_route;
			this.current_route = `business-analytics/${sub_route}`;
		} else {
			this.status = "";
			this.current_route = "business-analytics";
		}
	}

	handleFetchData() {
		this.fetchFinanceAnalyticsSummary().then((data) => {
			this.data = data;
		});
	}

	mounted() {
		this.setActiveSidebar("business-analytics");
		this.setActiveSubmenu("financial-analytics");
		this.setStatus();
		this.handleFetchData();
	}
}
</script>

<style scoped lang="scss">
.header {
	font-weight: 700;
	font-size: 20px;
	margin: 0px 0px;
}
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.left {
		font-weight: 700;
		font-size: 20px;
	}
	.right {
		display: flex;
		gap: 40px;
		.right-items {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 40px;
		}
		.dropdown {
			font-size: 18px;
			color: #90a9f5;
		}
		.import-button {
			cursor: pointer;
			font-size: 18px;
			color: #f1b450;
			span {
				margin-left: 5px;
			}
		}
	}
}
.content {
	margin-top: 30px;
	display: grid;
	grid-template-columns: 9fr 3fr;
	gap: 20px;
	.left {
		display: flex;
		flex-direction: column;
		gap: 20px;
		.summary-card {
			display: grid;
			grid-template-columns: 3fr 3fr 3fr 3fr;
			gap: 10px;
		}
		.finantical {
			margin-top: 20px;
			.finantical-header {
				display: flex;
				margin-bottom: 10px;
				.left {
				}
				.right {
					margin-left: auto;
					.user-filter {
						height: 36px;
						width: 200px;
						background-color: #edf1fe;
						border-radius: 10px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-left: 10px;
						padding-right: 10px;
						.label {
							font-weight: 500;
							font-size: 16px;
							color: #5c83f3;
						}
						.dropdown-wrapper {
							position: relative;
							.dropdown {
								width: 130px;
								height: 28px;
								background-color: #5c83f3;
								color: white;
								font-weight: 500;
								border-radius: 8px;
								display: flex;
								justify-content: center;
								align-items: center;
								cursor: pointer;
								position: relative;
							}
							.dropdown-content {
								position: absolute;
								top: 30px;
								width: 130px;
								margin-top: 5px;
								background-color: white;
								z-index: 999;
								box-shadow: 5px 5px 4px 5px #f1f1f1;
								.item {
									padding: 10px;
									cursor: pointer;
								}
							}
						}
					}
				}
			}
			.finantial-description {
				.total-earning {
					font-size: 18px;
					color: #74777e;
					.amount {
						margin-left: 20px;
						color: #90a9f5;
						font-weight: 600;
					}
				}
			}
		}
		.product-section {
			margin-top: 20px;
			.product-description {
				.total-earning {
					margin-top: 10px;
					font-size: 18px;
					color: #74777e;
					.amount {
						margin-left: 20px;
						color: #90a9f5;
						font-weight: 600;
					}
				}
			}
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		gap: 20px;
		.right-side-wrapper {
			border-radius: 15px;
			padding: 22px;
			background: #f2f6ff;
			.divider {
				margin: 5px 0px;
				height: 2px;
				width: 100%;
				background-image: linear-gradient(
					to left,
					#c0b928,
					#c89d18,
					#cb811a,
					#c86524,
					#bf492f,
					#b24e25,
					#a5531d,
					#985617,
					#7e701d,
					#628340,
					#45916c,
					#399b95
				);
			}
			.right-side-card {
				padding: 20px;
				.card-items {
					display: grid;
					grid-template-columns: 8fr 4fr;
					font-size: 18px;
					.name {
						padding: 8px 0px;
					}
					.value {
						text-align: right;
						padding: 8px 0px;
					}
				}
			}
			.order-card {
				border-radius: 20px !important;
				background: #e0e0e0;
				box-shadow: 5px 5px 15px #5a5a5a, -5px -5px 15px #ffffff;
			}
		}
	}
}
.c-pagination {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	margin-top: 15px;
	a {
		color: #737373;
		font-size: 14px;
		font-weight: 600;
		cursor: pointer;
	}
}
.active {
	color: black !important;
}
</style>
