import { GET_AUTH_HEADER } from "../getter.names";
import { Vue } from "vue-property-decorator";

import {} from "../getter.names";
import {
	FETCH_CAMPAIGNS,
	FETCH_CAMPAIGNS_DETAIL,
	UPDATE_CAMPAIGNS,
	CREATE_CAMPAIGN,
	DELETE_CAMPAIGN,
	CREATE_CAMPAIGN_PRODUCT,
	UPDATE_CAMPAIGN_PRODUCT,
	DELETE_CAMPAIGN_PRODUCT,
} from "../action.names";
import { FETCH_CAMPAIGNS_ENDPOINT } from "../endpoints";
import {} from "../mutation.names";
import { NS_USER } from "../namespace.names";
import { namespaced, buildParams } from "../utils";

export default {
	namespaced: true,
	state: {},
	getters: {},
	actions: {
		async [FETCH_CAMPAIGNS]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				let relativeUrl = `${FETCH_CAMPAIGNS_ENDPOINT}${buildParams(payload)}`;
				axios
					.get(relativeUrl, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [CREATE_CAMPAIGN]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.post(`${FETCH_CAMPAIGNS_ENDPOINT}`, payload, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [CREATE_CAMPAIGN_PRODUCT]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.post(
						`${FETCH_CAMPAIGNS_ENDPOINT}${payload.campaign_id}/add-product/`,
						payload.productData,
						{
							headers: {
								...authHeader,
							},
						}
					)
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [UPDATE_CAMPAIGN_PRODUCT]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.patch(
						`${FETCH_CAMPAIGNS_ENDPOINT}update-product/${payload.collection_through_id}/`,
						payload.productData,
						{
							headers: {
								...authHeader,
							},
						}
					)
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [DELETE_CAMPAIGN_PRODUCT]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.delete(
						`${FETCH_CAMPAIGNS_ENDPOINT}delete-product/${payload.collection_through_id}/`,
						{
							headers: {
								...authHeader,
							},
						}
					)
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [FETCH_CAMPAIGNS_DETAIL]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_CAMPAIGNS_ENDPOINT}${payload.id}/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [DELETE_CAMPAIGN]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.delete(`${FETCH_CAMPAIGNS_ENDPOINT}${payload.id}/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [UPDATE_CAMPAIGNS]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.patch(
						`${FETCH_CAMPAIGNS_ENDPOINT}${payload.id}/`,
						payload.formData,
						{
							headers: {
								...authHeader,
							},
						}
					)
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
	},

	mutations: {},
};
