<template>
	<div class="table-container" v-if="results.length > 0">
		<div class="c-table">
			<div class="c-head">
				<div>Name</div>
				<div>Category</div>
				<div>Quantity</div>
				<div>Price</div>
				<div>Publish</div>
				<div>Stock</div>
			</div>
			<!-- <sui-divider /> -->
			<div class="table-body-container" v-if="results">
				<div
					:class="[
						selectedProduct && res.id == selectedProduct.id
							? 'selected-product table-row'
							: 'table-row',
					]"
					v-for="(res, idx) in results"
					:key="'res_' + idx"
					@click="onSelectProduct(res)"
				>
					<ProductSingleRow :res="res" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Icon } from "@iconify/vue2";
import { API_HOST } from "../../../global";
import ProductSingleRow from "./ProductSingleRow.vue";

@Component({
	name: "ProductChoicesTable",
	components: {
		Icon,
		ProductSingleRow,
	},
})
export default class ProductChoicesTable extends Vue {
	@Prop() results;

	HOST = API_HOST;
	selectedProduct = null;

	onSelectProduct(product) {
		this.selectedProduct = {
			id: product.id,
			file: this.HOST + product.default_image.original.src,
			name: product.name,
			price: product.price,
		};

		this.$emit("emitSelectedProduct", this.selectedProduct);
	}
}
</script>

<style scoped lang="scss">
.table-container {
	.c-table {
		width: 100%;
		border: 1px solid #a4a4a4;
		box-sizing: border-box;
		border-radius: 20px;
		background-color: #f7f7f7;
		.c-head {
			display: grid;
			grid-template-columns: 2fr 2fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
			gap: 10px;
			height: 60px;
			background-color: black;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			div {
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 600;
				font-size: 18px;
				color: white;
			}
		}
		.table-body-container {
			.table-row {
				margin: 5px 0px;
				cursor: pointer;

				-webkit-transition: 0.4s ease-in-out;
				transition: 0.4s ease-in-out;
			}
			.table-row:hover {
				border: 1px red solid;
				border-radius: 5px;
				-webkit-transform: scale(0.9);
				transform: scale(0.99);
			}
		}
	}
}

.selected-product {
	border: 1px red solid;
	border-radius: 5px;
}

.detail-link {
	display: none !important;
}
.icon-font {
	font-size: 25px;
}

.ui.divider {
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	border-top: 1px solid #a4a4a4 !important;
}
.active {
	color: black !important;
}
.order-number {
	font-size: 14px !important;
}
.empty-wishlist {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	img {
		height: 100%;
	}
	p {
		margin-top: 30px;
		font-size: 18px;
		font-weight: 300;
	}
}
.icon {
	margin: 0px !important;
	padding: 0px !important;
	margin-top: -10px !important;
	cursor: pointer;
}
</style>
