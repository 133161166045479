<template>
	<div class="user-wrapper">
		<div class="top">
			<div class="left">Update Brand</div>
			<div class="right"></div>
		</div>
		<div class="content">
			<ValidationObserver v-slot="{ invalid }">
				<form method="post" @submit.prevent="handleSubmit">
					<div class="left">
						<div class="input-item">
							<label>Name</label>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<input name="name" placeholder="Name" v-model="formData.name" />
								<span v-if="errors[0]">* {{ errors[0] }}</span>
							</ValidationProvider>
						</div>

						<div class="input-item richtext">
							<label>Images</label>
							<div class="image-items">
								<div class="item">
									<label for="image_upload" class="round-orange-btn img-btn"
										>Add image</label
									>
									<input
										type="file"
										ref="img"
										name="product-image"
										class="image-btn"
										accept="image/*"
										id="image_upload"
										@change="addPhoto($event.target.name, $event)"
									/>
								</div>
							</div>
						</div>

						<div class="input-item" v-if="imageFile">
							<label></label>
							<div class="two wide column image-container">
								<img :src="imageFile.preview" style="height: 120px" />
							</div>
						</div>

						<div class="input-item" v-else-if="brandDetail">
							<label></label>
							<div class="two wide column image-container">
								<img
									v-if="brandDetail.image.meta"
									:src="HOST + brandDetail.image.meta.src"
									style="height: 120px"
								/>
							</div>
						</div>

						<div class="input-item btn">
							<button class="round-orange-btn" :disabled="invalid">
								Publish
							</button>
							<a class="discard-btn" href=""> Discard </a>
						</div>
					</div>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../../../store/utils";
import { API_HOST } from "../../../global";
import { NS_USER, NS_PRODUCTS } from "../../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../../store/mutation.names";
import { FETCH_BRAND_DETAIL } from "../../../store/action.names";
import { UPDATE_BRAND } from "../../../store/action.names";
import { GET_TOKEN } from "../../../store/getter.names";
import { Icon } from "@iconify/vue2";
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";

@Component({
	name: "PageAddBrand",
	components: {
		Icon,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class PageAddBrand extends Vue {
	@Getter(namespaced(NS_USER, GET_TOKEN)) getToken;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;

	@Action(namespaced(NS_PRODUCTS, FETCH_BRAND_DETAIL)) fetchBrandDetail;
	@Action(namespaced(NS_PRODUCTS, UPDATE_BRAND)) updateBrand;

	HOST = API_HOST;
	formData = {
		name: "",
		image: null,
	};
	brandId = this.$route.params.id;
	brandDetail = null;
	imageFile = null;

	addPhoto(fieldName, event) {
		this.imageFile = {
			file: event.target.files[0],
			preview: URL.createObjectURL(event.target.files[0]),
		};
		this.$refs.img.value = null;
	}

	fetchData() {
		this.fetchBrandDetail({ id: this.brandId }).then((data) => {
			this.brandDetail = data;
			this.formData.name = this.brandDetail.name;
		});
	}

	handleSubmit() {
		var that = this;

		this.updateBrand({
			id: this.brandDetail.id,
			formData: this.formData,
		}).then((data) => {
			if (this.imageFile) {
				var imageUploadFormData = new FormData();
				imageUploadFormData.append(`image`, this.imageFile.file);
				axios
					.post(
						`${that.HOST}/api/v1/admin/brands/${this.brandDetail.id}/upload_image/`,
						imageUploadFormData,
						{
							headers: {
								Authorization: `Token ${that.getToken}`,
							},
						}
					)
					.then(function (response) {
						that.$router.push({ name: "brand" });
					});
			}
		});
	}

	mounted() {
		this.setActiveSidebar("products");
		this.setActiveSubmenu("brand");
		this.fetchData();
	}
}
</script>

<style scoped lang="scss">
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.left {
		font-weight: 700;
		font-size: 20px;
	}
	.right {
		display: flex;
		gap: 40px;
		.add-new-user-btn {
			height: 36px;
			min-width: 150px;
			background-color: #edf1fe;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-left: 10px;
			padding-right: 10px;
			color: #5c83f3;
			cursor: pointer;
			font-weight: 500;
			.add-user-icon {
				font-size: 20px;
				font-weight: 500;
				margin-right: 5px;
			}
		}
	}
}
.content {
	span {
		form {
			margin-top: 30px;
			display: grid;
			grid-template-columns: 8fr 4fr;
			gap: 20px;
			.left {
				// border: 1px solid red;
				min-height: 300px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				.input-item {
					display: grid;
					grid-template-columns: 1fr 6fr;
					gap: 15px;
					align-items: center;
					span {
						display: flex;
						flex-direction: column;
					}
					label {
						width: 300px;
						font-weight: 600;
						font-size: 18px;
					}
					input {
						height: 40px;
						padding-left: 15px;
						width: 90%;
					}
					select {
						width: 90%;
						height: 40px;
					}
					.items {
						display: grid;
						grid-template-columns: 1fr 1fr;
						gap: 20px;
						width: 95%;
						label {
							font-size: 16px;
							font-weight: 400;
						}
					}
					.items3 {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;
						gap: 20px;
						width: 95%;
						margin-bottom: 50px;
						label {
							font-size: 16px;
							font-weight: 400;
						}
					}
					.image-items {
						display: grid;
						grid-template-columns: 1fr;
						gap: 20px;
						margin-top: 20px;
						.item {
							.image-btn {
								display: none;
							}
						}
					}
				}
				.richtext {
					align-items: flex-start;
				}
			}
			.right {
				border: 1px solid blue;
				min-height: 300px;
			}
		}
	}
}
.image-container {
	position: relative;
	width: 100%;
	margin-bottom: 20px !important;
}

.image-container .btn-btn {
	position: absolute;
	top: 0%;
	left: 80%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	color: black;
	font-size: 16px;
	padding: 12px 24px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	text-align: center;
}
.img-btn {
	font-size: 14px !important;
}
.btn {
	margin-bottom: 50px;
	button {
		cursor: pointer;
	}
}
.discard-btn {
	background-color: red;
	border-radius: 14px;
	padding: 10px;
	padding-left: 25px;
	padding-right: 25px;
	color: white;
	font-size: 16px;
	font-weight: 700;
	text-decoration: none;
	border: none;
	width: 120px;
	text-align: center;
}
.variant-container {
	width: 90%;
	margin: 0 auto;
	background-color: #f2f6ff;
	padding: 15px;
}
.dropdown {
	position: relative;
	width: 100%;
	margin: 0 auto;
}
.dropdown-input,
.dropdown-selected {
	width: 100%;
	padding: 10px 16px;
	border: 1px solid transparent;
	background: #edf2f7;
	line-height: 1.5em;
	outline: none;
	border-radius: 8px;
}
.dropdown-input:focus,
.dropdown-selected:hover {
	background: #fff;
	border-color: #e2e8f0;
}
.dropdown-input::placeholder {
	opacity: 0.7;
}
.dropdown-selected {
	font-weight: bold;
	cursor: pointer;
}
.dropdown-list {
	position: absolute;
	width: 100%;
	max-height: 500px;
	margin-top: 4px;
	overflow-y: auto;
	background: #ffffff;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	z-index: 1000;
}
.dropdown-item {
	display: flex;
	width: 100%;
	padding: 11px 16px;
	cursor: pointer;
}
.dropdown-item:hover {
	background: #edf2f7;
}
.dropdown-item-flag {
	max-width: 24px;
	max-height: 18px;
	margin: auto 12px auto 0px;
}
</style>
