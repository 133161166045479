<template>
	<div class="user-wrapper">
		<div class="top">
			<!-- <div class="left">Product analytics</div>
      <div class="right">
        <div class="import-button">
          <Icon class="add-user-icon" icon="akar-icons:download" />
          <span>Import/Export</span>
        </div>
      </div> -->
		</div>
		<div class="content" v-if="data">
			<div class="left">
				<div class="header">Summary of products</div>
				<div class="summary-card">
					<card-analytics
						card-header="Total products"
						card-icon="icon-park-outline:transaction-order"
						:count-value="
							data.analytics_summary.total_product
								? data.analytics_summary.total_product
								: 0
						"
					>
					</card-analytics>
					<card-analytics
						card-header="New products"
						card-icon="icon-park-outline:transaction-order"
						:count-value="
							data.analytics_summary.new_product
								? data.analytics_summary.new_product
								: 0
						"
					>
					</card-analytics>
					<card-analytics
						card-header="Published products"
						card-icon="icon-park-outline:transaction-order"
						:count-value="
							data.analytics_summary.published_product
								? data.analytics_summary.published_product
								: 0
						"
					>
					</card-analytics>
					<card-analytics
						card-header="Unpublished products"
						card-icon="icon-park-outline:transaction-order"
						:count-value="
							data.analytics_summary.unpublished_product
								? data.analytics_summary.unpublished_product
								: 0
						"
					>
					</card-analytics>
					<card-analytics
						card-header="Dispatch today"
						card-icon="bi:clock"
						:count-value="
							data.analytics_summary.dispatch_today
								? data.analytics_summary.dispatch_today
								: 0
						"
					>
					</card-analytics>
					<card-analytics
						card-header="Ready to dispatch"
						card-icon="clarity:date-outline-badged"
						:count-value="
							data.analytics_summary.ready_to_dispatch
								? data.analytics_summary.ready_to_dispatch
								: 0
						"
					>
					</card-analytics>
					<card-analytics
						card-header="Out of stock"
						card-icon="bi:graph-up-arrow"
						:count-value="totalOutOfStock"
					>
					</card-analytics>
					<card-analytics
						card-header="Product ship"
						card-icon="icon-park-outline:cycle"
						:count-value="
							data.analytics_summary.shipped_today
								? data.analytics_summary.shipped_today
								: 0
						"
					>
					</card-analytics>
				</div>
				<div class="finantical">
					<div class="finantical-header">
						<div class="left">
							<span class="header">Product analytics</span>
						</div>
						<div class="right">
							<div class="user-filter">
								<div class="label">Filter</div>
								<div class="dropdown-wrapper">
									<div @click="toggleSelectDateDropdown" class="dropdown">
										{{ dateFilter }}
										<Icon icon="gridicons:dropdown" />
									</div>
									<div v-if="showSelectDate" class="dropdown-content">
										<div
											@click="toggleSelectDateDropdown(null, 'Last Month')"
											class="item"
										>
											Last Month
										</div>
										<div
											@click="toggleSelectDateDropdown(null, '3 Month')"
											class="item"
										>
											3 Month
										</div>
										<div
											@click="toggleSelectDateDropdown(null, '6 Month')"
											class="item"
										>
											6 Month
										</div>
										<div
											@click="toggleSelectDateDropdown(null, '12 Month')"
											class="item"
										>
											12 Month
										</div>
										<div
											@click="toggleSelectDateDropdown(null, 'All')"
											class="item"
										>
											All
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="finantial-description">
						<p class="total-earning">
							<span>Total products</span>
							<span class="amount">
								{{ intNumberFormat(totalProduct) }} items</span
							>
						</p>

						<div>
							<ProductAnalyticsChart v-if="data" :graphData="graphData" />
						</div>
					</div>
				</div>
				<div class="product-section">
					<div class="top">
						<div class="left">Products</div>
						<div class="right">
							<div class="right-items">
								<!-- <div class="import-button">
									<Icon class="add-user-icon" icon="akar-icons:download" />
									<span>Download Excel</span>
								</div>
								<div class="dropdown">Below 20</div> -->
							</div>
						</div>
					</div>
					<div class="product-description">
						<p class="total-earning">
							<span>Out of Stock</span>
							<span class="amount">{{ totalOutOfStock }} Items</span>
						</p>

						<div>
							<card-product-analytics
								v-for="(item, idx) in outStockProducts"
								:key="'item_' + idx"
								:res="item"
							/>
							<div class="c-pagination" v-if="paginationSteps.length > 1">
								<a class="item" @click.prevent="handleDecrement"> Prev </a>
								<a
									:class="{ active: page == pag, item: true }"
									v-for="(pag, idx) in paginationSteps"
									:key="'pag_' + idx"
									@click.prevent="updatePage(pag)"
								>
									{{ pag }}
								</a>
								<a class="item" @click.prevent="handleIncreament"> Next </a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="right-side-wrapper">
					<div class="right-side-card product-card">
						<h3>Best selling items</h3>
						<div class="card-items">
							<div
								v-for="(d, idx) in data.analytics_summary.best_sales"
								:key="'data_' + idx"
								class="product-row"
							>
								<div class="name">{{ idx + 1 }}. {{ d[0] }}</div>
								<div class="value">{{ intNumberFormat(d[1]) }}</div>
							</div>
						</div>
					</div>

					<div class="right-side-card product-card">
						<h3>Most viewed items</h3>
						<div class="card-items">
							<div
								v-for="(d, idx) in data.analytics_summary.most_viewed"
								:key="'data_' + idx"
								class="product-row full-grid"
							>
								<div class="name">{{ idx + 1 }}. {{ d[0] }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced, intNumberFormat } from "../../store/utils";
import { API_HOST } from "../../global";
import { NS_USER, NS_ORDERS, NS_ANALYTICS } from "../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../store/mutation.names";
import {
	FETCH_ORDERS,
	FETCH_PRODUCT_ANALYTICS_SUMMARY,
	OUT_OF_STOCK_PRODUCT,
} from "../../store/action.names";
import { Icon } from "@iconify/vue2";
import CardAnalytics from "./CardAnalytics";
import CardProductAnalytics from "./snippets/CardProductAnalytics";
import ProductAnalyticsChart from "./snippets/ProductAnalyticsChart";

@Component({
	name: "PageAnalytics",
	components: {
		ProductAnalyticsChart,
		CardProductAnalytics,
		CardAnalytics,
		Icon,
	},
})
export default class PageAnalytics extends Vue {
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;

	@Action(namespaced(NS_ANALYTICS, FETCH_PRODUCT_ANALYTICS_SUMMARY))
	fetchProductAnalyticsSummary;

	@Action(namespaced(NS_ANALYTICS, OUT_OF_STOCK_PRODUCT)) outOfStockProduct;

	HOST = API_HOST;
	current_route = "business-analytics";
	status = "";
	data = null;
	totalOutOfStock = 0;
	outStockProducts = [];
	intNumberFormat = intNumberFormat;
	dateFilter = "Last Month";
	showSelectDate = false;

	page = this.$route.query.page ? this.$route.query.page : 1;
	count = "";
	paginationSteps = [this.page];
	totalPageCount = "";

	@Watch("$route")
	handleChangeRoute(val, oldVal) {
		this.page = this.$route.query.page ? this.$route.query.page : 1;
		if (val != oldVal) {
			this.handleFetchOutOfStockProduct();
			this.paginationSteps = [this.page];
		}
	}

	get graphData() {
		if (this.dateFilter == "Last Month") {
			return this.data.graph.last_month.graph;
		} else if (this.dateFilter == "3 Month") {
			return this.data.graph.last_3_month.graph;
		} else if (this.dateFilter == "6 Month") {
			return this.data.graph.last_6_month.graph;
		} else if (this.dateFilter == "12 Month") {
			return this.data.graph.last_12_month.graph;
		} else if (this.dateFilter == "All") {
			return this.data.graph.all.graph;
		} else {
			return [];
		}
	}

	get totalProduct() {
		if (this.dateFilter == "Last Month") {
			return this.data.graph.last_month.total_product;
		} else if (this.dateFilter == "3 Month") {
			return this.data.graph.last_3_month.total_product;
		} else if (this.dateFilter == "6 Month") {
			return this.data.graph.last_6_month.total_product;
		} else if (this.dateFilter == "12 Month") {
			return this.data.graph.last_12_month.total_product;
		} else if (this.dateFilter == "All") {
			return this.data.graph.all.total_product;
		} else {
			return 0;
		}
	}

	toggleSelectDateDropdown(value, data) {
		if (data) {
			this.dateFilter = data;
		}
		this.showSelectDate = !this.showSelectDate;
	}

	setStatus() {
		let sub_route = "";
		if (this.$route) {
			sub_route = this.$route.params.slug;
		}
		if (sub_route && sub_route != "business-analytics") {
			this.status = sub_route;
			this.current_route = `business-analytics/${sub_route}`;
		} else {
			this.status = "";
			this.current_route = "business-analytics";
		}
	}

	handleFetchData() {
		this.fetchProductAnalyticsSummary().then((data) => {
			this.data = data;
		});
	}

	handleDecrement() {
		if (this.page > 1) {
			// this.page = this.page - 1;
			if (this.$route.query) {
				this.$router.push(
					`/business-analytics/product-analytics?page=${
						parseInt(this.page) - 1
					}`
				);
			}
		} else {
			// this.page = 1;
			if (this.$route.query) {
				this.$router.push(`/business-analytics/product-analytics?page=1`);
			}
		}
	}

	handleIncreament() {
		if (this.page < this.totalPageCount) {
			// this.page = this.page + 1;
			if (this.$route.query) {
				this.$router.push(
					`/business-analytics/product-analytics?page=${
						parseInt(this.page) + 1
					}`
				);
			}
		} else {
			// this.page = this.totalPageCount;
			if (this.$route.query) {
				this.$router.push(
					`/business-analytics/product-analytics?page=${this.totalPageCount}`
				);
			}
		}
	}

	updatePage(pageNo) {
		// this.page = pageNo;
		if (this.$route.query) {
			this.$router.push(`/business-analytics/product-analytics?page=${pageNo}`);
		}
	}

	calculateNext(totalPage) {
		if (totalPage > 1) {
			var next = this.page;
			for (var i = 0; i < totalPage; i++) {
				if (i > 3) {
					break;
				}
				if (this.page < totalPage) {
					next = parseInt(next) + 1;
					if (next > totalPage) {
						break;
					}
					this.paginationSteps.push(next);
				}
			}
		}
	}

	calculatePrev() {
		if (this.page > 1) {
			var counter = 0;
			for (var i = this.page - 1; i > 0; i--) {
				if (counter > 3) {
					break;
				}
				this.paginationSteps.unshift(i);
				counter += 1;
			}
		}
	}

	calculatePage() {
		const totalPage = Math.ceil(this.count / 15);
		this.totalPageCount = totalPage;
		if (totalPage > 1) {
			this.calculateNext(totalPage);
			this.calculatePrev();
		}
	}

	handleFetchOutOfStockProduct() {
		let params = {
			page: this.page,
		};

		this.outOfStockProduct(params).then((data) => {
			this.outStockProducts = data.results;
			this.totalOutOfStock = data.count;

			this.count = data.count;
			this.calculatePage();
		});
	}

	mounted() {
		this.setActiveSidebar("business-analytics");
		this.setActiveSubmenu("product-analytics");
		this.setStatus();
		this.handleFetchData();
		this.handleFetchOutOfStockProduct();
		if (this.$route.query) {
			if (this.$route.query.page) {
				this.page = this.$route.query.page;
			}
		}
	}
}
</script>

<style scoped lang="scss">
.header {
	font-weight: 700;
	font-size: 20px;
	margin: 0px 0px;
}
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.left {
		font-weight: 700;
		font-size: 20px;
	}
	.right {
		display: flex;
		gap: 40px;
		.right-items {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 40px;
		}
		.dropdown {
			font-size: 18px;
			color: #90a9f5;
		}
		.import-button {
			cursor: pointer;
			font-size: 18px;
			color: #f1b450;
			span {
				margin-left: 5px;
			}
		}
	}
}
.content {
	margin-top: 30px;
	display: grid;
	grid-template-columns: 9fr 3fr;
	gap: 20px;
	.left {
		display: flex;
		flex-direction: column;
		gap: 20px;
		.summary-card {
			display: grid;
			grid-template-columns: 3fr 3fr 3fr 3fr;
			gap: 10px;
		}
		.finantical {
			margin-top: 20px;
			.finantical-header {
				display: flex;
				margin-bottom: 10px;
				.left {
				}
				.right {
					margin-left: auto;
					.user-filter {
						height: 36px;
						width: 200px;
						background-color: #edf1fe;
						border-radius: 10px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-left: 10px;
						padding-right: 10px;
						.label {
							font-weight: 500;
							font-size: 16px;
							color: #5c83f3;
						}
						.dropdown-wrapper {
							position: relative;
							.dropdown {
								width: 130px;
								height: 28px;
								background-color: #5c83f3;
								color: white;
								font-weight: 500;
								border-radius: 8px;
								display: flex;
								justify-content: center;
								align-items: center;
								cursor: pointer;
								position: relative;
							}
							.dropdown-content {
								position: absolute;
								top: 30px;
								width: 130px;
								margin-top: 5px;
								background-color: white;
								z-index: 999;
								box-shadow: 5px 5px 4px 5px #f1f1f1;
								.item {
									padding: 10px;
									cursor: pointer;
								}
							}
						}
					}
				}
			}
			.finantial-description {
				.total-earning {
					font-size: 18px;
					color: #74777e;
					.amount {
						margin-left: 20px;
						color: #90a9f5;
						font-weight: 600;
					}
				}
			}
		}
		.product-section {
			margin-top: 20px;
			.product-description {
				.total-earning {
					margin-top: 10px;
					font-size: 18px;
					color: #74777e;
					.amount {
						margin-left: 20px;
						color: #90a9f5;
						font-weight: 600;
					}
				}
			}
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		gap: 20px;
		.right-side-wrapper {
			border-radius: 15px;
			padding: 22px;
			background: #f2f6ff;
			.divider {
				margin: 5px 0px;
				height: 2px;
				width: 100%;
				background-image: linear-gradient(
					to left,
					#c0b928,
					#c89d18,
					#cb811a,
					#c86524,
					#bf492f,
					#b24e25,
					#a5531d,
					#985617,
					#7e701d,
					#628340,
					#45916c,
					#399b95
				);
			}
			.right-side-card {
				padding: 20px;
				margin-bottom: 20px;
				.card-items {
					.product-row {
						display: grid;
						grid-template-columns: 8fr 4fr;
						font-size: 18px;
						.name {
							padding: 8px 0px;
						}
						.value {
							text-align: right;
							padding: 8px 0px;
						}
					}
					.full-grid {
						display: grid;
						grid-template-columns: 1fr;
					}
				}
			}
			.product-card {
				border-radius: 20px !important;
				background: #e0e0e0;
				box-shadow: 5px 5px 15px #5a5a5a, -5px -5px 15px #ffffff;
			}
		}
	}
}
.c-pagination {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	margin-top: 15px;
	a {
		color: #737373;
		font-size: 14px;
		font-weight: 600;
		cursor: pointer;
	}
}
.active {
	color: black !important;
}
</style>
