<template>
	<div class="user-wrapper">
		<div class="top">
			<div class="left">Add Campaign</div>
			<div class="right"></div>
		</div>
		<div class="content">
			<ValidationObserver v-slot="{ invalid }">
				<sui-form method="post" @submit.prevent="handleSubmit">
					<label>Campaign Name</label>
					<sui-form-field>
						<input required v-model="formCamp.name" placeholder="Combo offer" />
					</sui-form-field>

					<label>Discount Type</label>
					<select required name="Discount type" v-model="formCamp.type">
						<option value="">Select discount type</option>
						<option
							v-for="(b, bidx) in camp_type_choices"
							:key="'Discount type' + bidx"
							:value="b.value"
						>
							{{ b.label }}
						</option>
					</select>

					<label>Is Active</label>
					<div>
						<sui-checkbox label="" toggle v-model="formCamp.is_active" />
					</div>

					<label>End Date</label>
					<sui-form-field>
						<datepicker
							required
							v-model="formCamp.end_date"
							type="datetime"
							valueType="format"
							:use12h="true"
							placeholder="Select date"
						>
						</datepicker>
					</sui-form-field>

					<modal-choose-image @passSelectedImage="getSelectedImage">
					</modal-choose-image>

					<sui-image :src="selected_icon.file" size="tiny" />

					<div class="product-add-button">
						<modal-choose-product @passSelectedProduct="getSelectedProduct">
						</modal-choose-product>
					</div>

					<div>
						<!-- selected products -->
						<!-- formCamp.products -->
						<div class="item">
							<div class="ui grid" v-if="selectedProductList.length > 0">
								<div
									v-for="(product, idx) in selectedProductList"
									:key="'product' + idx"
									class="row"
								>
									<div class="twelve wide column image-container">
										<input
											class="product-input-filed"
											disabled
											v-model="product.name"
											placeholder="Combo offer"
										/>
										<input
											class="product-input-filed"
											v-model="product.price"
											placeholder="Combo offer"
										/>
									</div>
									<div class="four wide column image-container">
										<sui-image
											:src="product.file"
											style="width: 100%; height: 120px"
										/>
										<i class="close icon btn-btn" @click="removeProduct(idx)">
										</i>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="input-item btn" style="margin-top: 40px">
						<button
							style="margin-right: 10px"
							class="round-green-btn"
							:disabled="invalid"
						>
							Publish
						</button>
						<a class="discard-btn" href=""> Discard </a>
					</div>
				</sui-form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../../store/utils";
import { API_HOST } from "../../global";
import {
	NS_USER,
	NS_PRODUCTS,
	NS_CAMPAIGNS,
} from "../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../store/mutation.names";
import { CREATE_CAMPAIGN } from "../../store/action.names";
import { GET_TOKEN } from "../../store/getter.names";
import { Icon } from "@iconify/vue2";
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import ModalChooseImage from "../../components/common/ModalChooseImage.vue";
import ModalChooseProduct from "../../components/common/product/ModalChooseProduct.vue";

@Component({
	name: "PageAddCampaign",
	components: {
		Icon,
		ValidationProvider,
		ValidationObserver,
		ModalChooseImage,
		ModalChooseProduct,
	},
})
export default class PageAddCampaign extends Vue {
	@Getter(namespaced(NS_USER, GET_TOKEN)) getToken;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;

	@Action(namespaced(NS_CAMPAIGNS, CREATE_CAMPAIGN)) createCampaigns;

	HOST = API_HOST;
	formCamp = {
		name: "",
		type: "",
		end_date: this.$dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss"),
		is_active: false,
		icon: null,
	};
	selectedProductList = [];

	selected_icon = {
		file: "",
		id: "",
	};

	camp_type_choices = [
		{
			value: "discount_sale",
			label: "Discount Sale",
		},
		{
			value: "cashback_sale",
			label: "Cashback Sale",
		},
		{
			value: "other",
			label: "Other",
		},
	];

	getSelectedImage(value) {
		//adding emited image to images list
		this.selected_icon = value;
	}
	getSelectedProduct(value) {
		this.selectedProductList.push(value);
	}

	removeProduct(idx) {
		this.selectedProductList.splice(idx, 1);
	}

	handleSubmit() {
		if (this.formCamp.end_date == "") {
			Vue.$toast.error("You need to select end date!", {});
			return false;
		}
		if (this.selectedProductList.length < 1) {
			Vue.$toast.error("You need to select atleast 1 product!", {});
			return false;
		}
		if (this.selected_icon.id != "") {
			this.formCamp.icon = this.selected_icon.id;
		}

		let newProductList = [];
		this.selectedProductList.forEach((element) => {
			newProductList.push({
				product: element.id,
				price: element.price,
			});
		});
		this.formCamp.products = newProductList;

		this.createCampaigns(this.formCamp).then((data) => {
			Vue.$toast.success("Campaign created successfully!", {});
			this.$router.push({ name: "admin-campaigns" });
		});
	}

	mounted() {
		this.setActiveSidebar("admin-campaigns");
	}
}
</script>

<style scoped lang="scss">
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.left {
		font-weight: 700;
		font-size: 20px;
	}
	.right {
		display: flex;
		gap: 40px;
		.add-new-user-btn {
			height: 36px;
			min-width: 150px;
			background-color: #edf1fe;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-left: 10px;
			padding-right: 10px;
			color: #5c83f3;
			cursor: pointer;
			font-weight: 500;
			.add-user-icon {
				font-size: 20px;
				font-weight: 500;
				margin-right: 5px;
			}
		}
	}
}
.content {
	span {
		form {
			padding: 0px 20%;
			margin-top: 30px;
			display: grid;
			grid-template-columns: 3fr 6fr;
			gap: 20px;
			.left {
				// border: 1px solid red;
				min-height: 300px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				.input-item {
					display: grid;
					grid-template-columns: 1fr 6fr;
					gap: 15px;
					align-items: center;
					span {
						display: flex;
						flex-direction: column;
					}
					label {
						width: 300px;
						font-weight: 600;
						font-size: 18px;
					}
					input {
						height: 40px;
						padding-left: 15px;
						width: 90%;
					}
					select {
						width: 90%;
						height: 40px;
					}
					.items {
						display: grid;
						grid-template-columns: 1fr 1fr;
						gap: 20px;
						width: 95%;
						label {
							font-size: 16px;
							font-weight: 400;
						}
					}
					.items3 {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;
						gap: 20px;
						width: 95%;
						margin-bottom: 50px;
						label {
							font-size: 16px;
							font-weight: 400;
						}
					}
					.image-items {
						display: grid;
						grid-template-columns: 1fr;
						gap: 20px;
						margin-top: 20px;
						.item {
							.image-btn {
								display: none;
							}
						}
					}
				}
				.richtext {
					align-items: flex-start;
				}
			}
			.right {
				border: 1px solid blue;
				min-height: 300px;
			}
		}
	}
}
.product-input-filed {
	margin: 3px 0px !important;
}

.image-container {
	position: relative;
	width: 100%;
	margin-bottom: 20px !important;
}

.image-container .btn-btn {
	position: absolute;
	top: 0%;
	left: 80%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	color: black;
	font-size: 16px;
	padding: 12px 24px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	text-align: center;
}
.img-btn {
	font-size: 14px !important;
}
.btn {
	margin-bottom: 50px;
	button {
		cursor: pointer;
	}
}
.discard-btn {
	background-color: red;
	border-radius: 14px;
	padding: 10px;
	padding-left: 25px;
	padding-right: 25px;
	color: white;
	font-size: 16px;
	font-weight: 700;
	text-decoration: none;
	border: none;
	width: 120px;
	text-align: center;
}
.variant-container {
	width: 90%;
	margin: 0 auto;
	background-color: #f2f6ff;
	padding: 15px;
}
.dropdown {
	position: relative;
	width: 100%;
	margin: 0 auto;
}
.dropdown-input,
.dropdown-selected {
	width: 100%;
	padding: 10px 16px;
	border: 1px solid transparent;
	background: #edf2f7;
	line-height: 1.5em;
	outline: none;
	border-radius: 8px;
}
.dropdown-input:focus,
.dropdown-selected:hover {
	background: #fff;
	border-color: #e2e8f0;
}
.dropdown-input::placeholder {
	opacity: 0.7;
}
.dropdown-selected {
	font-weight: bold;
	cursor: pointer;
}
.dropdown-list {
	position: absolute;
	width: 100%;
	max-height: 500px;
	margin-top: 4px;
	overflow-y: auto;
	background: #ffffff;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	z-index: 1000;
}
.dropdown-item {
	display: flex;
	width: 100%;
	padding: 11px 16px;
	cursor: pointer;
}
.dropdown-item:hover {
	background: #edf2f7;
}
.dropdown-item-flag {
	max-width: 24px;
	max-height: 18px;
	margin: auto 12px auto 0px;
}
.product-add-button {
	margin-top: auto;
}
</style>
