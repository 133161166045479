<template>
	<div class="page-wrapper">
		<div class="top">
			<div class="left">Contact Details</div>
			<div class="right"></div>
		</div>
		<div class="content">
			<div>Name</div>
			<div>
				{{ reviewDetail.name }}
			</div>
			<div>Email</div>
			<div>{{ reviewDetail.email }}</div>
			<div>Phone</div>
			<div>{{ reviewDetail.phone }}</div>
			<div>Date</div>
			<div>{{ reviewDetail.date }}</div>
			<div>Message</div>
			<div>{{ reviewDetail.message }}</div>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../../store/utils";
import { API_HOST } from "../../global";
import { NS_USER, NS_SETTINGS } from "../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../store/mutation.names";
import { FETCH_CONTACT_DETAIL, UPDATE_CONTACT } from "../../store/action.names";
import { Icon } from "@iconify/vue2";

@Component({
	name: "PageMessageDetails",
	components: {
		Icon,
	},
})
export default class PageMessageDetails extends Vue {
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;
	@Action(namespaced(NS_SETTINGS, FETCH_CONTACT_DETAIL)) fetchContactDetail;
	@Action(namespaced(NS_SETTINGS, UPDATE_CONTACT)) updateContact;

	HOST = API_HOST;
	data = null;
	contactId = this.$route.params.id;
	reviewDetail = {};

	fetchData() {
		this.fetchContactDetail({ id: this.contactId }).then((data) => {
			this.setData(data);
			if (!data.is_seen) {
				this.updateContactMessage();
			}
		});
	}
	updateContactMessage() {
		let updatedContactMessage = {
			id: this.contactId,
			formData: {
				is_seen: true,
			},
		};
		this.updateContact(updatedContactMessage).then((data) => {});
	}

	setData(data) {
		this.reviewDetail = data;
	}

	mounted() {
		this.setActiveSidebar("admin-messages");
		this.setActiveSubmenu("");
		this.fetchData();
	}
}
</script>

<style scoped lang="scss">
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.item-value {
		font-size: 18px;
	}
	.text-blue {
		color: #5b83f3;
	}
	.left {
		font-weight: 700;
		font-size: 20px;
	}
	.right {
		display: flex;
		gap: 40px;
	}
}
.content {
	padding: 0px 20%;
	margin-top: 30px;
	display: grid;
	grid-template-columns: 1fr 6fr;
	gap: 20px;
}

.btn {
	margin-bottom: 50px;
	button {
		cursor: pointer;
	}
}

.rigth-align-text {
	text-align: right;
	width: 90%;
}

.button-item {
	margin: 0px 10px;
}

.wrapper-note {
	background: #f2f6ff;
	.admin-note-header {
		background: #12233a;
		color: white;
		padding: 15px;
		border-radius: 20px 20px 0px 0px;
		display: flex;
		h2 {
			margin-bottom: 0px;
		}
		.minus-icon {
			margin-left: auto;
			display: flex;
			justify-content: center;
			text-align: center;
			font-size: 30px;
		}
	}
	.admin-note-detail {
		.custom-form {
			margin-top: 10px;
			display: grid;
			grid-template-columns: 12fr;
		}
		padding: 20px;
		.note-form-level {
			font-size: 18px;
			color: #86878a;
		}
	}
}
</style>
