<template>
  <div class="wrapper">
    <div class="header">
      <h2>HEALTHWAY ADMIN</h2>
    </div>
    <ValidationObserver v-slot="{ invalid }">
      <form 
        class="form" 
        @submit.prevent="handleLogin"
        ref="form"
      >
        <div class="input-wrapper">
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
          >
            <label for="username">* Username</label>
            <input 
              v-model="formData.username"
              id="username" 
              type="text" 
              name="username" 
              placeholder="Your phone / email" 
            />
            <span v-if="errors[0]">* {{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="input-wrapper">
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
          >
            <label for="password">* Password</label>
            <input 
              v-model="formData.password"
              id="password" 
              type="password" 
              name="password" 
              placeholder="Enter password" 
            />
            <span v-if="errors[0]">* {{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="input-wrapper">
          <p class="error" v-html="error_msg"></p>
        </div>

        <div class="input-wrapper">
          <button :disabled="invalid">Login</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { namespaced } from "../store/utils";
import { API_HOST } from "../global";
import { 
  LOGIN, 
} from "../store/action.names";
import { 
  GET_PROFILE, 
} from "../store/getter.names";
import { NS_USER } from "../store/namespace.names";

import {
    ValidationProvider,
    ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";

@Component({
  name: "LoginPage",
  components: {
    ValidationProvider,
    ValidationObserver
  }
})
export default class LoginPage extends Vue {
  @Action(namespaced(NS_USER, LOGIN)) login;

  @Getter(namespaced(NS_USER, GET_PROFILE)) profile;

  HOST = API_HOST;
  formData = {
    username: "",
    password: "",
  }
  error_msg = "";

  @Watch("profile")
  handleChangeProfile(val, oldVal){
    if(val.is_superuser || is_staff){
      this.$router.push("/");
    }
  }

  handleLogin(){
    this.login(this.formData).then((data) => {
      if(data.user.is_superuser || data.user.is_staff){
        this.$router.push("/");
      }else{
        this.error_msg = "* You are not allowed to logged in!";
      }
    }).catch((e) => {
      this.error_msg = "";
      var err_msg = "";
      for (const [key, value] of Object.entries(e.response.data)) {
        const err = `<p>${value}</p>`;
        err_msg = err_msg + err;
      }
      this.error_msg = err_msg;
    })
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 500px;
    border: 1px solid #f1f1f1;
    box-shadow: 10px 10px 5px 10px #aaaaaa;
  }
  .header{
    display: flex;
    justify-content: center;
    padding: 20px;
    border-bottom: 1px solid #f1f1f1;
    h2{
      font-weight: 500;
      letter-spacing: 1px;
      font-size: 25px;
    }
  }
  .form{
    margin-top: 50px;
  }

  .input-wrapper{
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
    span{
      display: flex;
      flex-direction: column;
      label{
        font-weight: 300;
        font-size: 14px;
        margin-bottom: 10px;
      }
      input{
        height: 40px;
        font-size: 16px;
      }
      span{
        color: red;
        font-size: 14px;
        margin-top: 5px;
      }
    }
    button{
      height: 40px;
      width: 120px;
      border: none;
      font-size: 16px;
      font-weight: 500;
      color: white;
      background-color: black;
      cursor: pointer;
    }
  }
  .error{
    color: red;
  }
</style>