<template>
	<div class="page-wrapper">
		<div class="top">
			<div class="left">
				<span v-if="reviewDetail">
					{{ reviewDetail.job_title }}
				</span>
				<span v-else> Job Application Details </span>
			</div>
			<div class="right"></div>
		</div>
		<div class="content">
			<div>Name</div>
			<div>{{ reviewDetail.name }}</div>

			<div>Email</div>
			<div>{{ reviewDetail.email }}</div>

			<div>Phone</div>
			<div>{{ reviewDetail.phone }}</div>

			<div>Date</div>
			<div>{{ reviewDetail.date }}</div>

			<div>linkedin Profile</div>
			<a :href="reviewDetail.linkedin_profile">
				{{ reviewDetail.linkedin_profile }}
			</a>

			<div>CV</div>
			<div>
				<iframe :src="reviewDetail.cv" frameborder="0"></iframe>
				<div></div>
				<sui-button
					class="view-pdf"
					v-if="reviewDetail.cv"
					@click.prevent="openPdf"
					color="teal"
					icon
				>
					<Icon
						class="icon-font expand-btn"
						icon="material-symbols:cloud-download-outline"
					/>
					<span> Download PDF</span>
				</sui-button>
			</div>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../../store/utils";
import { API_HOST } from "../../global";
import { NS_USER, NS_JOBS } from "../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../store/mutation.names";
import { FETCH_JOBS_DETAIL, UPDATE_JOBS } from "../../store/action.names";
import { Icon } from "@iconify/vue2";
import axios from "axios";

@Component({
	name: "PageApplicationDetails",
	components: {
		Icon,
	},
})
export default class PageApplicationDetails extends Vue {
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;
	@Action(namespaced(NS_JOBS, FETCH_JOBS_DETAIL)) fetchJobsDetail;
	@Action(namespaced(NS_JOBS, UPDATE_JOBS)) updateJobs;

	HOST = API_HOST;
	data = null;
	jobId = this.$route.params.id;
	reviewDetail = {};

	openPdf() {
		const response = axios.get(this.reviewDetail.cv, {
			responseType: "blob",
		});
		const blob = new Blob([response.data], { type: "application/pdf" });
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = `${this.reviewDetail.job_title}_${this.reviewDetail.name}`;
		link.click();
	}

	fetchData() {
		this.fetchJobsDetail({ id: this.jobId }).then((data) => {
			this.setData(data);
			if (!data.is_seen) {
				this.updateJobApplication();
			}
		});
	}

	updateJobApplication() {
		let updatedJobData = {
			id: this.jobId,
			formData: {
				is_seen: true,
			},
		};
		this.updateJobs(updatedJobData).then((data) => {});
	}

	setData(data) {
		this.reviewDetail = data;
	}

	mounted() {
		this.setActiveSidebar("job-applications");
		this.setActiveSubmenu("");
		this.fetchData();
	}
}
</script>

<style scoped lang="scss">
.view-pdf {
	margin-top: 10px !important;
}
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.item-value {
		font-size: 18px;
	}
	.text-blue {
		color: #5b83f3;
	}
	.left {
		font-weight: 700;
		font-size: 20px;
	}
	.right {
		display: flex;
		gap: 40px;
	}
}
.content {
	padding: 0px 20%;
	margin-top: 30px;
	display: grid;
	grid-template-columns: 1fr 6fr;
	gap: 20px;
}

.btn {
	margin-bottom: 50px;
	button {
		cursor: pointer;
	}
}

.rigth-align-text {
	text-align: right;
	width: 90%;
}

.button-item {
	margin: 0px 10px;
}

.wrapper-note {
	background: #f2f6ff;
	.admin-note-header {
		background: #12233a;
		color: white;
		padding: 15px;
		border-radius: 20px 20px 0px 0px;
		display: flex;
		h2 {
			margin-bottom: 0px;
		}
		.minus-icon {
			margin-left: auto;
			display: flex;
			justify-content: center;
			text-align: center;
			font-size: 30px;
		}
	}
	.admin-note-detail {
		.custom-form {
			margin-top: 10px;
			display: grid;
			grid-template-columns: 12fr;
		}
		padding: 20px;
		.note-form-level {
			font-size: 18px;
			color: #86878a;
		}
	}
}
</style>
