<template>
	<div class="page-wrapper">
		<div class="top">
			<div class="left">Return Detail</div>
			<div class="center ticket">
				<!-- TODO: move this to center  -->
				<!-- Ticket Number: #54-25478 -->
			</div>
			<div class="right">
				<!-- <div class="item-value text-blue order-status">
					<a href="#" @click="downloadInvoice()">
						<Icon class="add-user-icon" icon="akar-icons:download" />
						Download Issue
					</a>
				</div> -->
			</div>
		</div>
		<div class="content">
			<ValidationObserver>
				<form>
					<div class="left">
						<div class="order-item">
							<label>Invoice number : {{ returnDetail.order_id }}</label>
							<div class="rigth-align-text order-item-value">
								<a href="#">
									<b>
										Date: {{ $dayjs(returnDetail.date).format("YYYY-MM-DD") }}
									</b>
								</a>
							</div>
						</div>
						<div>
							<card-product-return :res="returnDetail"></card-product-return>
						</div>

						<div class="order-item">
							<label>Description</label>
							<div class="order-item-value">
								<div v-html="returnDetail.description"></div>
							</div>
						</div>

						<!-- <div class="order-item">
							<label>Message To Customer</label>
							<div class="order-item-value">
								<textarea
									class="message-to-cutomer"
									v-model="returnUpdateForm.message_to_customer"
									rows="3"
								>
								</textarea>
							</div>
						</div> -->

						<!-- <div class="order-item">
							<label>Warranty Status</label>
							<div class="rigth-align-text order-item-value">
								<div class="checkbox-wrapper">
									<input
										class="custom-checkbox"
										type="checkbox"
										v-model="returnUpdateForm.is_send_update_to_customer"
									/>
									<b>Send update to customer</b>
								</div>
							</div>
						</div> -->

						<div class="order-item">
							<label>Warrenty Status</label>
							<select
								name="orderStatus"
								v-model="returnUpdateForm.warranty_present_status"
							>
								<option
									class="order-item-value"
									:value="status_key"
									v-for="(status_value, status_key) in returnStatusChoiceList"
									:key="'status_' + status_key"
								>
									{{ status_value }}
								</option>
							</select>
							<div></div>
							<div class="rigth-align-text">
								<sui-button
									@click.prevent="changeWarrantyStatus()"
									class="custom-button"
								>
									Change Status
								</sui-button>
							</div>
						</div>

						<div class="order-item">
							<label>Shipping Details</label>
						</div>

						<div class="order-item">
							<label>Order By</label>
							<div class="order-item-value">
								<div v-if="returnDetail.order_detail">
									<span
										v-if="returnDetail.order_detail.shipping_address_detail"
									>
										{{
											returnDetail.order_detail.shipping_address_detail
												.full_name
										}}
									</span>
									<span v-if="returnDetail.order_detail.user_detail">
										<a
											v-if="
												returnDetail.order_detail.user_detail.plus_membership
											"
											href=""
										>
											Elite member
										</a>
									</span>
								</div>
							</div>
						</div>

						<div class="order-item">
							<label>Shipping Address</label>
							<div class="order-item-value">
								<div v-if="returnDetail.order_detail">
									<b>
										{{ returnDetail.order_detail.shipping_address_display }}
									</b>
								</div>
							</div>
						</div>

						<div class="order-item">
							<label>Contact Number</label>
							<div class="order-item-value" v-if="returnDetail.order_detail">
								<b>
									{{
										returnDetail.order_detail.shipping_address_detail.phone
									}}</b
								>
							</div>
						</div>

						<div class="order-item">
							<label>Email</label>
							<div class="order-item-value" v-if="returnDetail.order_detail">
								<b>{{ returnDetail.order_detail.user_detail.email }}</b>
							</div>
						</div>

						<!-- <div class="order-item">
							<label>Select Receiver</label>
							<div class="rigth-align-text order-item-value">
								<div class="checkbox-wrapper">
									<input
										class="custom-checkbox"
										type="checkbox"
										id="vehicle2"
										name="vehicle2"
										value="Generate Referance ID"
										v-model="returnUpdateForm.is_generate_ref_id"
									/>
									<b>Generate Reference ID</b>
								</div>
							</div>
						</div> -->

						<div class="order-item">
							<label>Forworder Name</label>
							<select
								name="orderStatus"
								v-model="returnUpdateForm.shipping_provider"
							>
								<option
									class="order-item-value"
									:value="provider.id"
									v-for="(provider, index) in orderProviderChoiceList"
									:key="index"
								>
									{{ provider.provider }}
								</option>
							</select>
						</div>

						<div class="order-item">
							<label>Reference ID</label>
							<sui-form-field>
								<input
									v-model="returnUpdateForm.refernce_id"
									placeholder="Referance ID"
								/>
							</sui-form-field>
						</div>

						<!-- <div class="order-item">
							<label>Shipping Cost</label>
							<div
								class="order-item-value"
								v-if="returnDetail.shipping_price_detail"
							>
								<b>
									ট
									{{
										returnDetail.shipping_price_detail.total_shipping_price
									}}
								</b>
							</div>
						</div> -->

						<div class="order-item">
							<label>Order Information</label>
							<div class="order-item-value" v-if="returnDetail.order_detail">
								{{ returnDetail.order_detail.status_display }}
							</div>
						</div>

						<!-- <div class="order-item">
							<label>Prodiver</label>
							<div class="order-item-value" v-if="returnDetail.order_detail">
								{{ returnDetail.order_detail.payment_detail.provider }}
							</div>
						</div> -->
						<div class="order-item">
							<label>Total Paid</label>
							<div class="order-item-value" v-if="returnDetail.order_detail">
								{{ returnDetail.order_detail.payment_detail.amount }}
							</div>
						</div>
						<!-- <div class="order-item">
							<label>Card</label>
							<div class="order-item-value">N/A</div>
						</div>
						<div class="order-item">
							<label>Card No</label>
							<div class="order-item-value">N/A</div>
						</div> -->
						<div class="order-item">
							<label>Payment Date</label>
							<div class="order-item-value" v-if="returnDetail.order_detail">
								{{
									this.$dayjs(
										returnDetail.order_detail.payment_detail.date
									).format("YYYY-MM-DD")
								}}
							</div>
						</div>

						<div class="button-group">
							<button
								@click.prevent="handleSubmit"
								class="button-item round-green-btn"
							>
								Update
							</button>
							<!-- <button
								@click.prevent="handleDiscard"
								class="button-item round-outline-orange-btn"
							>
								Save draft
							</button> -->
							<button
								@click.prevent="handleDiscard"
								class="button-item round-outline-orange-btn"
							>
								Cancel
							</button>
							<!-- <button
								@click.prevent="handleDeleteOrder"
								class="button-item round-red-btn"
							>
								Deny Request
							</button> -->
						</div>
					</div>
					<div class="right">
						<div v-if="returnDetail">
							<div class="wrapper-note">
								<div class="admin-note-header">
									<h2>Order Note</h2>
									<Icon class="minus-icon" icon="akar-icons:minus" />
								</div>
								<div class="admin-note-detail">
									<sui-form class="custom-form">
										<sui-form-field>
											<label class="note-form-level">Admin note</label>
											<textarea v-model="returnUpdateForm.admin_order_note">
											</textarea>
										</sui-form-field>
									</sui-form>
								</div>
							</div>
						</div>
					</div>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../../store/utils";
import { API_HOST } from "../../global";
import { NS_USER, NS_WARRANTY_RETURN } from "../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../store/mutation.names";
import {
	FETCH_WARRANTY_OR_RETURN_DETAIL,
	FETCH_WARRANTY_OR_RETURN_OPTIONS_DATA,
	UPDATE_ORDER,
	DELETE_ORDER,
	UPDATE_WARRENTY_RETURN,
} from "../../store/action.names";
import { Icon } from "@iconify/vue2";
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import CardProductReturn from "./CardProductReturn.vue";
@Component({
	name: "pageReturnDetail",
	components: {
		CardProductReturn,
		ValidationProvider,
		ValidationObserver,
		Icon,
	},
})
export default class pageReturnDetail extends Vue {
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;
	@Action(namespaced(NS_WARRANTY_RETURN, FETCH_WARRANTY_OR_RETURN_DETAIL))
	fetchWarrantyOrReturnDetail;
	@Action(namespaced(NS_WARRANTY_RETURN, FETCH_WARRANTY_OR_RETURN_OPTIONS_DATA))
	fetchWarrantyOrReturnOptionsData;
	@Action(namespaced(NS_WARRANTY_RETURN, UPDATE_WARRENTY_RETURN))
	updateWarrentyReturn;
	@Action(namespaced(NS_WARRANTY_RETURN, DELETE_ORDER)) deleteOrder;

	HOST = API_HOST;
	data = null;
	returnId = this.$route.params.id;
	returnDetail = {};
	returnStatusChoiceList = [];
	orderProviderChoiceList = [];

	returnUpdateForm = {
		return_present_status: null,
		refernce_id: "",
		admin_order_note: "",
		message_to_customer: "",
		shipping_provider: "",
	};

	changeWarrantyStatus() {
		let updatedOrderData = {
			id: this.returnDetail.id,
			data: {
				status: this.returnUpdateForm.warranty_present_status,
			},
		};
		this.updateWarrentyReturn(updatedOrderData).then((data) => {
			this.$toast.success("Return status updated successfully!", {});
			// this.setData(data);
		});
	}

	handleSubmit() {
		let updatedOrderData = {
			id: this.returnDetail.id,
			data: {
				shipping_info_input: {
					provider: this.returnUpdateForm.shipping_provider,
					refernce_id: this.returnUpdateForm.refernce_id,
				},
				admin_order_note: this.returnUpdateForm.admin_order_note,
			},
		};
		this.updateWarrentyReturn(updatedOrderData).then((data) => {
			this.$toast.success("Warrenty updated successfully!", {});
			// this.setData(data);
		});
	}

	handleDiscard() {
		this.setData(this.returnDetail);
	}

	handleDeleteOrder() {
		this.$confirm("Are you sure to delete this order?").then(() => {
			this.deleteOrder({
				id: this.returnDetail.id,
			}).then((data) => {
				Vue.$toast.error("Order deleted successfully!", {});
				setTimeout(() => {
					console.log("Redirecting .......");
					this.$router.push({ name: "orders" });
				}, 2000);
			});
		});
	}

	fetchData() {
		this.fetchWarrantyOrReturnDetail({ id: this.returnId }).then((data) => {
			this.setData(data);
		});
		this.fetchWarrantyOrReturnOptionsData().then((data) => {
			this.returnStatusChoiceList = data.status_choices_list;
			this.orderProviderChoiceList = data.providers_list;
		});
	}

	downloadInvoice() {
		console.log("downloading invoice...");
		console.log(window.location);
		let download_url = `${window.location.hostname}:8000/invoice/pdf/${this.returnDetail.order_id}`;
		console.log(download_url);
		window.location = download_url;
	}

	setData(data) {
		this.returnDetail = data;
		this.returnUpdateForm.warranty_present_status = this.returnDetail.status;
		this.returnUpdateForm.admin_order_note =
			this.returnDetail.order_detail.admin_order_note;
		if (this.returnDetail.shipping_info) {
			this.returnUpdateForm.shipping_provider =
				this.returnDetail.shipping_info.shipping_provider;
			this.returnUpdateForm.refernce_id =
				this.returnDetail.shipping_info.ref_id;
		}
	}

	mounted() {
		this.setActiveSidebar("warranty-claim");
		this.setActiveSubmenu("");
		this.fetchData();
	}
}
</script>

<style scoped lang="scss">
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.item-value {
		font-size: 18px;
	}
	.text-blue {
		color: #5b83f3;
	}
	.text-white {
		color: white;
	}
	.left {
		font-weight: 700;
		font-size: 20px;
	}

	.order-status {
		background: #edf1fe;
		padding: 10px 15px;
		border-radius: 10px;
	}
	.right {
		display: flex;
		gap: 40px;
	}
	.ticket {
		color: #f0af45;
		font-size: 18px;
		font-weight: 600;
	}
}
.content {
	margin-bottom: 20px;
	span {
		form {
			margin-top: 30px;
			display: grid;
			grid-template-columns: 8.5fr 3.5fr;
			gap: 20px;
			.left {
				// border: 1px solid red;
				min-height: 300px;
				display: flex;
				flex-direction: column;
				gap: 20px;

				.total_price_section {
					display: grid;
					grid-template-columns: 1fr 5fr 2fr;
					gap: 10px;
					text-decoration: none;
					.price_section {
						display: grid;
						gap: 10px;
						grid-template-columns: 5fr 5fr;
						div {
							display: flex;
						}
						.right_text {
							margin-right: 10px;
							justify-content: right;
						}
					}
				}
				.order-item {
					display: grid;
					grid-template-columns: 1fr 6fr;
					gap: 15px;
					align-items: center;
					span {
						display: flex;
						flex-direction: column;
					}
					label {
						width: 300px;
						font-weight: 600;
						font-size: 18px;
					}
					input {
						height: 40px;
						padding-left: 15px;
						width: 90%;
					}
					select {
						width: 90%;
						height: 40px;
					}
					.gray-background {
						background: #e8e8e8 !important;
						border-radius: 8px;
						padding: 10px 20px;
						width: 90%;
					}
					.order-item-value {
						font-size: 18px;
						.message-to-cutomer {
							width: 90%;
						}
					}
					.cash-on-delivery {
						display: flex;
						color: #65b781;
						justify-content: right;
						b {
							margin-left: 5px;
						}
					}
					.payment-pending-text {
						display: flex;
						justify-content: right;
						div {
							b {
								margin-left: 5px;
								color: #e98679;
							}
							display: flex;
							justify-content: right;
							display: flex;
							border-style: solid;
							border-width: 3px;
							border-image: linear-gradient(#d23b11, #d23b11, #d23b11, #d23b11)
									60,
								50;
							padding: 10px 35px;
							border-radius: 10px;
						}
					}
					.items {
						display: grid;
						grid-template-columns: 1fr 1fr;
						gap: 20px;
						width: 95%;
						label {
							font-size: 16px;
							font-weight: 400;
						}
					}
					.items3 {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;
						gap: 20px;
						width: 95%;
						margin-bottom: 50px;
						label {
							font-size: 16px;
							font-weight: 400;
						}
					}
					.image-items {
						display: grid;
						grid-template-columns: 1fr;
						gap: 20px;
						margin-top: 20px;
						.item {
							.image-btn {
								display: none;
							}
						}
					}
				}
				.richtext {
					align-items: flex-start;
				}
			}
			.right {
				// border: 1px solid blue;
				min-height: 300px;
			}
		}
	}
}

.btn {
	margin-bottom: 50px;
	button {
		cursor: pointer;
	}
}

.dropdown {
	position: relative;
	width: 100%;
	margin: 0 auto;
}
.dropdown-input,
.dropdown-selected {
	width: 100%;
	padding: 10px 16px;
	border: 1px solid transparent;
	background: #edf2f7;
	line-height: 1.5em;
	outline: none;
	border-radius: 8px;
}
.dropdown-input:focus,
.dropdown-selected:hover {
	background: #fff;
	border-color: #e2e8f0;
}
.dropdown-input::placeholder {
	opacity: 0.7;
}
.dropdown-selected {
	font-weight: bold;
	cursor: pointer;
}
.dropdown-list {
	position: absolute;
	width: 100%;
	max-height: 500px;
	margin-top: 4px;
	overflow-y: auto;
	background: #ffffff;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	z-index: 1000;
}
.dropdown-item {
	display: flex;
	width: 100%;
	padding: 11px 16px;
	cursor: pointer;
}
.dropdown-item:hover {
	background: #edf2f7;
}
.dropdown-item-flag {
	max-width: 24px;
	max-height: 18px;
	margin: auto 12px auto 0px;
}
.rigth-align-text {
	text-align: right;
	width: 90%;
}
.reword-customer {
	text-align: right;
	width: 94%;
}
.custom-button {
	background: #65b781 !important;
	color: white !important;
	border-radius: 10px !important;
}
.custom-primary-checkbox {
	background: #65b781 !important;
	color: white !important;
}

.button-item {
	margin: 0px 10px;
}

.checkbox-wrapper {
	display: flex;
	align-items: center;
	justify-content: right;
	.custom-checkbox {
		margin-right: 10px;
		height: 25px !important;
		width: 25px !important;
		background: #65b781 !important;
	}
}

.wrapper-note {
	background: #f2f6ff;
	.admin-note-header {
		background: #12233a;
		color: white;
		padding: 15px;
		border-radius: 20px 20px 0px 0px;
		display: flex;
		h2 {
			margin-bottom: 0px;
		}
		.minus-icon {
			margin-left: auto;
			display: flex;
			justify-content: center;
			text-align: center;
			font-size: 30px;
		}
	}
	.admin-note-detail {
		.custom-form {
			margin-top: 10px;
			display: grid;
			grid-template-columns: 12fr;
		}
		padding: 20px;
		.note-form-level {
			font-size: 18px;
			color: #86878a;
		}
	}
}
</style>
