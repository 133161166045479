<template>
	<div class="user-wrapper">
		<div class="top">
			<div class="left">
				<span v-if="formCategory.name">
					{{ formCategory.name }}
				</span>
				<span v-else> Details Category </span>
			</div>
			<div class="right"></div>
		</div>
		<div class="content">
			<ValidationObserver v-slot="{ invalid }">
				<sui-form method="post" @submit.prevent="handleSubmit">
					<div class="left">
						<label>Name</label>
						<sui-form-field>
							<input
								required
								v-model="formCategory.name"
								placeholder="Combo offer"
							/>
						</sui-form-field>

						<label>Is Active</label>
						<div>
							<sui-checkbox label="" toggle v-model="formCategory.is_active" />
						</div>

						<modal-choose-image
							:add-button-name="icon_add_button"
							@passSelectedImage="getSelectedIconImage"
						>
						</modal-choose-image>
						<sui-image :src="selected_icon.file" size="tiny" />

						<modal-choose-image
							:add-button-name="cover_image_add_button"
							@passSelectedImage="getSelectedCOverImage"
						>
						</modal-choose-image>
						<sui-image :src="selected_cover_image.file" size="tiny" />
					</div>

					<div class="right">
						<!-- meta info -->
						<label>Meta title: </label>
						<sui-form-field>
							<input
								required
								v-model="formCategory.meta_title"
								placeholder="Combo offer"
							/>
						</sui-form-field>

						<label>Meta Description: </label>
						<sui-form-field>
							<input
								required
								v-model="formCategory.meta_desc"
								placeholder="Combo offer"
							/>
						</sui-form-field>

						<label>Meta Keywords: </label>
						<sui-form-field>
							<input
								required
								v-model="formCategory.meta_keywords"
								placeholder="Combo offer"
							/>
						</sui-form-field>
					</div>

					<div class="input-item btn" style="margin-top: 40px">
						<button
							style="margin-right: 10px"
							class="round-green-btn"
							:disabled="invalid"
						>
							Publish
						</button>
						<a class="discard-btn" href=""> Discard </a>
					</div>
				</sui-form>
			</ValidationObserver>
		</div>
		<div>
			<!-- child category table -->

			<div class="top">
				<div class="left">
					<h3 is="sui-header" dividing>
						Sub Categories ({{ childCategoryList.length }})
					</h3>
				</div>
				<div class="right">
					<div class="add-new-user-btn">
						<Icon
							class="add-user-icon"
							icon="material-symbols:add-box-outline"
						/>

						<modal-add-category
							:parent-category="parentCategoryToPass"
							@createdCategory="handleCreatedCategory"
						>
						</modal-add-category>
					</div>
				</div>
			</div>
			<TableCategory
				v-if="childCategoryList"
				:results="childCategoryList"
				@categoryDelete="handleCategoryDelete"
			/>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../../../store/utils";
import { API_HOST } from "../../../global";
import { NS_USER, NS_PRODUCTS } from "../../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../../store/mutation.names";
import {
	FETCH_CATEGORY_DETAIL,
	FETCH_CATEGORY,
	UPDATE_CATEGORY,
} from "../../../store/action.names";
import { GET_TOKEN } from "../../../store/getter.names";
import { Icon } from "@iconify/vue2";
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import ModalChooseImage from "../../../components/common/ModalChooseImage.vue";
import TableCategory from "../../../components/tables/category/TableCategory.vue";
import ModalAddCategory from "./ModalAddCategory.vue";

@Component({
	name: "PageAddCampaign",
	components: {
		Icon,
		ValidationProvider,
		ValidationObserver,
		ModalChooseImage,
		TableCategory,
		ModalAddCategory,
	},
})
export default class PageAddCampaign extends Vue {
	@Getter(namespaced(NS_USER, GET_TOKEN)) getToken;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;
	@Action(namespaced(NS_PRODUCTS, FETCH_CATEGORY)) fetchCategory;
	@Action(namespaced(NS_PRODUCTS, UPDATE_CATEGORY)) updateCategory;
	@Action(namespaced(NS_PRODUCTS, FETCH_CATEGORY_DETAIL))
	fetchCategoryDetail;

	HOST = API_HOST;
	categoryId = this.$route.params.id;
	icon_add_button = "Add Icon";
	cover_image_add_button = "Add Cover Image";
	parentCategoryToPass = null;
	childCategoryList = [];
	formCategory = {
		name: "",
		parent: "",
		is_active: false,
		icon: null,
		cover_img: null,
		meta_title: "",
		meta_desc: "",
		meta_keywords: "",
	};

	selected_icon = {
		file: "",
		id: "",
	};

	selected_cover_image = {
		file: "",
		id: "",
	};

	@Watch("$route")
	handleChangeRoute(val, oldVal) {
		if (val != oldVal) {
			this.categoryId = this.$route.params.id;
			this.fetchCategoryDetailData();
		}
	}

	handleCategoryDelete() {
		this.fetchChildCategoryList();
	}

	getSelectedIconImage(value) {
		this.selected_icon = value;
	}

	getSelectedCOverImage(value) {
		this.selected_cover_image = value;
	}

	handleSubmit() {
		if (this.formCategory.name == "") {
			Vue.$toast.error("You need to enter the name!", {});
			return false;
		}

		if (this.selected_icon.id != "") {
			this.formCategory.icon = this.selected_icon.id;
		}
		if (this.selected_cover_image.id != "") {
			this.formCategory.cover_img = this.selected_cover_image.id;
		}

		this.updateCategory({
			formData: this.formCategory,
			id: this.categoryId,
		}).then((data) => {});
	}

	handleCreatedCategory(value) {
		if (value) {
			this.childCategoryList.unshift(value);
		}
	}

	fetchCategoryDetailData() {
		this.fetchCategoryDetail({
			id: this.categoryId,
		}).then((data) => {
			this.setDetailData(data);
			this.fetchChildCategoryList();
		});
	}

	fetchChildCategoryList() {
		this.fetchCategory({
			parent: this.categoryId,
		}).then((data) => {
			this.childCategoryList = data;
		});
	}

	resetIconAndImage() {
		this.selected_icon = {
			file: "",
			id: "",
		};

		this.selected_cover_image = {
			file: "",
			id: "",
		};
	}

	setDetailData(data) {
		this.formCategory.name = data.name;
		this.formCategory.parent = data.parent;
		this.formCategory.is_active = data.is_active;
		this.formCategory.meta_title = data.meta_title;
		this.formCategory.meta_desc = data.meta_desc;
		this.formCategory.meta_keywords = data.meta_keywords;
		this.formCategory.icon = data.icon;
		this.formCategory.cover_img = data.cover_img;

		this.parentCategoryToPass = {
			id: data.id,
			name: data.name,
		};

		this.resetIconAndImage();

		if (data.icon_detail) {
			this.selected_icon = {
				file: `${this.HOST}${data.icon_detail.original.src}`,
				id: data.icon.id,
			};
		}

		if (data.cover_image_detail) {
			this.selected_cover_image = {
				file: `${this.HOST}${data.cover_image_detail.file}`,
				id: data.icon.id,
			};
		}
	}

	mounted() {
		this.setActiveSidebar("products");
		this.setActiveSubmenu("category");
		this.fetchCategoryDetailData();
	}
}
</script>

<style scoped lang="scss">
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.left {
		font-weight: 700;
		font-size: 20px;
	}
	.right {
		display: flex;
		gap: 40px;
		.add-new-user-btn {
			height: 36px;
			width: 150px;
			background-color: #edf1fe;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-left: 10px;
			padding-right: 10px;
			color: #5c83f3;
			cursor: pointer;
			font-weight: 500;
			.add-user-icon {
				font-size: 20px;
				font-weight: 500;
				margin-right: 5px;
			}
		}
	}
}
.content {
	span {
		form {
			// padding: 0px 20%;
			margin-top: 30px;
			display: grid;
			grid-template-columns: 8fr 8fr;
			gap: 2px 40px;
			.left {
				// border: 1px solid red;
				min-height: 300px;
				display: grid;
				grid-template-columns: 4fr 8fr;
				gap: 5px 10px;
				.input-item {
					display: grid;
					grid-template-columns: 1fr 6fr;
					gap: 15px;
					align-items: center;
					span {
						display: flex;
						flex-direction: column;
					}
					label {
						width: 300px;
						font-weight: 600;
						font-size: 18px;
					}
					input {
						height: 40px;
						padding-left: 15px;
						width: 90%;
					}
					select {
						width: 90%;
						height: 40px;
					}
					.items {
						display: grid;
						grid-template-columns: 1fr 1fr;
						gap: 20px;
						width: 95%;
						label {
							font-size: 16px;
							font-weight: 400;
						}
					}
					.items3 {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;
						gap: 20px;
						width: 95%;
						margin-bottom: 50px;
						label {
							font-size: 16px;
							font-weight: 400;
						}
					}
					.image-items {
						display: grid;
						grid-template-columns: 1fr;
						gap: 20px;
						margin-top: 20px;
						.item {
							.image-btn {
								display: none;
							}
						}
					}
				}
				.richtext {
					align-items: flex-start;
				}
			}
			.right {
				// border: 1px solid blue;
				// min-height: 300px;
				display: grid;
				grid-template-columns: 4fr 8fr;
			}
		}
	}
}
.product-input-filed {
	margin: 3px 0px !important;
}

.image-container {
	position: relative;
	width: 100%;
	margin-bottom: 20px !important;
}

.image-container .btn-btn {
	position: absolute;
	top: 0%;
	left: 80%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	color: black;
	font-size: 16px;
	padding: 12px 24px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	text-align: center;
}
.img-btn {
	font-size: 14px !important;
}
.btn {
	margin-bottom: 50px;
	button {
		cursor: pointer;
	}
}
.discard-btn {
	background-color: red;
	border-radius: 14px;
	padding: 10px;
	padding-left: 25px;
	padding-right: 25px;
	color: white;
	font-size: 16px;
	font-weight: 700;
	text-decoration: none;
	border: none;
	width: 120px;
	text-align: center;
}
.variant-container {
	width: 90%;
	margin: 0 auto;
	background-color: #f2f6ff;
	padding: 15px;
}
.dropdown {
	position: relative;
	width: 100%;
	margin: 0 auto;
}
.dropdown-input,
.dropdown-selected {
	width: 100%;
	padding: 10px 16px;
	border: 1px solid transparent;
	background: #edf2f7;
	line-height: 1.5em;
	outline: none;
	border-radius: 8px;
}
.dropdown-input:focus,
.dropdown-selected:hover {
	background: #fff;
	border-color: #e2e8f0;
}
.dropdown-input::placeholder {
	opacity: 0.7;
}
.dropdown-selected {
	font-weight: bold;
	cursor: pointer;
}
.dropdown-list {
	position: absolute;
	width: 100%;
	max-height: 500px;
	margin-top: 4px;
	overflow-y: auto;
	background: #ffffff;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	z-index: 1000;
}
.dropdown-item {
	display: flex;
	width: 100%;
	padding: 11px 16px;
	cursor: pointer;
}
.dropdown-item:hover {
	background: #edf2f7;
}
.dropdown-item-flag {
	max-width: 24px;
	max-height: 18px;
	margin: auto 12px auto 0px;
}
.product-add-button {
	margin-top: auto;
}
</style>
