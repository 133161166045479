<template>
	<div class="single-table-row">
		<div class="image-name">
			<div class="image">
				<img v-if="res.image" :src="HOST + res.image.meta.src" />
			</div>
		</div>
		<div class="name">
			<div class="name">
				{{ res.name }}
			</div>
		</div>

		<div class="detail-link">
			<Icon
				class="icon-font expand-btn"
				icon="entypo:dots-three-vertical"
				@click.native="toggleShortMenu"
			/>
			<div class="short-menu" v-if="showShortMenu">
				<a @click.prevent="handleEditBrand"> Edit </a>
				<a> Clone </a>
				<a @click.prevent="handleDelete"> Delete </a>
			</div>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { Icon } from "@iconify/vue2";
import { API_HOST } from "../../../global";
import { namespaced } from "../../../store/utils";
import { NS_PRODUCTS } from "../../../store/namespace.names";
import { DELETE_BRAND } from "../../../store/action.names";

@Component({
	name: "ProductTableSingleRow",
	components: {
		Icon,
	},
})
export default class ProductTableSingleRow extends Vue {
	@Prop() res;

	@Action(namespaced(NS_PRODUCTS, DELETE_BRAND)) deleteBrand;

	HOST = API_HOST;
	id = null;
	showShortMenu = false;

	toggleShortMenu() {
		this.showShortMenu = !this.showShortMenu;
	}

	mounted() {
		this.id = this.res.id;
	}

	handleEditBrand() {
		this.$router.push({ name: "brand-update", params: { id: this.id } });
	}

	handleDelete() {
		this.$confirm("Are you sure to delete this brand?").then(() => {
			this.showShortMenu = false;
			this.deleteBrand({
				id: this.id,
			}).then((data) => {
				this.$emit("brandDelete");
			});
		});
	}
}
</script>

<style scoped lang="scss">
.single-table-row {
	display: grid;
	grid-template-columns: 2fr 2fr 2fr;
	gap: 10px;
	min-height: 40px;
	border-bottom: 1px solid grey;
	text-decoration: none;
	// &:hover{
	//   background-color: #DEE9FE;
	//   cursor: pointer;
	// }
	div {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 16px;
		color: #12233a;
	}
	.image-name {
		display: flex;
		justify-content: flex-start;
		gap: 10px;
		padding: 10px;
		.image {
			width: 60px;
			height: 60px;
			img {
				height: 100%;
				width: 100%;
			}
		}
	}
	.action-column {
		position: relative;
		&:hover {
			.action-dropdown {
				display: block;
			}
		}
		.action-dropdown {
			width: 136px;
			z-index: 999;
			position: absolute;
			display: none;
			top: 30px;
			background: #f7f7f7;
			border: 1px solid #a4a4a4;
			box-sizing: border-box;
			box-shadow: 0px 4px 10px rgba(162, 162, 162, 0.25);
			border-radius: 6px;
			.dropdown-item {
				font-size: 14px;
				font-weight: 600;
				color: #62b37c;
				text-align: left;
				display: flex;
				justify-content: flex-start;
				padding: 10px;
				padding-top: 15px;
				padding-bottom: 15px;
				padding-left: 15px;
				cursor: pointer;
			}
		}
	}
}
.detail-link {
	position: relative;
	.expand-btn {
		cursor: pointer;
	}
	.short-menu {
		position: absolute;
		height: 50px;
		width: 230px;
		right: 80px;
		background: #f2f6ff;
		box-shadow: 0px 4px 16px rgba(64, 64, 64, 0.21);
		border-radius: 8px;
		display: flex;
		justify-content: space-around;
		a {
			font-size: 16px;
			font-weight: 600;
			height: 50px;
			width: 100%;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			&:hover {
				background-color: #5c83f3;
				color: white;
			}
			&:last-child {
				&:hover {
					background-color: red;
					color: white;
				}
			}
		}
	}
}
</style>
