import {
	GET_PROFILE,
	GET_TOKEN,
	GET_AUTH_HEADER,
	GET_ACTIVE_SIDEBAR,
	GET_ACTIVE_SUBMENU,
	GET_SEARCH_TEXT,
} from "../getter.names";
import {
	LOGIN,
	REGISTRATION,
	PROFILE,
	GET_TOKEN_FROM_LOCAL_STORE,
	LOGOUT,
	FETCH_USERS,
	FETCH_USER_DETAIL,
	UPDATE_PROFILE,
	DELETE_USER,
	UPDATE_SUPERCOIN,
	STATS_FOR_USER_DETAIL,
} from "../action.names";
import {
	LOGIN_ENDPOINT,
	PROFILE_ENDPOINT,
	ADMIN_USER_ENDPOINT,
} from "../endpoints";
import {
	SET_TOKEN,
	SET_PROFILE,
	SET_TOKEN_ERROR,
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
	SET_SEARCH_TEXT,
} from "../mutation.names";
import { namespaced, buildParams } from "../utils";
import axios from "axios";

export default {
	namespaced: true,
	state: {
		user: {
			profile: null,
			token: null,
			uuid: null,
		},
		activeSidebar: "",
		activeSubmenu: "",
		searchText: "",
		error: false,
	},
	getters: {
		[GET_PROFILE](state) {
			return state.user.profile;
		},
		[GET_TOKEN](state) {
			return state.user.token;
		},
		[GET_AUTH_HEADER](state) {
			if (state.user.token == null) return null;
			return { Authorization: `Token ${state.user.token}` };
		},
		[GET_ACTIVE_SIDEBAR](state) {
			return state.activeSidebar;
		},
		[GET_ACTIVE_SUBMENU](state) {
			return state.activeSubmenu;
		},
		[GET_SEARCH_TEXT](state) {
			return state.searchText;
		},
	},
	actions: {
		async [LOGIN]({ commit, dispatch }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(LOGIN_ENDPOINT, payload)
					.then(({ data }) => {
						commit(SET_TOKEN, data.token);
						commit(SET_PROFILE, data.user);
						resolve(data);
					})
					.catch((e) => {
						commit(SET_TOKEN_ERROR);
						console.log(e);
						reject(e);
					});
			});
		},
		async [PROFILE]({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.get(PROFILE_ENDPOINT, {
						headers: {
							...getters[GET_AUTH_HEADER],
						},
					})
					.then(({ data }) => {
						commit(SET_PROFILE, data);
						resolve(data);
					})
					.catch((e) => {
						commit(SET_TOKEN_ERROR);
						console.log(e);
						reject(e);
					});
			});
		},
		async [GET_TOKEN_FROM_LOCAL_STORE]({ commit, dispatch }) {
			return new Promise((resolve, reject) => {
				const localToken = localStorage.getItem("HEALTHWAY_ADMIN_TOKEN");
				if (localToken != null) {
					commit(SET_TOKEN, localToken);
					dispatch(PROFILE);
					resolve();
				} else {
					reject();
				}
			});
		},
		async [LOGOUT]({ commit }) {
			return new Promise((resolve, reject) => {
				let token = localStorage.getItem("HEALTHWAY_ADMIN_TOKEN");
				if (token != null) {
					commit(SET_TOKEN_ERROR);
					resolve();
				}
			});
		},
		async [FETCH_USERS]({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.get(`${ADMIN_USER_ENDPOINT}${buildParams(payload)}`, {
						headers: {
							...getters[GET_AUTH_HEADER],
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [FETCH_USER_DETAIL]({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.get(`${ADMIN_USER_ENDPOINT}${payload.id}/`, {
						headers: {
							...getters[GET_AUTH_HEADER],
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [UPDATE_PROFILE]({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.patch(`${ADMIN_USER_ENDPOINT}${payload.id}/`, payload, {
						headers: {
							...getters[GET_AUTH_HEADER],
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [DELETE_USER]({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`${ADMIN_USER_ENDPOINT}${payload.id}/`, {
						headers: {
							...getters[GET_AUTH_HEADER],
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [STATS_FOR_USER_DETAIL]({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.get(`${ADMIN_USER_ENDPOINT}${payload.id}/user_stats/`, {
						headers: {
							...getters[GET_AUTH_HEADER],
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [UPDATE_SUPERCOIN]({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(
						`${ADMIN_USER_ENDPOINT}${payload.id}/add_remove_supercoin/`,
						payload,
						{
							headers: {
								...getters[GET_AUTH_HEADER],
							},
						}
					)
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
	},
	mutations: {
		[SET_TOKEN](state, token) {
			state.user.token = token;
			state.error = false;
			localStorage.setItem("HEALTHWAY_ADMIN_TOKEN", token);
		},
		[SET_TOKEN_ERROR](state) {
			state.user.token = null;
			state.user.profile = null;
			state.error = true;
			localStorage.removeItem("HEALTHWAY_ADMIN_TOKEN");
		},
		[SET_PROFILE](state, data) {
			state.user.profile = data;
		},
		[SET_ACTIVE_SIDEBAR](state, data) {
			state.activeSidebar = data;
		},
		[SET_ACTIVE_SUBMENU](state, data) {
			state.activeSubmenu = data;
		},
		[SET_SEARCH_TEXT](state, data) {
			state.searchText = data;
		},
	},
};
