<template>
	<div class="single-table-row">
		<div>
			<span class="cursor-view" @click.prevent="sendToDetailPage">
				{{ res.name }}
			</span>
		</div>
		<div>
			<span
				class="cursor-view"
				v-if="res.slug"
				@click.prevent="sendToDetailPage"
			>
				{{ res.slug }}
			</span>
			<span v-else> Not yet set </span>
		</div>
		<div class="detail-link">
			<Icon
				class="icon-font expand-btn"
				icon="entypo:dots-three-vertical"
				@click.native="toggleShortMenu"
			/>
			<div class="short-menu" v-if="showShortMenu">
				<a @click.prevent="handleDelete"> Delete </a>
			</div>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { Icon } from "@iconify/vue2";
import { API_HOST } from "../../../global";
import { namespaced } from "../../../store/utils";
import { NS_PRODUCTS } from "../../../store/namespace.names";
import { DELETE_CATEGORY } from "../../../store/action.names";

@Component({
	name: "CategoryTableSingleRow",
	components: {
		Icon,
	},
})
export default class CategoryTableSingleRow extends Vue {
	@Prop() res;

	@Action(namespaced(NS_PRODUCTS, DELETE_CATEGORY)) deleteCategory;

	HOST = API_HOST;
	id = null;
	showShortMenu = false;

	toggleShortMenu() {
		this.showShortMenu = !this.showShortMenu;
	}

	handleDelete() {
		console.log("Deleting category......");
	}

	handleDelete() {
		this.$confirm("Are you sure to delete this category?").then(() => {
			this.showShortMenu = false;
			this.deleteCategory({
				id: this.id,
			}).then((data) => {
				this.$emit("categoryDelete");
			});
		});
	}

	mounted() {
		this.id = this.res.id;
	}

	sendToDetailPage() {
		this.$router.push({
			name: "category-detail",
			params: { id: this.res.id },
		});
	}
}
</script>

<style scoped lang="scss">
.single-table-row {
	display: grid;
	grid-template-columns: 2fr 2fr 1fr;
	gap: 10px;
	min-height: 40px;
	border-bottom: 1px solid grey;
	text-decoration: none;
	// &:hover{
	//   background-color: #DEE9FE;
	//   cursor: pointer;
	// }
	div {
		// cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 16px;
		color: #12233a;
	}
	.cursor-view {
		cursor: pointer;
	}
	.cursor-view:hover {
		color: rgb(0, 200, 255);
		text-decoration: underline;
	}
}
.detail-link {
	position: relative;
	.expand-btn {
		cursor: pointer;
	}
	.short-menu {
		position: absolute;
		height: 50px;
		width: 100px;
		right: 170px;
		background: #f2f6ff;
		box-shadow: 0px 4px 16px rgba(64, 64, 64, 0.21);
		border-radius: 8px;
		display: flex;
		justify-content: space-around;
		a {
			font-size: 16px;
			font-weight: 600;
			height: 50px;
			width: 100%;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			&:hover {
				background-color: #5c83f3;
				color: white;
			}
			&:last-child {
				&:hover {
					background-color: red;
					color: white;
				}
			}
		}
	}
}
</style>
