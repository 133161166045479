<template>
	<div class="page-wrapper">
		<div class="top">
			<div class="left">Review Details</div>
			<div class="right"></div>
		</div>
		<div class="content">
			<ValidationObserver>
				<form>
					<div class="left">
						<div class="review-item">
							<label>Invoice number</label>
							<div class="invoice-heading review-item-value">
								<div>INV2022-08-00085</div>
								<div class="rigth-align-text text-blue">
									Date : August 14, 2022
								</div>
							</div>
						</div>
						<div class="review-item" style="margin: 10px 0px">
							<label>
								<!-- <div class="image" v-if="res.product_detail.default_image">
                    <img :src="HOST + res.product_detail.default_image.mobile.src" />
                </div> -->
								<div class="image">
									<img
										class="ui tiny circular image"
										src="@/assets/images/empty-user.png"
									/>
								</div>
							</label>
							<div class="review-item-value"></div>
						</div>
						<div class="review-item">
							<label>Name</label>
							<div class="review-item-value">Alex Ruben</div>
						</div>
						<div class="review-item">
							<label>Contact</label>
							<div class="review-item-value">+8801840719155</div>
						</div>

						<div class="review-item">
							<label>Review Date</label>
							<div class="review-item-value">August 23, 2022, 3:09 p.m.</div>
						</div>

						<div class="review-item">
							<label>Review</label>
							<div class="review-item-value">
								This is a very good product, I usually use this product. this is
								cost effective. You should try it.
							</div>
						</div>

						<div class="review-item">
							<label>Product</label>
							<div class="review-item-value">
								First Aid Kit with 59 Items for Home, Office, and Travel purpose
							</div>
						</div>

						<div class="review-item">
							<label>Images</label>
							<div class="review-item-value">
								<!-- there will be multiple images -->
								<div class="ui tiny images">
									<img class="ui image" src="@/assets/images/empty-user.png" />
									<img class="ui image" src="@/assets/images/empty-user.png" />
									<img class="ui image" src="@/assets/images/empty-user.png" />
									<img class="ui image" src="@/assets/images/empty-user.png" />
								</div>
							</div>
						</div>

						<div class="button-group">
							<button
								@click.prevent="handleSubmit"
								class="button-item round-green-btn"
							>
								Authorize Now
							</button>
							<button
								@click.prevent="handleDeleteReview"
								class="button-item round-red-btn"
							>
								Delete
							</button>
						</div>
					</div>
					<div class="right">
						<div v-if="reviewDetail">
							<!-- <div class="wrapper-note">
                <div class="admin-note-header">
                  <h2>Order Note</h2>
                  <Icon class="minus-icon" icon="akar-icons:minus" />
                </div>
                <div class="admin-note-detail">
                  <sui-form class="custom-form">
                    <sui-form-field>
                      <label class="note-form-level">Admin note</label>
                      <textarea v-model="reviewUpdateForm.admin_order_note">
                      </textarea>
                    </sui-form-field>
                  </sui-form>
                </div>
              </div> -->
						</div>
						<div v-if="reviewDetail">
							<message-to-customer :selected-review="reviewDetail">
							</message-to-customer>
						</div>
					</div>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../../store/utils";
import { API_HOST } from "../../global";
import { NS_USER, NS_REVIEWS } from "../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../store/mutation.names";
import {
	FETCH_REVIEW_DETAIL,
	UPDATE_REVIEW,
	DELETE_REVIEW,
} from "../../store/action.names";
import { Icon } from "@iconify/vue2";
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";

import OrderNote from "../../components/tables/order/OrderNote.vue";
import MessageToCustomer from "./MessageToCustomer.vue";
@Component({
	name: "PageReviewDetail",
	components: {
		MessageToCustomer,
		OrderNote,
		ValidationProvider,
		ValidationObserver,
		Icon,
	},
})
export default class PageReviewDetail extends Vue {
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;
	@Action(namespaced(NS_REVIEWS, FETCH_REVIEW_DETAIL)) fetchReviewDetail;
	@Action(namespaced(NS_REVIEWS, UPDATE_REVIEW)) updateReview;
	@Action(namespaced(NS_REVIEWS, DELETE_REVIEW)) deleteReview;

	HOST = API_HOST;
	data = null;
	reviewId = this.$route.params.id;
	reviewDetail = {};

	reviewUpdateForm = {
		order_present_status: null,
		is_send_update_to_customer: true,
		shipping_provide: null,
		is_generate_ref_id: true,
		ref_id: "",
		admin_order_note: "",
	};

	handleSubmit() {
		let updatedReviewData = {
			id: this.reviewDetail.id,
			formData: this.reviewUpdateForm,
		};
		this.updateReview(updatedReviewData).then((data) => {
			Vue.$toast.success("Review updated successfully!", {});
			this.setData(data);
		});
	}

	handleDeleteReview() {
		this.$confirm("Are you sure to delete this review?").then(() => {
			this.deleteReview({
				id: this.reviewDetail.id,
			}).then((data) => {
				Vue.$toast.error("Review deleted successfully!", {});
				setTimeout(() => {
					console.log("Redirecting .......");
					this.$router.push({ name: "customer-reviews" });
				}, 2000);
			});
		});
	}

	fetchData() {
		this.fetchReviewDetail({ id: this.reviewId }).then((data) => {
			this.setData(data);
		});
	}

	downloadInvoice() {}

	setData(data) {
		this.reviewDetail = data;
		// TODO: set other review form data here
	}

	mounted() {
		this.setActiveSidebar("customer-reviews");
		this.setActiveSubmenu("");
		this.fetchData();
	}
}
</script>

<style scoped lang="scss">
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.item-value {
		font-size: 18px;
	}
	.text-blue {
		color: #5b83f3;
	}
	.left {
		font-weight: 700;
		font-size: 20px;
	}
	.right {
		display: flex;
		gap: 40px;
	}
}
.content {
	span {
		form {
			margin-top: 30px;
			display: grid;
			grid-template-columns: 8fr 4fr;
			gap: 20px;
			.left {
				// border: 1px solid red;
				min-height: 300px;
				display: flex;
				flex-direction: column;
				gap: 20px;

				.review-item {
					display: grid;
					grid-template-columns: 1fr 6fr;
					gap: 15px;
					align-items: center;
					span {
						display: flex;
						flex-direction: column;
					}
					label {
						width: 300px;
						font-weight: 600;
						font-size: 18px;
					}
					.review-item-value {
						font-size: 18px;
					}
					.invoice-heading {
						display: grid;
						grid-template-columns: 6fr 6fr;
						gap: 15px;
						align-items: center;
					}

					.items {
						display: grid;
						grid-template-columns: 1fr 1fr;
						gap: 20px;
						width: 95%;
						label {
							font-size: 16px;
							font-weight: 400;
						}
					}
				}
				.richtext {
					align-items: flex-start;
				}
			}
			.right {
				// border: 1px solid blue;
				min-height: 300px;
			}
		}
	}
}

.btn {
	margin-bottom: 50px;
	button {
		cursor: pointer;
	}
}

.rigth-align-text {
	text-align: right;
	width: 90%;
}

.button-item {
	margin: 0px 10px;
}

.wrapper-note {
	background: #f2f6ff;
	.admin-note-header {
		background: #12233a;
		color: white;
		padding: 15px;
		border-radius: 20px 20px 0px 0px;
		display: flex;
		h2 {
			margin-bottom: 0px;
		}
		.minus-icon {
			margin-left: auto;
			display: flex;
			justify-content: center;
			text-align: center;
			font-size: 30px;
		}
	}
	.admin-note-detail {
		.custom-form {
			margin-top: 10px;
			display: grid;
			grid-template-columns: 12fr;
		}
		padding: 20px;
		.note-form-level {
			font-size: 18px;
			color: #86878a;
		}
	}
}
</style>
