import { GET_AUTH_HEADER } from "../getter.names";
import { Vue } from "vue-property-decorator";

import {} from "../getter.names";
import {
	FETCH_COUPONS,
	FETCH_COUPONS_DETAIL,
	UPDATE_COUPONS,
	CREATE_COUPONS,
	DELETE_COUPONS,
} from "../action.names";
import { FETCH_COUPONS_ENDPOINT } from "../endpoints";
import {} from "../mutation.names";
import { NS_USER } from "../namespace.names";
import { namespaced, buildParams } from "../utils";

export default {
	namespaced: true,
	state: {},
	getters: {},
	actions: {
		async [FETCH_COUPONS]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				let relativeUrl = `${FETCH_COUPONS_ENDPOINT}${buildParams(payload)}`;
				axios
					.get(relativeUrl, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [FETCH_COUPONS_DETAIL]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_COUPONS_ENDPOINT}${payload.id}/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [DELETE_COUPONS]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.delete(`${FETCH_COUPONS_ENDPOINT}${payload.id}/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [CREATE_COUPONS]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.post(`${FETCH_COUPONS_ENDPOINT}`, payload, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [UPDATE_COUPONS]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.patch(`${FETCH_COUPONS_ENDPOINT}${payload.id}/`, payload.formData, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
	},

	mutations: {},
};
