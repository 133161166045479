<template>
	<div>
		<div class="toggle-button-space">
			<sui-button @click.prevent="toggle" class="custom-button">
				Reword Customer
			</sui-button>
		</div>
		<sui-modal v-model="open_modal">
			<sui-modal-header class="modal-header">
				<div>Reword Customer</div>
				<div class="text-right">
					<Icon
						@click.native="toggle"
						class="close-user-icon"
						icon="charm:circle-cross"
					/>
				</div>
			</sui-modal-header>
			<sui-modal-content scrolling>
				<ValidationObserver>
					<sui-form class="custom-form">
						<div class="input-item">
							<label>Select type</label>
							<select v-model="formReword.type">
								<option value="Super Coin">Super Coin</option>
							</select>
						</div>

						<br />

						<div class="input-item">
							<label>Quantity</label>
							<sui-form-field>
								<input v-model="formReword.quantity" placeholder="100" />
							</sui-form-field>
						</div>
					</sui-form>
				</ValidationObserver>
			</sui-modal-content>
			<sui-modal-actions>
				<sui-button class="custom-button" @click.prevent="handleRewardCustomer">
					Save
				</sui-button>
			</sui-modal-actions>
		</sui-modal>
	</div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { Icon } from "@iconify/vue2";
import { API_HOST } from "../../../global";
import { namespaced } from "../../../store/utils";
import { NS_SETTINGS } from "../../../store/namespace.names";
import { REWORD_CUSTOMER } from "../../../store/action.names";
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";

@Component({
	name: "ModalRewordCustomer",
	components: {
		Icon,
		ValidationObserver,
		ValidationProvider,
	},
})
export default class ModalRewordCustomer extends Vue {
	@Prop() coinUser;

	@Action(namespaced(NS_SETTINGS, REWORD_CUSTOMER)) rewordCustomer;
	HOST = API_HOST;
	open_modal = false;
	formReword = {
		type: "Super Coin",
		quantity: 100,
		user: this.coinUser,
	};

	@Watch("coinUser")
	handlePropChange(val, oldVal) {
		if (val != oldVal) {
			this.formReword.user = this.coinUser;
		}
	}
	mounted() {}
	toggle() {
		this.open_modal = !this.open_modal;
	}

	handleRewardCustomer() {
		this.rewordCustomer(this.formReword).then((data) => {
			if (data) {
				this.toggle();
			}
		});
	}
}
</script>

<style scoped lang="scss">
.modal-header {
	display: grid !important;
	grid-template-columns: 6fr 6fr !important;
	gap: 10px;
	div {
		display: flex;
		font-size: 18px;
	}
	.text-right {
		justify-content: right;
		color: red;
	}
	.close-user-icon {
		cursor: pointer;
	}
}
.toggle-button-space {
	height: 36px;
	min-width: 150px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: right;
	padding-left: 10px;
	padding-right: 10px;
	.custom-button {
		background: #65b781 !important;
		color: white !important;
		border-radius: 10px !important;
		cursor: pointer;
	}
}
.custom-form {
	margin-top: 10px;
	display: grid;
	grid-template-columns: 12fr;
	padding: 20px 40px;
	label {
		color: black;
		margin-bottom: 5px;
		font-size: 16px;
	}
}
.mx-datepicker {
	width: 100%;
}
</style>
