import { GET_AUTH_HEADER } from "../getter.names";
import { Vue } from "vue-property-decorator";

import {} from "../getter.names";
import {
	FETCH_WARRANTY_OR_RETURN_CALIM,
	FETCH_WARRANTY_OR_RETURN_DETAIL,
	FETCH_WARRANTY_OR_RETURN_OPTIONS_DATA,
	UPDATE_WARRENTY_RETURN,
	//   UPDATE_WARRANTY_OR_RETURN,
} from "../action.names";
import { FETCH_WARRANTY_OR_RETURN_ENDPOINT } from "../endpoints";
import {} from "../mutation.names";
import { NS_USER } from "../namespace.names";
import { namespaced, buildParams } from "../utils";

export default {
	namespaced: true,
	state: {
		carts: [],
		order: {
			shippingPrice: "",
			order: {},
		},
	},
	getters: {},
	actions: {
		async [FETCH_WARRANTY_OR_RETURN_CALIM]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				let relativeUrl = `${FETCH_WARRANTY_OR_RETURN_ENDPOINT}${buildParams(
					payload
				)}`;
				axios
					.get(relativeUrl, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [FETCH_WARRANTY_OR_RETURN_DETAIL]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_WARRANTY_OR_RETURN_ENDPOINT}${payload.id}/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [FETCH_WARRANTY_OR_RETURN_OPTIONS_DATA](
			{ commit, rootGetters },
			payload
		) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_WARRANTY_OR_RETURN_ENDPOINT}form-options-data/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [UPDATE_WARRENTY_RETURN]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.patch(
						`${FETCH_WARRANTY_OR_RETURN_ENDPOINT}${payload.id}/`,
						payload.data,
						{
							headers: {
								...authHeader,
							},
						}
					)
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		// async [UPDATE_WARRANTY_OR_RETURN]({ commit, rootGetters }, payload) {
		//   return new Promise((resolve, reject) => {
		//     const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
		//     axios
		//       .patch(`${FETCH_WARRANTY_OR_RETURN_ENDPOINT}${payload.id}/`, payload.formData, {
		//         headers: {
		//           ...authHeader,
		//         },
		//       })
		//       .then(({ data }) => {
		//         resolve(data);
		//       })
		//       .catch((e) => {
		//         console.log(e);
		//         reject(e);
		//       });
		//   });
		// },
	},

	mutations: {},
};
