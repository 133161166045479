<template>
	<div>
		<div @click.prevent="toggle" class="add-new-user-btn">Add New Category</div>
		<sui-modal v-model="open_modal">
			<sui-modal-header class="modal-header">
				<div>Add Category</div>
				<div class="text-right">
					<Icon
						@click.native="toggle"
						class="add-user-icon"
						icon="charm:circle-cross"
					/>
				</div>
			</sui-modal-header>
			<sui-form class="custom-form">
				<sui-modal-content scrolling>
					<label>Name</label>
					<sui-form-field required>
						<input
							required
							v-model="formCategory.name"
							placeholder="Coupon name"
						/>
					</sui-form-field>

					<sui-segment>
						<sui-form-field>
							<sui-checkbox
								toggle
								label="Is Active"
								v-model="formCategory.is_active"
							/>
						</sui-form-field>
					</sui-segment>

					<label>Meta title </label>
					<sui-form-field>
						<input
							required
							v-model="formCategory.meta_title"
							placeholder="Combo offer"
						/>
					</sui-form-field>

					<label>Meta Description </label>
					<sui-form-field>
						<input
							required
							v-model="formCategory.meta_desc"
							placeholder="Combo offer"
						/>
					</sui-form-field>

					<label>Meta Keywords </label>
					<sui-form-field>
						<input
							required
							v-model="formCategory.meta_keywords"
							placeholder="Combo offer"
						/>
					</sui-form-field>

					<div class="image-grid">
						<modal-choose-image
							class="image-modal"
							:add-button-name="icon_add_button"
							@passSelectedImage="getSelectedIconImage"
						>
						</modal-choose-image>
						<sui-image :src="selected_icon.file" size="tiny" />

						<modal-choose-image
							class="image-modal"
							:add-button-name="cover_image_add_button"
							@passSelectedImage="getSelectedCOverImage"
						>
						</modal-choose-image>
						<sui-image :src="selected_cover_image.file" size="tiny" />
					</div>
				</sui-modal-content>
				<sui-modal-actions>
					<sui-button
						color="teal"
						style="margin-top: 20px"
						floated="right"
						@click.prevent="handleCreateCategory"
					>
						Save
					</sui-button>
				</sui-modal-actions>
			</sui-form>
		</sui-modal>
	</div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { Icon } from "@iconify/vue2";
import { API_HOST } from "../../../global";
import { namespaced } from "../../../store/utils";
import { NS_PRODUCTS } from "../../../store/namespace.names";
import { CREATE_CATEGORY } from "../../../store/action.names";
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import ModalChooseImage from "../../../components/common/ModalChooseImage.vue";

@Component({
	name: "ModalAddCategory",
	components: {
		Icon,
		ValidationObserver,
		ValidationProvider,
		ModalChooseImage,
	},
})
export default class ModalAddCategory extends Vue {
	@Prop() parentCategory;
	@Action(namespaced(NS_PRODUCTS, CREATE_CATEGORY)) createCategory;

	HOST = API_HOST;
	open_modal = false;
	icon_add_button = "Add Icon";
	cover_image_add_button = "Add Cover Image";
	formCategory = {
		name: "",
		parent: "",
		is_active: false,
		icon: null,
		cover_img: null,
		meta_title: "",
		meta_desc: "",
		meta_keywords: "",
	};

	selected_icon = {
		file: "",
		id: "",
	};

	selected_cover_image = {
		file: "",
		id: "",
	};

	mounted() {}

	@Watch("parentCategory", { immediate: true, deep: true })
	handleParentCategory(val, oldVal) {
		this.setParent();
	}

	toggle() {
		this.open_modal = !this.open_modal;
	}

	setParent() {
		if (this.parentCategory) {
			this.formCategory.parent = this.parentCategory.id;
		}
	}

	getSelectedIconImage(value) {
		this.selected_icon = value;
	}

	getSelectedCOverImage(value) {
		this.selected_cover_image = value;
	}

	handleCreateCategory() {
		if (this.formCategory.name == "") {
			Vue.$toast.error("You need to enter name!", {});
			return false;
		}

		if (this.selected_icon.id) {
			this.formCategory.icon = this.selected_icon.id;
		}
		if (this.selected_cover_image.id) {
			this.formCategory.cover_img = this.selected_cover_image.id;
		}

		this.createCategory(this.formCategory).then((data) => {
			if (data) {
				this.$emit("createdCategory", data);
				this.toggle();
			}
		});
	}
}
</script>

<style scoped lang="scss">
.modal-header {
	display: grid !important;
	grid-template-columns: 6fr 6fr !important;
	gap: 10px;
	div {
		display: flex;
		font-size: 18px;
	}
	.text-right {
		justify-content: right;
		color: red;
	}
}
.image-grid {
	margin-top: 30px;
	display: grid;
	grid-template-columns: 4fr 8fr;
	gap: 2px 40px;
	.image-modal {
		margin-bottom: 10px;
	}
}
.add-new-user-btn {
	height: 36px;
	min-width: 150px;
	background-color: #edf1fe;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 10px;
	padding-right: 10px;
	color: #5c83f3;
	cursor: pointer;
	font-weight: 500;
	.add-user-icon {
		font-size: 20px;
		font-weight: 500;
		margin-right: 5px;
	}
	.status-display {
		margin-left: 10px;
		background: #5b83f3;
		padding: 5px 15px;
		border-radius: 10px;
		color: #f1f1f1;
	}
}
.custom-form {
	margin-top: 10px;
	display: grid;
	grid-template-columns: 12fr;
	padding: 20px 40px;
	label {
		color: black;
		margin-bottom: 5px;
		font-size: 16px;
	}
}
.mx-datepicker {
	width: 100%;
}
</style>
