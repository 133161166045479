<template>
  <div class="single-table-row">
    <div class="invoice-id">
      <div class="name">Invoice No</div>
    </div>
    <div>
      <span v-if="res.order_by"> Product Id </span>
      <span v-else> Not yet set </span>
    </div>
    <div>
      <span v-if="res.status_display">
        <!-- {{ res.status_display }} -->
        Product name
      </span>
      <span v-else> Not yet set </span>
    </div>
    <div>
      <span v-if="res.total_price">
        <!-- {{ res.total_price }} -->
        Duration left
      </span>
      <span v-else> Not yet set </span>
    </div>
    <div>
      <span v-if="res.date">
        <!-- {{ res.date }} -->
        purchase date
      </span>
      <span v-else> Not yet set </span>
    </div>
    <div>
      <span v-if="res.authorized_by">
        <!-- {{ res.authorized_by }} -->
        reason
      </span>
      <span v-else> Not yet set </span>
    </div>
    <div class="detail-link">
      <sui-button @click.native="handleReviewDetail()" color="teal" icon>
        <Icon class="icon-font expand-btn" icon="akar-icons:eye" />
      </sui-button>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { Icon } from "@iconify/vue2";
import { API_HOST } from "../../../global";
import { namespaced } from "../../../store/utils";
import { NS_REVIEWS } from "../../../store/namespace.names";
import { DELETE_REVIEW } from "../../../store/action.names";

@Component({
  name: "ReviewTableSingleRow",
  components: {
    Icon,
  },
})
export default class ReviewTableSingleRow extends Vue {
  @Prop() res;

  @Action(namespaced(NS_REVIEWS, DELETE_REVIEW)) deleteReview;

  HOST = API_HOST;
  id = null;
  showShortMenu = false;

  toggleShortMenu() {
    this.showShortMenu = !this.showShortMenu;
  }

  mounted() {
    this.id = this.res.id;
  }

  handleReviewDetail() {
    this.$router.push({
      name: "review-details",
      params: {
        id: this.id,
      },
    });
  }
}
</script>

<style scoped lang="scss">
.single-table-row {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 0.5fr;
  gap: 10px;
  min-height: 40px;
  border-bottom: 1px solid grey;
  text-decoration: none;
  // &:hover{
  //   background-color: #DEE9FE;
  //   cursor: pointer;
  // }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    color: #12233a;
  }
  .invoice-id {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px;
    .image {
      width: 60px;
      height: 60px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .action-column {
    position: relative;
    &:hover {
      .action-dropdown {
        display: block;
      }
    }
    .action-dropdown {
      width: 136px;
      z-index: 999;
      position: absolute;
      display: none;
      top: 30px;
      background: #f7f7f7;
      border: 1px solid #a4a4a4;
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(162, 162, 162, 0.25);
      border-radius: 6px;
      .dropdown-item {
        font-size: 14px;
        font-weight: 600;
        color: #62b37c;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        padding: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        cursor: pointer;
      }
    }
  }
}
.detail-link {
  position: relative;
  .expand-btn {
    cursor: pointer;
  }
  .short-menu {
    position: absolute;
    height: 50px;
    width: 230px;
    right: 80px;
    background: #f2f6ff;
    box-shadow: 0px 4px 16px rgba(64, 64, 64, 0.21);
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    a {
      font-size: 16px;
      font-weight: 600;
      height: 50px;
      width: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: #5c83f3;
        color: white;
      }
      &:last-child {
        &:hover {
          background-color: red;
          color: white;
        }
      }
    }
  }
}
</style>
