<template>
	<sui-segment class="single-table-row">
		<div class="image-name">
			<div class="image" v-if="res.product_detail.default_image">
				<img :src="HOST + res.product_detail.default_image.mobile.src" />
			</div>
		</div>
		<div>
			{{ res.product_detail.name }}
		</div>
		<div class="price_section">
			<div>
				<div v-if="res.old_price">
					<del> {{ res.old_price }}/pc </del>
				</div>
				<div v-else>N/A</div>
			</div>
			<div class="right_text">
				<b>{{ res.per_price }}/pc</b>
			</div>
			<div><b>Qty</b></div>
			<div class="right_text">
				<b>{{ res.count }} pc</b>
			</div>

			<div><b>Total</b></div>
			<div class="right_text">
				<b>ট {{ res.total_price }}</b>
			</div>
		</div>
	</sui-segment>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Icon } from "@iconify/vue2";
import { namespaced } from "../../../store/utils";
import { API_HOST } from "../../../global";

@Component({
	name: "CardOrderProductItem",
	components: {
		Icon,
	},
})
export default class CardOrderProductItem extends Vue {
	HOST = API_HOST;
	@Prop() res;
}
</script>

<style scoped lang="scss">
.single-table-row {
	display: grid;
	grid-template-columns: 1fr 5fr 2fr;
	gap: 10px;
	min-height: 40px;
	border: 1px solid grey;
	text-decoration: none;
	// &:hover{
	//   background-color: #DEE9FE;
	//   cursor: pointer;
	// }
	.image {
		width: 60px;
		height: 40px;
		img {
			height: 100%;
			width: 100%;
		}
	}
	.price_section {
		display: grid;
		gap: 5px;
		grid-template-columns: 5fr 5fr;
		div {
			display: flex;
		}
		.right_text {
			justify-content: right;
		}
	}
	div {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 16px;
		color: #12233a;
	}
	.order-id {
		display: flex;
		justify-content: flex-start;
		gap: 10px;
		padding: 10px;
	}
	.action-column {
		position: relative;
		&:hover {
			.action-dropdown {
				display: block;
			}
		}
		.action-dropdown {
			width: 136px;
			z-index: 999;
			position: absolute;
			display: none;
			top: 30px;
			background: #f7f7f7;
			border: 1px solid #a4a4a4;
			box-sizing: border-box;
			box-shadow: 0px 4px 10px rgba(162, 162, 162, 0.25);
			border-radius: 6px;
			.dropdown-item {
				font-size: 14px;
				font-weight: 600;
				color: #62b37c;
				text-align: left;
				display: flex;
				justify-content: flex-start;
				padding: 10px;
				padding-top: 15px;
				padding-bottom: 15px;
				padding-left: 15px;
				cursor: pointer;
			}
		}
	}
}
.detail-link {
	position: relative;
	.expand-btn {
		cursor: pointer;
	}
	.short-menu {
		position: absolute;
		height: 50px;
		width: 230px;
		right: 80px;
		background: #f2f6ff;
		box-shadow: 0px 4px 16px rgba(64, 64, 64, 0.21);
		border-radius: 8px;
		display: flex;
		justify-content: space-around;
		a {
			font-size: 16px;
			font-weight: 600;
			height: 50px;
			width: 100%;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			&:hover {
				background-color: #5c83f3;
				color: white;
			}
			&:last-child {
				&:hover {
					background-color: red;
					color: white;
				}
			}
		}
	}
}
</style>
