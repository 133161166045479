export const namespaced = (namespace, asset) => `${namespace}/${asset}`;

export const buildParams = (params) => {
	const keys = Object.keys(params);
	if (keys.length == 0) return "";
	return (
		"?" +
		keys
			.map((key) => {
				return `${key}=${params[key]}`;
			})
			.join("&")
	);
};

export const deepCopy = (params) => {
	return JSON.parse(JSON.stringify(params));
};

export const humanizeNumber = (num, digits) => {
	const lookup = [
		{ value: 1, symbol: "" },
		{ value: 1e3, symbol: "k" },
		{ value: 1e6, symbol: "M" },
		{ value: 1e9, symbol: "G" },
		{ value: 1e12, symbol: "T" },
		{ value: 1e15, symbol: "P" },
		{ value: 1e18, symbol: "E" },
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	var item = lookup
		.slice()
		.reverse()
		.find(function (item) {
			return num >= item.value;
		});
	return item
		? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
		: "0";
};

export const intNumberFormat = (number) => {
	return new Intl.NumberFormat().format(number);
};

export const menus = [
	{
		name: "dashboard",
		label: "Dashboard",
		icon: "ion:speedometer",
		link: "/",
		childs: [],
	},
	{
		name: "users",
		label: "Users",
		icon: "bx:bxs-user",
		link: "/users",
		childs: [
			{
				name: "customer",
				label: "Customer",
				icon: null,
				link: "/users?type=customer",
				childs: null,
			},
			{
				name: "office-employees",
				label: "Office employees",
				icon: null,
				link: "/users?type=employee",
				childs: null,
			},
		],
	},
	{
		name: "products",
		label: "Products",
		icon: "bi:box-seam",
		link: "/products",
		childs: [
			{
				name: "category",
				label: "Category",
				icon: null,
				link: "/products/category",
				childs: null,
			},
			{
				name: "attribute",
				label: "Attribute",
				icon: null,
				link: "/products/attribute",
				childs: null,
			},
			{
				name: "brand",
				label: "Brand",
				icon: null,
				link: "/products/brand",
				childs: null,
			},
			{
				name: "out-of-stocks",
				label: "Out of stocks",
				icon: null,
				link: "/products/out-of-stocks",
				childs: null,
			},
		],
	},
	{
		name: "orders",
		label: "Orders",
		icon: "icon-park-outline:transaction-order",
		link: "/orders",
		childs: [
			{
				name: "pending-payment",
				label: "Pending Payment",
				icon: null,
				link: "/orders/pending-payment",
				childs: null,
			},
			{
				name: "cod-processing",
				label: "COD Processing",
				icon: null,
				link: "/orders/cod-processing",
				childs: null,
			},
			{
				name: "cod-shipped",
				label: "COD Shipped",
				icon: null,
				link: "/orders/cod-shipped",
				childs: null,
			},
			{
				name: "cod-delivered",
				label: "COD Delivered",
				icon: null,
				link: "/orders/cod-delivered",
				childs: null,
			},

			{
				name: "paid-processing",
				label: "Paid Processing",
				icon: null,
				link: "/orders/paid-processing",
				childs: null,
			},
			{
				name: "paid-shipped",
				label: "Paid Shipped",
				icon: null,
				link: "/orders/paid-shipped",
				childs: null,
			},
			{
				name: "paid-delivered",
				label: "Paid Delivered",
				icon: null,
				link: "/orders/paid-delivered",
				childs: null,
			},
			{
				name: "cancelled",
				label: "Cancelled",
				icon: null,
				link: "/orders/cancelled",
				childs: null,
			},
			{
				name: "return",
				label: "Return",
				icon: null,
				link: "/orders/return",
				childs: null,
			},
			// {
			// 	name: "delivery-failed",
			// 	label: "Delivery Failed",
			// 	icon: null,
			// 	link: "/orders/delivery-failed",
			// 	childs: null,
			// },
			// {
			// 	name: "search-on-forworder",
			// 	label: "Search on Forworder",
			// 	icon: null,
			// 	link: "/orders/search-on-forworder",
			// 	childs: null,
			// },
		],
	},
	{
		name: "business-analytics",
		label: "Business Analytics",
		icon: "icon-park-outline:transaction-order",
		link: "/business-analytics",
		childs: [
			{
				name: "product-analytics",
				label: "Product Analytics",
				icon: null,
				link: "/business-analytics/product-analytics",
				childs: null,
			},
			{
				name: "customer-analytics",
				label: "Customer Analytics",
				icon: null,
				link: "/business-analytics/customer-analytics",
				childs: null,
			},
			{
				name: "order-analytics",
				label: "Order Analytics",
				icon: null,
				link: "/business-analytics/order-analytics",
				childs: null,
			},
			{
				name: "financial-analytics",
				label: "Financial Analytics",
				icon: null,
				link: "/business-analytics/financial-analytics",
				childs: null,
			},
		],
	},

	// {
	// 	name: "customer-reviews",
	// 	label: "Customer Review",
	// 	icon: "ant-design:comment-outlined",
	// 	link: "/customer-reviews",
	// 	childs: [
	// 		{
	// 			name: "pending-review",
	// 			label: "Pending Review",
	// 			icon: null,
	// 			link: "/customer-reviews/pending-review",
	// 			childs: null,
	// 		},
	// 		{
	// 			name: "authorized-review",
	// 			label: "Authorized Review",
	// 			icon: null,
	// 			link: "/customer-reviews/authorized-review",
	// 			childs: null,
	// 		},
	// 		{
	// 			name: "trashed-review",
	// 			label: "Trashed Review",
	// 			icon: null,
	// 			link: "/customer-reviews/trashed-review",
	// 			childs: null,
	// 		},
	// 	],
	// },

	{
		name: "warranty-claim",
		label: "Warranty Claim",
		icon: "mdi:shield-crown-outline",
		link: "/warranty-claim",
		childs: [
			{
				name: "pending",
				label: "Pending Warranty",
				icon: null,
				link: "/warranty-claim/pending",
				childs: null,
			},
			{
				name: "received",
				label: "Received Warranty",
				icon: null,
				link: "/warranty-claim/received",
				childs: null,
			},
			{
				name: "complete",
				label: "Complete Warranty",
				icon: null,
				link: "/warranty-claim/complete",
				childs: null,
			},
			{
				name: "cancel",
				label: "Cancel Warranty",
				icon: null,
				link: "/warranty-claim/cancel",
				childs: null,
			},
		],
	},

	{
		name: "return-claim",
		label: "Return Claim",
		icon: "ic:outline-assignment-return",
		link: "/return-claim",
		childs: [
			{
				name: "pending",
				label: "Pending Return",
				icon: null,
				link: "/return-claim/pending",
				childs: null,
			},
			{
				name: "received",
				label: "Received Return",
				icon: null,
				link: "/return-claim/received",
				childs: null,
			},
			{
				name: "complete",
				label: "Complete Return",
				icon: null,
				link: "/return-claim/complete",
				childs: null,
			},
			{
				name: "cancel",
				label: "Cancel Return",
				icon: null,
				link: "/return-claim/cancel",
				childs: null,
			},
		],
	},
	{
		name: "job-applications",
		label: "Job Application",
		icon: "bxs:user-detail",
		link: "/job-applications",
		childs: [],
	},
	{
		name: "admin-messages",
		label: "Messages",
		icon: "ant-design:message-outlined",
		link: "/admin-messages",
		childs: [],
	},
	{
		name: "admin-campaigns",
		label: "Campaigns",
		icon: "ic:outline-campaign",
		link: "/admin-campaigns",
		childs: [],
	},
	// {
	// 	name: "admin-documents",
	// 	label: "Documents",
	// 	icon: "et:documents",
	// 	link: "/admin-documents",
	// 	childs: [],
	// },
	// {
	// 	name: "admin-blogs",
	// 	label: "Blogs",
	// 	icon: "fontisto:blogger",
	// 	link: "/admin-blogs",
	// 	childs: [],
	// },
	{
		name: "admin-coupons",
		label: "Coupons",
		icon: "fluent:shopping-bag-percent-24-regular",
		link: "/admin-coupons",
		childs: [],
	},
];
