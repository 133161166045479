import { GET_AUTH_HEADER } from "../getter.names";
import { Vue } from "vue-property-decorator";

import {} from "../getter.names";
import {
	FETCH_IMAGE,
	FETCH_IMAGE_DETAIL,
	CREATE_IMAGE,
	REWORD_CUSTOMER,
	FETCH_CONTACTS,
	FETCH_CONTACT_DETAIL,
	UPDATE_CONTACT,
} from "../action.names";
import {
	FETCH_IMAGE_ENDPOINT,
	ADMIN_GENERAL_ENDPOINT,
	FETCH_CONTACT_ENDPOINT,
} from "../endpoints";
import {} from "../mutation.names";
import { NS_USER } from "../namespace.names";
import { namespaced, buildParams } from "../utils";

export default {
	namespaced: true,
	state: {
		menu: [],
		settings: {},
		categories: [],
		cities: [],
		shippingAddress: [],
	},
	getters: {},
	actions: {
		async [FETCH_IMAGE]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				let relativeUrl = `${FETCH_IMAGE_ENDPOINT}${buildParams(payload)}`;
				axios
					.get(relativeUrl, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [FETCH_IMAGE_DETAIL]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_IMAGE_ENDPOINT}${payload.id}/`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [CREATE_IMAGE]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.post(`${FETCH_IMAGE_ENDPOINT}`, payload, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						Vue.$toast.success("Image created successfully!", {});
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [REWORD_CUSTOMER]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.post(`${ADMIN_GENERAL_ENDPOINT}reword-customer/`, payload, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						Vue.$toast.success("Customer reworded successfully!", {});
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},

		async [FETCH_CONTACTS]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				let relativeUrl = `${FETCH_CONTACT_ENDPOINT}${buildParams(payload)}`;
				axios
					.get(relativeUrl, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [FETCH_CONTACT_DETAIL]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.get(`${FETCH_CONTACT_ENDPOINT}${payload.id}`, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
		async [UPDATE_CONTACT]({ commit, rootGetters }, payload) {
			return new Promise((resolve, reject) => {
				const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
				axios
					.patch(`${FETCH_CONTACT_ENDPOINT}${payload.id}/`, payload.formData, {
						headers: {
							...authHeader,
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			});
		},
	},
	mutations: {},
};
