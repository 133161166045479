<template>
	<div class="page-wrapper">
		<div class="top">
			<div class="left">{{ attributeDetail.name }}</div>
			<div class="right"></div>
		</div>
		<div class="content">
			<sui-segment attached="top">
				<sui-form>
					<sui-form-field>
						<input
							placeholder="Attribute Value"
							v-model="attributeForm.value"
							type="text"
						/>
					</sui-form-field>
				</sui-form>

				<sui-button primary @click="handleAddAttributeValue()">
					Add
				</sui-button>
			</sui-segment>
			<sui-segment
				attached="top"
				v-for="(attribute_value, index) in attributeValueList"
				:key="index"
			>
				<sui-form>
					<sui-form-field>
						<input
							placeholder="Attribute Value"
							v-model="attribute_value.value"
							type="text"
						/>
					</sui-form-field>
				</sui-form>
				<div>
					<sui-button
						icon="trash"
						negative
						@click="handleDeleteAttributeValue(attribute_value.id)"
					>
					</sui-button>
					<sui-button
						icon="save"
						primary
						@click="handleUpdateAttributeValue(attribute_value)"
					>
					</sui-button>
				</div>
			</sui-segment>
		</div>

		<div class="c-pagination" v-if="paginationSteps.length > 1">
			<a class="item" @click.prevent="handleDecrement"> Prev </a>
			<a
				:class="{ active: page == pag, item: true }"
				v-for="(pag, idx) in paginationSteps"
				:key="'pag_' + idx"
				@click.prevent="updatePage(pag)"
			>
				{{ pag }}
			</a>
			<a class="item" @click.prevent="handleIncreament"> Next </a>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../../../store/utils";
import { API_HOST } from "../../../global";
import { NS_USER, NS_PRODUCTS } from "../../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../../store/mutation.names";
import {
	FETCH_ATTRIBUTE_DETAIL,
	ADD_ATTRIBUTE_VALUE,
	UPDATE_ATTRIBUTE_VALUE,
	DELETE_ATTRIBUTE_VALUE,
} from "../../../store/action.names";
import { GET_ACTIVE_SUBMENU } from "../../../store/getter.names";
import { Icon } from "@iconify/vue2";
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";

@Component({
	name: "AttributeDetail",
	components: {
		Icon,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class AttributeDetail extends Vue {
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;
	@Getter(namespaced(NS_USER, GET_ACTIVE_SUBMENU)) getActiveSubmenu;

	@Action(namespaced(NS_PRODUCTS, FETCH_ATTRIBUTE_DETAIL)) fetchAttributeDetail;
	@Action(namespaced(NS_PRODUCTS, ADD_ATTRIBUTE_VALUE)) addAttributeValue;
	@Action(namespaced(NS_PRODUCTS, UPDATE_ATTRIBUTE_VALUE)) updateAttributeValue;
	@Action(namespaced(NS_PRODUCTS, DELETE_ATTRIBUTE_VALUE)) deleteAttributeValue;

	HOST = API_HOST;
	data = null;
	attributeId = this.$route.params.id;
	attributeDetail = {};
	attributeForm = {
		attribute: this.attributeId,
		value: "",
	};

	page = this.$route.query.page ? this.$route.query.page : 1;
	attributeValueList = [];
	count = null;
	paginationSteps = [this.page];
	totalPageCount = "";

	@Watch("$route")
	handleChangeRoute(val, oldVal) {
		this.page = this.$route.query.page ? this.$route.query.page : 1;
		if (val != oldVal) {
			this.fetchData();
			this.paginationSteps = [this.page];
		}
	}

	handleDecrement() {
		if (this.page > 1) {
			this.$router
				.push(
					`/products/attribute/${this.attributeId}?page=${
						parseInt(this.page) - 1
					}`
				)
				.catch(() => {});
		} else {
			this.$router
				.push(`/products/attribute/${this.attributeId}?page=1`)
				.catch(() => {});
		}
	}

	handleIncreament() {
		if (this.page < this.totalPageCount) {
			this.$router
				.push(
					`/products/attribute/${this.attributeId}?page=${
						parseInt(this.page) + 1
					}`
				)
				.catch(() => {});
		} else {
			this.$router
				.push(
					`/products/attribute/${this.attributeId}?page=${this.totalPageCount}`
				)
				.catch(() => {});
		}
	}

	calculateNext(totalPage) {
		if (totalPage > 1) {
			var next = this.page;
			for (var i = 0; i < totalPage; i++) {
				if (i > 3) {
					break;
				}
				if (this.page < totalPage) {
					next = parseInt(next) + 1;
					if (next > totalPage) {
						break;
					}
					this.paginationSteps.push(next);
				}
			}
		}
	}

	calculatePrev() {
		if (this.page > 1) {
			var counter = 0;
			for (var i = this.page - 1; i > 0; i--) {
				if (counter > 3) {
					break;
				}
				this.paginationSteps.unshift(i);
				counter += 1;
			}
		}
	}

	calculatePage() {
		const totalPage = Math.ceil(this.count / 15);
		this.totalPageCount = totalPage;
		if (totalPage > 1) {
			this.calculateNext(totalPage);
			this.calculatePrev();
		}
	}

	updatePage(pageNo) {
		this.$router
			.push(`/products/attribute/${this.attributeId}?page=${pageNo}`)
			.catch(() => {});
	}

	handleAttributeDelete() {
		this.fetchData();
	}

	handleUpdateAttributeValue(attributeValue) {
		let updatedAttributeValue = {
			value: attributeValue.value,
		};
		this.updateAttributeValue({
			id: attributeValue.id,
			formData: updatedAttributeValue,
		}).then((data) => {});
	}

	handleAddAttributeValue() {
		if (this.attributeForm.value) {
			this.addAttributeValue(this.attributeForm).then((response) => {
				this.attributeDetail.values.push(response);
				this.resetForm();
			});
		}
	}

	resetForm() {
		this.attributeForm = {
			attribute: this.attributeId,
			value: "",
		};
	}

	handleDeleteAttributeValue(attributeValueId) {
		this.$confirm("Are you sure to delete this attribute value?").then(() => {
			this.deleteAttributeValue({
				id: attributeValueId,
			}).then((data) => {
				this.fetchData();
			});
		});
	}

	fetchData() {
		this.fetchAttributeDetail({ id: this.attributeId }).then((data) => {
			this.attributeDetail = data;
			this.fetchAttributeValue();
		});
	}
	// http://localhost:8000/api/v1/admin/attribute/values/?attribute=1
	fetchAttributeValue() {
		axios
			.get(
				`${this.HOST}/api/v1/admin/attribute/values/?attribute=${this.attributeId}&page=${this.page}`,
				this.formData,
				{
					headers: {
						Authorization: `Token ${this.getToken}`,
					},
				}
			)
			.then((response) => {
				if (response.status == 200) {
					this.count = response.data.count;
					this.attributeValueList = response.data.results;
					this.calculatePage();
				}
			});
	}

	mounted() {
		this.setActiveSidebar("products");
		this.setActiveSubmenu("attribute");
		this.fetchData();
	}
}
</script>

<style scoped lang="scss">
.page-wrapper {
	margin-bottom: 10px;
}
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.left {
		font-weight: 700;
		font-size: 20px;
	}
	.right {
		display: flex;
		gap: 40px;
		.user-filter {
			height: 36px;
			width: 200px;
			background-color: #edf1fe;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 10px;
			padding-right: 10px;
			.label {
				font-weight: 500;
				font-size: 16px;
				color: #5c83f3;
			}
			.dropdown-wrapper {
				.dropdown {
					width: 93px;
					height: 28px;
					background-color: #5c83f3;
					color: white;
					font-weight: 500;
					border-radius: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					position: relative;
				}
				.dropdown-content {
					position: absolute;
					width: 93px;
					margin-top: 5px;
					background-color: white;
					z-index: 999;
					box-shadow: 5px 5px 4px 5px #f1f1f1;
					.item {
						padding: 10px;
						cursor: pointer;
					}
				}
			}
		}
		.add-new-user-btn {
			height: 36px;
			min-width: 150px;
			background-color: #edf1fe;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-left: 10px;
			padding-right: 10px;
			color: #5c83f3;
			cursor: pointer;
			font-weight: 500;
			.add-user-icon {
				font-size: 20px;
				font-weight: 500;
				margin-right: 5px;
			}
		}
	}
}
.content {
	margin-top: 30px;
}
.c-pagination {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	margin-top: 15px;
	a {
		color: #737373;
		font-size: 14px;
		font-weight: 600;
		cursor: pointer;
	}
}
.active {
	color: black !important;
}
</style>
