export const NS_USER = "user";
export const NS_PRODUCTS = "products";
export const NS_ORDERS = "orders";
export const NS_ANALYTICS = "analytics";
export const NS_SETTINGS = "settings";
export const NS_WARRANTY_RETURN = "warranty_or_return";

export const NS_CAMPAIGNS = "campaigns";
export const NS_COUPONS = "coupons";
export const NS_JOBS = "jobs";
export const NS_REVIEWS = "reviews";
export const NS_CATEGORY = "categories";
