<template>
  <div>
    <div class="container">{{ message }}</div>
  </div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";

import { Icon } from "@iconify/vue2";

@Component({
  name: "Warning",
  components: {
    Icon,
  },
})
export default class Warning extends Vue {
  @Prop() message;
}
</script>

<style scoped lang="scss">
.container {
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ad6418;
  background: #fffaf3;
  border: 1px solid #c9ba9b;
  margin: 10px 0px;
  border-radius: 10px;
  font-size: 18px;
}
</style>
