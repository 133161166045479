<template>
	<div class="wrapper-note">
		<div class="note-header">
			<h2>Message to Customer</h2>
			<Icon class="minus-icon" icon="akar-icons:minus" />
		</div>
		<div class="note-detail">
			<sui-form>
				<sui-form-field>
					<label class="note-form-level">Phone number</label>
					<input disabled v-model="message_form.phone" placeholder="" />
				</sui-form-field>

				<sui-form-field>
					<label class="note-form-level">Message</label>
					<textarea v-model="message_form.message"></textarea>
				</sui-form-field>
				<sui-button
					class="submit-button"
					@click.prevent="sendMessage()"
					type="submit"
				>
					Send
				</sui-button>
			</sui-form>

			<!-- <div class="button-section">
				<div class="button-item"><h3>Processing Status Mail</h3></div>
				<div class="button-item"><h3>Processing Status Message</h3></div>
				<div class="button-item print-button">
					<Icon class="print-icon" icon="fluent:print-20-regular" />
					<h3>Print Label</h3>
				</div>
			</div> -->
		</div>
		<!-- {{ selectedOrder }} -->
	</div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Icon } from "@iconify/vue2";
import { namespaced } from "../../../store/utils";
import { API_HOST } from "../../../global";
import { Action, Getter, Mutation } from "vuex-class";
import { NS_USER, NS_ORDERS } from "../../../store/namespace.names";
import { SEND_MESSAGE_TO_CUSTOMER } from "../../../store/action.names";

@Component({
	name: "CardOrderProductItem",
	components: {
		Icon,
	},
})
export default class CardOrderProductItem extends Vue {
	@Action(namespaced(NS_ORDERS, SEND_MESSAGE_TO_CUSTOMER))
	sendMessageToCustomer;

	HOST = API_HOST;
	@Prop() selectedOrder;

	message_form = {
		phone: "",
		message: "",
		order: "",
	};

	sendMessage() {
		console.log(this.message_form);
		console.log("sending message to customer...");

		this.sendMessageToCustomer(this.message_form).then((data) => {
			this.$toast.success("Message send to customer successfully!", {});
			this.setData(data);
		});
	}

	@Watch("selectedOrder")
	handleInStocked(val, oldVal) {
		this.setData();
	}

	setData() {
		if (this.selectedOrder && this.selectedOrder.shipping_address_detail) {
			this.message_form.phone =
				this.selectedOrder.shipping_address_detail.phone;
			this.message_form.order = this.selectedOrder.id;
		}
	}

	mounted() {
		this.setData();
	}
}
</script>

<style scoped lang="scss">
.wrapper-note {
	background: #f2f6ff;
	border-radius: 0px 0px 20px 20px;
	.note-header {
		background: #12233a;
		color: white;
		padding: 15px;
		display: flex;
		h2 {
			margin-bottom: 0px;
		}
		.minus-icon {
			margin-left: auto;
			display: flex;
			justify-content: center;
			text-align: center;
			font-size: 30px;
		}
	}
	.note-detail {
		padding: 20px;
		.note-form-level {
			font-size: 18px;
			color: #86878a;
		}
		.submit-button {
			display: flex;
			margin-left: auto !important;
			background: #65b781 !important;
			color: white !important;
			border-radius: 10px !important;
			font-size: 16px;
		}
		.button-section {
			margin-top: 25px;
			.button-item {
				padding: 10px;
				background: #12233a;
				color: white;
				margin: 15px 0px;
				border-radius: 10px;
			}
			.print-button {
				display: flex;
				justify-content: center;
				padding: 10px;
				background: #ec7e4c;
				color: white;
				margin-top: 40px;
				border-radius: 10px;
				.print-icon {
					font-size: 22px;
				}
				h3 {
					margin-left: 10px;
					margin-top: 0px;
				}
			}
		}
	}
}
</style>
