<template>
  <div class="wrapper-note">
    <div class="note-header">
      <h2>Order Note</h2>
      <Icon class="minus-icon" icon="akar-icons:minus" />
    </div>
    <div class="note-detail">
      <sui-form>
        <sui-form-field>
          <label class="note-form-level">Admin note</label>
          <textarea></textarea>
        </sui-form-field>
        <sui-button
          class="submit-button"
          @click.prevent="sendOrderNote()"
          type="submit"
          >Send</sui-button
        >
      </sui-form>

      <div class="previus-note-list">
        <label class="note-form-level"> Last Admin Notes</label>

        <div class="user-descriptin">
          <span class="user-icon"> ■ </span>
          <span class="user-name">Thouhid</span>
          <span class="date"> Date </span>
        </div>
        <div class="note-message">
          {{ selectedOrder.admin_order_note }}
        </div>
      </div>
    </div>
    <!-- {{ selectedOrder }} -->
  </div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Icon } from "@iconify/vue2";
import { namespaced } from "../../../store/utils";
import { API_HOST } from "../../../global";

@Component({
  name: "CardOrderProductItem",
  components: {
    Icon,
  },
})
export default class CardOrderProductItem extends Vue {
  HOST = API_HOST;
  @Prop() selectedOrder;

  sendOrderNote() {
    console.log("sending order note...");
  }
}
</script>

<style scoped lang="scss">
.wrapper-note {
  background: #f2f6ff;
  .note-header {
    background: #12233a;
    color: white;
    padding: 15px;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    h2 {
      margin-bottom: 0px;
    }
    .minus-icon {
      margin-left: auto;
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 30px;
    }
  }
  .note-detail {
    padding: 20px;
    .note-form-level {
      font-size: 18px;
      color: #86878a;
    }
    .submit-button {
      display: flex;
      margin-left: auto !important;
      background: #65b781 !important;
      color: white !important;
      border-radius: 10px !important;
      font-size: 16px;
    }
    .previus-note-list {
      margin-top: 25px;
      .user-descriptin {
        display: flex;
        margin-top: 10px;
        .user-icon {
          font-size: 25px;
          color: #ec7e4c;
        }
        .user-name {
          font-size: 18px;
          margin-left: 5px;
          font-weight: bold;
        }
        .date {
          display: flex;
          margin-left: auto;
          justify-content: right;
        }
      }
      .note-message {
        font-weight: bold;
        margin: 10px 0px;
        font-size: 18px;
      }
    }
  }
}
</style>
