<template>
	<div>
		<div @click="toggle" class="round-orange-btn add-image-button">
			<span v-if="addButtonName">
				{{ addButtonName }}
			</span>
			<span v-else> Add Image </span>
		</div>
		<sui-modal v-model="open_modal">
			<sui-modal-header class="modal-header">
				<div>Images</div>
				<div class="text-right">
					<Icon
						@click.native="toggle"
						class="add-user-icon"
						icon="charm:circle-cross"
					/>
				</div>
			</sui-modal-header>
			<sui-modal-content scrolling>
				<!--  ============= image form section ============== -->
				<sui-form class="custom-form">
					<label>Add new image</label>
					<sui-form-field>
						<input
							type="file"
							ref="img"
							name="product-image"
							class="image-btn"
							accept="image/*"
							id="upload_image"
							@change="addPhoto($event.target.name, $event)"
						/>
						<sui-button
							floated="right"
							@click.prevent="handleCreateNewImage"
							content="Save Image"
						/>

						<sui-image size="small" v-if="imageFile" :src="imageFile.preview" />
					</sui-form-field>
				</sui-form>

				<!-- ============= image list showing section ============== -->
				<!-- image listing -->
				<h3 is="sui-header" dividing>Select An Image</h3>
				<div>
					<sui-image-group size="small" class="image-group">
						<div
							class="ui image image-wrapper"
							v-for="(img, index) in results"
							:key="index"
							@click="selecteImage(img)"
						>
							<sui-image
								:class="[
									selectedImage && selectedImage.id == img.id
										? 'selected-image custom-image-item'
										: 'custom-image-item',
								]"
								:src="img.file"
								rounded
							/>
						</div>
					</sui-image-group>
				</div>
				<!-- image pagination -->
				<div class="c-pagination" v-if="paginationSteps.length > 1">
					<sui-button class="item" @click.prevent="handleDecrement">
						Prev
					</sui-button>
					<sui-button
						:class="{ active: page == pag, item: true }"
						v-for="(pag, idx) in paginationSteps"
						:key="'pag_' + idx"
						@click.prevent="updatePage(pag)"
					>
						{{ pag }}
					</sui-button>
					<sui-button class="item" @click.prevent="handleIncreament">
						Next
					</sui-button>
				</div>
			</sui-modal-content>
			<sui-modal-actions>
				<sui-button @click.prevent="handleSelectedImage"> Add </sui-button>
			</sui-modal-actions>
		</sui-modal>
	</div>
</template>

<script>
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { Icon } from "@iconify/vue2";
import { API_HOST } from "../../global";
import { namespaced } from "../../store/utils";
import { NS_SETTINGS } from "../../store/namespace.names";
import { FETCH_IMAGE, CREATE_IMAGE } from "../../store/action.names";

@Component({
	name: "ModalChooseImage",
	components: {
		Icon,
	},
})
export default class ModalChooseImage extends Vue {
	@Prop() addButtonName;
	@Action(namespaced(NS_SETTINGS, FETCH_IMAGE)) fetchImages;
	@Action(namespaced(NS_SETTINGS, CREATE_IMAGE)) createImages;

	HOST = API_HOST;
	open_modal = false;

	page = 1;
	results = [];
	count = "";
	paginationSteps = [this.page];
	totalPageCount = "";
	selectedImage = null;

	imageFile = null;
	current_route = "wg-images";

	// @Watch("page", { deep: true, immediate: true })
	// handlePageChange(val, oldVal) {
	// 	if (val != oldVal) {
	// 		this.fetchImageList();
	// 	}
	// }

	mounted() {
		this.fetchImageList();
	}

	toggle() {
		this.open_modal = !this.open_modal;
	}

	handleDecrement() {
		if (this.page > 1) {
			this.page = parseInt(this.page) - 1;
		} else {
			this.page = 1;
		}
		this.fetchImageList();
	}

	handleIncreament() {
		if (this.page < this.totalPageCount) {
			this.page = parseInt(this.page) + 1;
		} else {
			this.page = parseInt(this.totalPageCount);
		}
		this.fetchImageList();
	}

	calculateNext(totalPage) {
		if (totalPage > 1) {
			var next = this.page;
			for (var i = 0; i < totalPage; i++) {
				if (i > 3) {
					break;
				}
				if (this.page < totalPage) {
					next = parseInt(next) + 1;
					if (next > totalPage) {
						break;
					}
					this.paginationSteps.push(next);
				}
			}
		}
	}

	calculatePrev() {
		if (this.page > 1) {
			var counter = 0;
			for (var i = this.page - 1; i > 0; i--) {
				if (counter > 3) {
					break;
				}
				this.paginationSteps.unshift(i);
				counter += 1;
			}
		}
	}

	calculatePage() {
		const totalPage = Math.ceil(this.count / 16);
		this.totalPageCount = totalPage;
		if (totalPage > 1) {
			this.calculateNext(totalPage);
			this.calculatePrev();
		}
	}

	updatePage(pageNo) {
		this.page = parseInt(pageNo);
		this.fetchImageList();
	}

	fetchImageList() {
		let params = {
			page: this.page,
		};
		this.fetchImages(params).then((data) => {
			this.results = [];
			this.results = data.results;
			this.count = data.count;
			this.paginationSteps = [this.page];
			this.calculatePage();
		});
	}

	handleSelectedImage() {
		// Vue.$toast.success("selected image successfully!", {});
		this.$emit("passSelectedImage", this.selectedImage);
		this.toggle();
	}

	handleCreateNewImage() {
		if (this.imageFile.length < 1) {
			Vue.$toast.error("You need to select atleast 1 image!", {});
			return false;
		}
		var imageUploadFormData = new FormData();
		imageUploadFormData.append(`file`, this.imageFile.file);
		imageUploadFormData.append(`title`, this.imageFile.file.name);

		this.createImages(imageUploadFormData).then((data) => {
			this.results.unshift(data);
			this.count = this.count + 1;
		});
	}

	addPhoto(fieldName, event) {
		this.imageFile = {
			file: event.target.files[0],
			preview: URL.createObjectURL(event.target.files[0]),
		};
		this.$refs.img.value = null;
	}

	selecteImage(img) {
		this.selectedImage = {
			id: img.id,
			file: img.file,
		};
	}
}
</script>

<style scoped lang="scss">
.modal-header {
	display: grid !important;
	grid-template-columns: 6fr 6fr !important;
	gap: 10px;
	div {
		display: flex;
		font-size: 18px;
	}
	.text-right {
		justify-content: right;
		color: red;
	}
}

.image-group {
	display: flex !important;
	gap: 10px !important;
	flex-wrap: wrap !important;
}
.custom-image-item {
	height: 120px !important;
	width: 150px !important;
	cursor: pointer;
	object-fit: cover;
	flex-shrink: 0;
	-webkit-flex-shrink: 0;
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	border: 1px solid #f1f1f1;
}
.custom-image-item:hover {
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
}
.selected-image {
	border: 1px solid red;
	border-radius: 5px;
}
.add-image-button {
	width: max-content;
	cursor: pointer;
	justify-content: center;
	font-weight: 500;
}
.add-user-icon {
	font-size: 20px;
	font-weight: 500;
	margin-right: 5px;
	cursor: pointer;
}
.custom-form {
	display: grid;
	grid-template-columns: 12fr;
	padding: 20px 5px;
	label {
		color: black;
		margin-bottom: 5px;
		font-size: 16px;
	}
}
.mx-datepicker {
	width: 100%;
}

.image-wrapper {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}
</style>
