<template>
	<div class="user-wrapper">
		<div class="top">
			<div class="left">Edit Product</div>
			<div class="right"></div>
		</div>
		<div class="content">
			<ValidationObserver v-slot="{ invalid }">
				<form method="post" @submit.prevent="handleSubmit">
					<div class="left">
						<div class="input-item">
							<label>Name</label>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<input name="name" placeholder="Name" v-model="formData.name" />
								<span v-if="errors[0]">* {{ errors[0] }}</span>
							</ValidationProvider>
						</div>
						<div class="input-item" v-if="categoryList.length > 0">
							<label>Category</label>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<select name="category" v-model="category">
									<option value="">Select Category</option>
									<option
										:value="cat.id"
										v-for="(cat, cidx) in categoryList"
										:key="'category_' + cidx"
									>
										{{ cat.name }}
									</option>
								</select>
								<span class="error" v-if="errors[0]">* {{ errors[0] }}</span>
							</ValidationProvider>
						</div>
						<div class="input-item" v-if="subcategoryList.length > 0">
							<label>Sub Category</label>
							<select name="subcategory" v-model="subcategory">
								<option value="">Select SubCategory</option>
								<option
									:value="scat.id"
									v-for="(scat, sidx) in subcategoryList"
									:key="'scategory_' + sidx"
								>
									{{ scat.name }}
								</option>
							</select>
						</div>
						<div class="input-item" v-if="childSubCategoryList.length > 0">
							<label>Child SubCategory</label>
							<select name="childsubcategory" v-model="childSubCategory">
								<option value="">Select Child SubCategory</option>
								<option
									:value="cscat.id"
									v-for="(cscat, csidx) in childSubCategoryList"
									:key="'cscategory_' + csidx"
								>
									{{ cscat.name }}
								</option>
							</select>
						</div>
						<div class="input-item" v-if="brandList.length > 0">
							<label>Brand</label>
							<select name="brand" v-model="formData.brand">
								<option value="">Select Brand</option>
								<option
									:value="b.id"
									v-for="(b, bidx) in brandList"
									:key="'brand' + bidx"
								>
									{{ b.name }}
								</option>
							</select>
						</div>
						<div class="input-item">
							<label>Product Quantity</label>
							<div class="items">
								<div class="item">
									<label> Quantity </label>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<input
											type="number"
											name="quantity"
											v-model="formData.product_quantity"
											placeholder="Quantity"
										/>
										<span class="error" v-if="errors[0]"
											>* {{ errors[0] }}</span
										>
									</ValidationProvider>
								</div>
								<div class="item">
									<label> Weight </label>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<input
											type="number"
											name="weight"
											v-model="formData.weight"
											placeholder="Weight"
										/>
										<span class="error" v-if="errors[0]"
											>* {{ errors[0] }}</span
										>
									</ValidationProvider>
								</div>
							</div>
						</div>
						<div class="input-item">
							<label>Price</label>
							<div class="items">
								<div class="item">
									<label> Old price </label>

									<input
										type="number"
										name="old price"
										v-model="formData.old_price"
										placeholder="Old price"
									/>
								</div>
								<div class="item">
									<label> Price </label>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<input
											type="number"
											name="price"
											v-model="formData.price"
											placeholder="Price"
										/>
										<span class="error" v-if="errors[0]"
											>* {{ errors[0] }}</span
										>
									</ValidationProvider>
								</div>
							</div>
						</div>
						<div class="input-item richtext">
							<label>Description</label>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<vue-editor v-model="formData.desc" />
								<span v-if="errors[0]">* {{ errors[0] }}</span>
							</ValidationProvider>
						</div>
						<div class="input-item richtext">
							<label>Additional Information</label>
							<vue-editor v-model="formData.additional_information" />
						</div>
						<div class="input-item">
							<label>Status</label>
							<div class="items">
								<div class="item">
									<sui-checkbox
										v-model="formData.is_publish"
										label="Is publish"
									/>
								</div>
								<div class="item">
									<sui-checkbox v-model="formData.in_stock" label="In stock" />
								</div>
							</div>
						</div>
						<div class="input-item richtext">
							<label>Images</label>
							<div class="image-items">
								<div class="item">
									<modal-choose-image @passSelectedImage="getSelectedImage">
									</modal-choose-image>
								</div>
								<div class="item">
									<div class="ui grid">
										<div class="row" v-if="images.length > 0">
											<div
												v-for="(image, idx) in images"
												:key="'image' + idx"
												class="four wide column image-container"
											>
												<sui-image
													:src="image.file"
													style="width: 100%; height: 120px"
												/>
												<i class="close icon btn-btn" @click="removePhoto(idx)">
												</i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="input-item">
							<label>Dimensions</label>
							<div class="items3">
								<div class="item">
									<label> Length </label>
									<input
										type="number"
										name="length"
										v-model="formData.length"
										placeholder="Length"
									/>
								</div>
								<div class="item">
									<label> Width </label>
									<input
										type="number"
										name="Width"
										v-model="formData.width"
										placeholder="Width"
									/>
								</div>
								<div class="item">
									<label> Height </label>
									<input
										type="number"
										name="height"
										v-model="formData.height"
										placeholder="Height"
									/>
								</div>
							</div>
						</div>

						<div class="variants-input" v-if="variantsInput.length > 0">
							<h3>Variants</h3>
							<div class="variant-table">
								<div
									class="variant-row"
									v-for="(item, variantIdx) in variantsInput"
									:key="'variantInput_' + variantIdx"
								>
									<div class="v-input">
										<label>Name</label>
										<input
											class="name-input"
											type="text"
											:value="generateVariantName(item)"
										/>
									</div>
									<div class="v-input">
										<label>Quantity</label>
										<input type="number" v-model="item.quantity" />
									</div>
									<div class="v-input">
										<label>Price</label>
										<input type="number" v-model="item.price" />
									</div>
									<div class="v-input">
										<label>Action</label>
										<button
											type="button"
											@click.prevent="handleVariantUpdate(item)"
										>
											Save
										</button>
									</div>
								</div>
							</div>
						</div>

						<div class="input-item btn">
							<button class="round-orange-btn" :disabled="invalid">
								Publish
							</button>
							<a class="discard-btn" href=""> Discard </a>
						</div>
					</div>
					<div class="right" v-if="renderComponent">
						<h3>Edit Attribute</h3>
						<div class="attr-wrapper">
							<div v-for="(attr, idx) in attributesInput" :key="'attr_' + idx">
								<div class="attr-container">
									<div class="custom-dropdown">
										<div class="inputs">
											<input
												v-model="attr.attributeObj.name"
												type="text"
												@click="toggleAttributeDrop(idx)"
											/>
											<button @click="addAttribute(idx)" type="button">
												Add
											</button>
										</div>
										<div class="drop-content" v-if="attr.attrDropOpen == true">
											<div
												v-for="(attrItem, sidx) in attrList"
												:key="'attrItem_' + sidx"
												class="drop-item"
												@click="handleAttributeListClick(idx, attrItem)"
											>
												{{ attrItem.name }}
											</div>
										</div>
									</div>
									<div
										class="custom-dropdown drop-value"
										v-if="attributesInput[idx].addbtnClicked"
									>
										<div class="inputs">
											<input
												type="text"
												placeholder="Select value"
												@click="toggleAttributeValueDrop(idx)"
											/>
										</div>
										<div class="drop-content" v-if="attr.valDropOpen == true">
											<div
												v-for="(valItem, vidx) in valList"
												:key="'valItem_' + vidx"
												class="drop-item"
												@click="handleAttributeValueListClick(idx, valItem)"
											>
												{{ valItem.value }}
											</div>
										</div>
										<div
											class="show-selected-values"
											v-if="attributesInput[idx].values"
										>
											<div
												v-for="(sItem, seidx) in attributesInput[idx].values
													.selectedValues"
												:key="'selectedVal_' + seidx"
												class="selected-item"
											>
												{{ sItem.value }}
												<span @click="handleAttrValueDelete(idx, seidx)"
													>X</span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<button type="button" @click="handleAddNewAttr">
							Add Attribute
						</button>
					</div>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../../store/utils";
import { API_HOST } from "../../global";
import { NS_USER, NS_PRODUCTS } from "../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../store/mutation.names";
import {
	FETCH_PRODUCT_DETAIL,
	FETCH_CATEGORY,
	FETCH_BRAND,
	FETCH_ALL_BRAND,
	PRODUCT_UPDATE,
	FETCH_ATTRIBUTE_ALL,
	FETCH_ATTRIBUTE_VALUE,
	VARIANT_UPDATE,
} from "../../store/action.names";
import { GET_TOKEN } from "../../store/getter.names";
import { Icon } from "@iconify/vue2";
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import ModalChooseImage from "../../components/common/ModalChooseImage.vue";

@Component({
	name: "ProductAdd",
	components: {
		Icon,
		ValidationProvider,
		ValidationObserver,
		ModalChooseImage,
	},
})
export default class ProductEdit extends Vue {
	@Getter(namespaced(NS_USER, GET_TOKEN)) getToken;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;

	@Action(namespaced(NS_PRODUCTS, FETCH_PRODUCT_DETAIL)) fetchProductDetail;
	@Action(namespaced(NS_PRODUCTS, PRODUCT_UPDATE)) productUpdate;
	@Action(namespaced(NS_PRODUCTS, FETCH_CATEGORY)) fetchCategory;
	@Action(namespaced(NS_PRODUCTS, FETCH_ALL_BRAND)) fetchAllBrand;
	@Action(namespaced(NS_PRODUCTS, FETCH_ATTRIBUTE_ALL)) fetchAttributeAll;
	@Action(namespaced(NS_PRODUCTS, FETCH_ATTRIBUTE_VALUE)) fetchAttributeValue;
	@Action(namespaced(NS_PRODUCTS, VARIANT_UPDATE)) variantUpdate;

	HOST = API_HOST;
	productId = this.$route.params.id;

	category = "";
	subcategory = "";
	childSubCategory = "";
	categoryList = [];
	subcategoryList = [];
	childSubCategoryList = [];
	brandList = [];
	attributesInput = [
		// {
		// 	attrDropOpen: false,
		// 	valDropOpen: false,
		// 	addbtnClicked: false,
		// 	attribute: "",
		// 	attributeObj: {
		// 		name: "",
		// 	},
		// },
	];
	variantsInput = [];

	formData = {
		brand: "",
		buy_1_get_1: false,
		name: "",
		desc: "",
		is_publish: true,
		in_stock: true,
		is_combo: false,
		product_quantity: 10,
		weight: 1,
		additional_information: "",
		old_price: "",
		price: "",
		length: 1,
		width: 1,
		height: 1,
	};

	images = [];
	variants = [];
	attrList = [];
	valList = [];
	renderComponent = true;

	handleVariantUpdate(item) {
		console.log(item);
		this.variantUpdate({
			id: item.id,
			data: {
				price: item.price,
				quantity: item.quantity,
			},
		}).then((data) => {
			this.$toast.success("Variant updated successfully!");
		});
	}

	handleAddNewAttr() {
		this.attributesInput.push({
			attrDropOpen: false,
			valDropOpen: false,
			addbtnClicked: false,
			attribute: "",
			attributeObj: {
				name: "",
			},
		});
	}

	generateVariantName(item) {
		var attrs = item.attributes;
		return attrs.map((attr) => attr.value.value).join("-");
	}

	addAttribute(idx) {
		if (!this.attributesInput[idx].attribute) {
			this.$toast.error("Select attribute from dropdown!");
			return false;
		}
		this.attributesInput[idx]["values"] = {
			selectedValues: [],
			values: [],
		};
		this.attributesInput[idx].addbtnClicked = true;
		this.forceRerender();
	}

	handleAttributeListClick(idx, attrItem) {
		this.attributesInput[idx].attributeObj = attrItem;
		this.attributesInput[idx].attribute = attrItem.id;
		this.attributesInput[idx].attrDropOpen = false;
	}

	toggleAttributeDrop(idx) {
		this.attributesInput[idx].attrDropOpen =
			!this.attributesInput[idx].attrDropOpen;
	}

	toggleAttributeValueDrop(idx) {
		if (this.attributesInput[idx].valDropOpen == false) {
			this.handleFetchAttributeValue(this.attributesInput[idx].attribute, idx);
		}
		this.attributesInput[idx].valDropOpen =
			!this.attributesInput[idx].valDropOpen;
	}

	handleAttributeValueListClick(idx, valueItem) {
		var currentValues = this.attributesInput[idx]["values"]["values"];
		if (currentValues.includes(valueItem.id)) {
			this.$toast.error("Already added this value!");
			return false;
		}
		this.attributesInput[idx]["values"]["selectedValues"].push(valueItem);
		this.attributesInput[idx]["values"]["values"].push(valueItem.id);
		this.attributesInput[idx].valDropOpen = false;
	}

	handleFetchAttributeValue(attributeId, idx) {
		if (!attributeId) {
			this.$toast.error("Select attribute first!");
			this.attributesInput[idx].valDropOpen = false;
			return false;
		}
		this.fetchAttributeValue({ attribute: attributeId }).then((data) => {
			this.valList = data.results;
		});
	}

	handleAttrValueDelete(idx, seidx) {
		this.attributesInput[idx]["values"]["selectedValues"].splice(seidx, 1);
		this.attributesInput[idx]["values"]["values"].splice(seidx, 1);
		this.forceRerender();
	}

	forceRerender() {
		// Removing my-component from the DOM
		this.renderComponent = false;

		this.$nextTick(() => {
			// Adding the component back in
			this.renderComponent = true;
		});
	}

	removePhoto(idx) {
		this.images.splice(idx, 1);
	}

	getSelectedImage(value) {
		//adding emited image to images list
		this.images.push(value);
	}

	categoryFetch() {
		this.fetchCategory({
			has_parent: true,
		}).then((data) => {
			this.categoryList = data;
		});
	}

	brandFetch() {
		this.fetchAllBrand({}).then((data) => {
			this.brandList = data;
		});
	}

	@Watch("category")
	changeCategory(val, oldVal) {
		this.fetchSubCategory(val, "subCategory");
		// this.subcategory = "";
	}

	@Watch("subcategory")
	changeSubCategory(val, oldVal) {
		this.fetchSubCategory(val, "childSubCategory");
		// this.childSubCategory = "";
	}

	fetchSubCategory(categoryId, type) {
		if (type == "subCategory") {
			this.fetchCategory({
				parent: categoryId,
			}).then((data) => {
				this.subcategoryList = data;
			});
		} else {
			this.fetchCategory({
				parent: categoryId,
			}).then((data) => {
				this.childSubCategoryList = data;
			});
		}
	}

	formatAttributeInputForSubmit() {
		var formattedInput = [];
		if (this.attributesInput.length > 0) {
			for (var i = 0; i < this.attributesInput.length; i++) {
				if (this.attributesInput[i]["values"].values.length > 0) {
					var obj = {
						attribute: this.attributesInput[i].attribute,
						values: this.attributesInput[i]["values"].values,
					};
					formattedInput.push(obj);
				}
			}
			return formattedInput;
		}
		return formattedInput;
	}

	handleSubmit() {
		if (this.images.length < 1) {
			Vue.$toast.error("You need to select atleast 1 image!", {});
			return false;
		}

		var category = null;
		if (this.childSubCategory) {
			category = this.childSubCategory;
		} else if (this.subcategory) {
			category = this.subcategory;
		} else {
			category = this.category;
		}

		this.formData.category = category;
		this.formData["images"] = [];

		for (var i = 0; i < this.images.length; i++) {
			this.formData.images.unshift(this.images[i].id);
		}

		if (this.attributesInput.length > 0) {
			var formattedAttributeInput = this.formatAttributeInputForSubmit();
			this.formData["attribute_inputs"] = formattedAttributeInput;
		}

		this.productUpdate({
			formData: this.formData,
			id: this.productId,
		}).then((data) => {
			location.reload();
		});
	}

	fetchProductDetailData() {
		this.fetchProductDetail({ id: this.productId }).then((data) => {
			this.setProductData(data);
		});
	}

	setProductData(product_data) {
		if (product_data) {
			this.formData = {
				brand: product_data.brand ? product_data.brand : "",
				buy_1_get_1: product_data.buy_1_get_1,
				name: product_data.name,
				desc: product_data.desc,
				is_publish: product_data.is_publish,
				in_stock: product_data.in_stock,
				is_combo: product_data.is_combo,
				product_quantity: product_data.product_quantity,
				weight: product_data.weight,
				additional_information: product_data.additional_information,
				old_price: product_data.old_price,
				price: product_data.price,
			};

			// set product dimensions
			if (product_data.dimensions) {
				this.formData.length = product_data.dimensions.length;
				this.formData.width = product_data.dimensions.width;
				this.formData.height = product_data.dimensions.height;
			}

			// set product category
			if (product_data.categories) {
				console.log();
				this.category = product_data.categories.category;
				this.subcategory = product_data.categories.sub_category;
				if (this.category) {
					this.fetchSubCategory(this.category, "subCategory");
				}
				if (this.subcategory) {
					this.fetchSubCategory(this.subcategory, "childSubCategory");
				}
				this.childSubCategory = product_data.categories.child_category;
			}

			// set images
			product_data.images.forEach((element) => {
				this.images.unshift({
					id: element.wg_image_id,
					file: `${this.HOST}${element.image.original.src}`,
				});
			});

			//set attributes
			if (product_data.attributes) {
				this.attributesInput = [];
				const productAttributes = product_data.attributes;
				for (var i = 0; i < productAttributes.length; i++) {
					var o = {
						attrDropOpen: false,
						valDropOpen: false,
						addbtnClicked: true,
						attribute: productAttributes[i].attribute.id,
						attributeObj: productAttributes[i].attribute,
						values: {
							selectedValues: productAttributes[i].values,
							values: productAttributes[i].values.map(function (el) {
								return el.id;
							}),
						},
					};
					this.attributesInput.push(o);
				}
			}

			//set variants input
			if (product_data.variants) {
				this.variantsInput = product_data.variants;
			}
		}
	}

	handleFetchAllAttr() {
		this.fetchAttributeAll({}).then((data) => {
			this.attrList = data;
		});
	}

	mounted() {
		this.setActiveSidebar("products");
		this.setActiveSubmenu("");
		this.categoryFetch();
		this.brandFetch();
		this.fetchProductDetailData();
		this.handleFetchAllAttr();
	}
}
</script>

<style scoped lang="scss">
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.left {
		font-weight: 700;
		font-size: 20px;
	}
	.right {
		display: flex;
		gap: 40px;
		.add-new-user-btn {
			height: 36px;
			min-width: 150px;
			background-color: #edf1fe;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-left: 10px;
			padding-right: 10px;
			color: #5c83f3;
			cursor: pointer;
			font-weight: 500;
			.add-user-icon {
				font-size: 20px;
				font-weight: 500;
				margin-right: 5px;
			}
		}
	}
}
.content {
	span {
		form {
			margin-top: 30px;
			display: grid;
			grid-template-columns: 8fr 4fr;
			gap: 20px;
			.left {
				// border: 1px solid red;
				min-height: 300px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				.input-item {
					display: grid;
					grid-template-columns: 1fr 6fr;
					gap: 15px;
					align-items: center;
					span {
						display: flex;
						flex-direction: column;
					}
					label {
						width: 300px;
						font-weight: 600;
						font-size: 18px;
					}
					input {
						height: 40px;
						padding-left: 15px;
						width: 90%;
					}
					select {
						width: 90%;
						height: 40px;
					}
					.items {
						display: grid;
						grid-template-columns: 1fr 1fr;
						gap: 20px;
						width: 95%;
						label {
							font-size: 16px;
							font-weight: 400;
						}
					}
					.items3 {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;
						gap: 20px;
						width: 95%;
						margin-bottom: 50px;
						label {
							font-size: 16px;
							font-weight: 400;
						}
					}
					.image-items {
						display: grid;
						grid-template-columns: 1fr;
						gap: 20px;
						margin-top: 20px;
						.item {
							.image-btn {
								display: none;
							}
						}
					}
				}
				.richtext {
					align-items: flex-start;
				}
			}
			.right {
				border: 1px solid #f1f1f1;
				min-height: 300px;
				padding: 20px;
				.attr-wrapper {
					.attr-container {
						border: 1px solid #f1f1f1;
						padding: 10px;
					}
				}
			}
		}
	}
}
.image-container {
	position: relative;
	width: 100%;
	margin-bottom: 20px !important;
}

.image-container .btn-btn {
	position: absolute;
	top: 0%;
	left: 80%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	color: black;
	font-size: 16px;
	padding: 12px 24px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	text-align: center;
}
.img-btn {
	font-size: 14px !important;
}
.btn {
	margin-bottom: 50px;
	button {
		cursor: pointer;
	}
}
.discard-btn {
	background-color: red;
	border-radius: 14px;
	padding: 10px;
	padding-left: 25px;
	padding-right: 25px;
	color: white;
	font-size: 16px;
	font-weight: 700;
	text-decoration: none;
	border: none;
	width: 120px;
	text-align: center;
}
.variant-container {
	width: 90%;
	margin: 0 auto;
	background-color: #f2f6ff;
	padding: 15px;
}
.dropdown {
	position: relative;
	width: 100%;
	margin: 0 auto;
}
.dropdown-input,
.dropdown-selected {
	width: 100%;
	padding: 10px 16px;
	border: 1px solid transparent;
	background: #edf2f7;
	line-height: 1.5em;
	outline: none;
	border-radius: 8px;
}
.dropdown-input:focus,
.dropdown-selected:hover {
	background: #fff;
	border-color: #e2e8f0;
}
.dropdown-input::placeholder {
	opacity: 0.7;
}
.dropdown-selected {
	font-weight: bold;
	cursor: pointer;
}
.dropdown-list {
	position: absolute;
	width: 100%;
	max-height: 500px;
	margin-top: 4px;
	overflow-y: auto;
	background: #ffffff;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	z-index: 1000;
}
.dropdown-item {
	display: flex;
	width: 100%;
	padding: 11px 16px;
	cursor: pointer;
}
.dropdown-item:hover {
	background: #edf2f7;
}
.dropdown-item-flag {
	max-width: 24px;
	max-height: 18px;
	margin: auto 12px auto 0px;
}
.custom-dropdown {
	position: relative;
	.inputs {
		display: flex;
		gap: 5px;
		input {
			height: 30px;
			width: 70%;
			padding-left: 10px;
		}
		button {
			height: 30px;
			width: 20%;
		}
	}
	.drop-content {
		position: absolute;
		width: 70%;
		max-height: 300px;
		background-color: #f1f1f1;
		padding: 20px;
		overflow: hidden;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
		gap: 5px;
		z-index: 9999999;
		.drop-item {
			height: 30px;
			width: 100%;
			font-size: 18px;
			cursor: pointer;
		}
	}
	.show-selected-values {
		display: flex;
		gap: 5px;
		flex-direction: row;
		margin-top: 10px;
		.selected-item {
			position: relative;
			padding-left: 10px;
			padding-right: 15px;
			padding-top: 5px;
			padding-bottom: 5px;
			background-color: #f1f1f1;
			span {
				position: absolute;
				top: 0px;
				right: 5px;
				color: red;
				font-size: 10px;
				font-weight: bold;
				cursor: pointer;
			}
		}
	}
}
.drop-value {
	margin-top: 10px;
}

.variants-input {
	min-height: 200px;
	width: 100%;
	border: 1px solid #f1f1f1;
	padding: 10px;
	.variant-table {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		.variant-row {
			display: flex;
			gap: 20px;
			label {
				display: none;
			}
			&:first-child {
				label {
					display: block;
				}
			}
			.v-input {
				display: flex;
				flex-direction: column;
				input {
					height: 40px;
					width: 200px;
					padding-left: 10px;
				}
				.name-input {
					width: 300px;
				}
				button {
					height: 40px;
					width: 100px;
				}
			}
		}
	}
}
</style>
