import { GET_AUTH_HEADER } from "../getter.names";
import { Vue } from "vue-property-decorator";

import {} from "../getter.names";
import { FETCH_JOBS, FETCH_JOBS_DETAIL, UPDATE_JOBS } from "../action.names";
import { FETCH_JOBS_ENDPOINT } from "../endpoints";
import {} from "../mutation.names";
import { NS_USER } from "../namespace.names";
import { namespaced, buildParams } from "../utils";

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    async [FETCH_JOBS]({ commit, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
        let relativeUrl = `${FETCH_JOBS_ENDPOINT}${buildParams(payload)}`;
        axios
          .get(relativeUrl, {
            headers: {
              ...authHeader,
            },
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },

    async [FETCH_JOBS_DETAIL]({ commit, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
        axios
          .get(`${FETCH_JOBS_ENDPOINT}${payload.id}/`, {
            headers: {
              ...authHeader,
            },
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },

    async [UPDATE_JOBS]({ commit, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const authHeader = rootGetters[namespaced(NS_USER, GET_AUTH_HEADER)];
        axios
          .patch(`${FETCH_JOBS_ENDPOINT}${payload.id}/`, payload.formData, {
            headers: {
              ...authHeader,
            },
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
  },

  mutations: {},
};
