<template>
	<div class="user-wrapper">
		<div class="left">
			<div class="top">
				<div class="title">User details</div>
				<!-- <router-link
          to="/"
          class="round-orange-btn"
        >
          Edit now
        </router-link> -->
			</div>
			<div class="content" v-if="data">
				<div class="profile">
					<div class="image" v-if="data.pp">
						<img :src="data.pp" />
					</div>
					<div class="image" v-else>
						<img src="@/assets/images/empty-user.png" />
					</div>
					<div class="name">
						{{ data.full_name }}
					</div>
					<div class="verified-user" v-if="data.is_otp_verified">
						<img src="@/assets/images/verified.png" />
						Verified user
					</div>
					<div class="verified-user" v-else>User not verified</div>
					<div class="verified-user orange" v-if="data.plus_membership">
						<img src="@/assets/images/elite-member.png" />
						Elite member
					</div>
					<div class="verified-user orange supercoin">
						<img src="@/assets/images/super-coin-icon.svg" />
						{{ data.supercoin }} Supercoin
					</div>
					<div style="margin-top: 30px" v-if="$route.params.id == profile.id">
						<a href="#" @click.prevent="handleLogout" class="round-red-btn">
							Logout
						</a>
					</div>
				</div>
				<div class="profile-content">
					<div class="item">
						<div class="label">Fullname</div>
						<div class="data">
							{{ data.full_name }}
						</div>
					</div>
					<div class="item">
						<div class="label">Contact number</div>
						<div class="data">
							{{ data.phone }}
						</div>
					</div>
					<div class="item">
						<div class="label">Email</div>
						<div class="data">
							{{ data.email }}
						</div>
					</div>
					<div class="item">
						<div class="label">Gender</div>
						<div class="data">
							{{ data.gender.toUpperCase() }}
						</div>
					</div>
					<div class="item">
						<div class="label">Date of birth</div>
						<div class="data">
							{{ data.birth_date }}
						</div>
					</div>
					<div class="item">
						<div class="label">Is active</div>
						<div class="data">
							<sui-checkbox toggle v-model="is_active" />
						</div>
					</div>
					<div class="item">
						<div class="label">Is verified</div>
						<div class="data">
							<sui-checkbox toggle v-model="is_verified" />
						</div>
					</div>
					<div class="item">
						<div class="label">Elite member</div>
						<div class="data">
							<sui-checkbox toggle v-model="is_elite" />
						</div>
					</div>
					<div class="item">
						<div class="label">Joining date</div>
						<div class="data">
							{{ this.$dayjs(data.join_date).format("YYYY-MM-DD") }}
						</div>
					</div>
					<div class="item">
						<div class="label">Last login</div>
						<div class="data">
							{{ this.$dayjs(data.last_login).format("YYYY-MM-DD") }}
						</div>
					</div>
					<div class="item">
						<div class="label">Action</div>
						<div class="data">
							<a
								href="#"
								@click.prevent="handleDeleteUser"
								class="round-red-btn"
							>
								Remove this user
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="right">
			<div class="healthcoin-management">
				<div class="title">HealthCoin management</div>
				<ValidationObserver v-slot="{ invalid }">
					<form method="post" @submit.prevent="handleHealthcoinAddRemove">
						<div class="input-item">
							<label>Select type</label>
							<select v-model="healthCoinForm.operation_type">
								<option value="add">Add</option>
								<option value="remove">Remove</option>
							</select>
						</div>
						<div class="input-item">
							<label>* Amount</label>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<input
									type="number"
									v-model="healthCoinForm.amount"
									name="amount"
									placeholder="Amount"
								/>
								<span class="error" v-if="errors[0]">* {{ errors[0] }}</span>
							</ValidationProvider>
						</div>
						<div class="input-item">
							<label>* Reason</label>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<input
									type="text"
									v-model="healthCoinForm.reason"
									name="reason"
									placeholder="Reason"
								/>
								<span class="error" v-if="errors[0]">* {{ errors[0] }}</span>
							</ValidationProvider>
						</div>
						<div class="input-item">
							<button type="submit" class="round-green-btn" :disabled="invalid">
								Submit
							</button>
						</div>
					</form>
				</ValidationObserver>
			</div>
			<div class="stats" v-if="statsData">
				<div class="title">Stats</div>
				<div class="stat-item">
					<div class="left">
						<Icon
							icon="iconoir:google-docs"
							style="color: #5c83f3; font-size: 26px"
						/>
						Total orders
					</div>
					<div class="right">
						{{ statsData.order_count }}
					</div>
				</div>
				<div class="stat-item">
					<div class="left">
						<Icon
							icon="iconoir:google-docs"
							style="color: #5c83f3; font-size: 26px"
						/>
						BDT total spend
					</div>
					<div class="right">
						{{ statsData.spend_amount }}
					</div>
				</div>
				<div class="stat-item">
					<div class="left">
						<Icon
							icon="iconoir:google-docs"
							style="color: #5c83f3; font-size: 26px"
						/>
						HealthCoin
					</div>
					<div class="right">
						{{ data.supercoin }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../store/utils";
import { API_HOST } from "../global";
import { NS_USER } from "../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../store/mutation.names";
import {
	FETCH_USER_DETAIL,
	UPDATE_PROFILE,
	DELETE_USER,
	UPDATE_SUPERCOIN,
	STATS_FOR_USER_DETAIL,
	LOGOUT,
} from "../store/action.names";
import { GET_ACTIVE_SUBMENU, GET_PROFILE } from "../store/getter.names";
import { Icon } from "@iconify/vue2";
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";

@Component({
	name: "UserDetail",
	components: {
		Icon,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class UserDetail extends Vue {
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;

	@Getter(namespaced(NS_USER, GET_ACTIVE_SUBMENU)) getActiveSubmenu;
	@Getter(namespaced(NS_USER, GET_PROFILE)) profile;

	@Action(namespaced(NS_USER, FETCH_USER_DETAIL)) fetchUserDetail;
	@Action(namespaced(NS_USER, UPDATE_PROFILE)) updateProfile;
	@Action(namespaced(NS_USER, DELETE_USER)) deleteUser;
	@Action(namespaced(NS_USER, UPDATE_SUPERCOIN)) updateSupercoin;
	@Action(namespaced(NS_USER, STATS_FOR_USER_DETAIL)) statsForUserDetail;
	@Action(namespaced(NS_USER, LOGOUT)) logout;

	HOST = API_HOST;
	data = null;
	userId = this.$route.params.id;
	is_active = false;
	is_verified = false;
	is_elite = false;
	statsData = null;

	healthCoinForm = {
		operation_type: "add",
		amount: "",
		reason: "",
	};

	handleLogout() {
		this.logout();
	}

	@Watch("is_active")
	handleIsActiveChange(val, oldVal) {
		this.updateProfile({
			id: this.userId,
			is_active: this.is_active,
		}).then((data) => {});
	}

	@Watch("is_verified")
	handleIsVerifiedChange(val, oldVal) {
		this.updateProfile({
			id: this.userId,
			is_otp_verified: this.is_verified,
		}).then((data) => {});
	}

	@Watch("is_elite")
	handleIsEliteChange(val, oldVal) {
		this.updateProfile({
			id: this.userId,
			plus_membership: this.is_elite,
		}).then((data) => {});
	}

	handleDeleteUser() {
		this.$confirm("Are you sure to delete this user?").then(() => {
			this.deleteUser({
				id: this.userId,
			}).then((data) => {
				this.$router.push({ name: "users" });
			});
		});
	}

	handleHealthcoinAddRemove() {
		this.healthCoinForm["id"] = this.userId;
		this.updateSupercoin(this.healthCoinForm).then((data) => {
			this.fetchData();
		});
	}

	fetchData() {
		this.fetchUserDetail({ id: this.userId }).then((data) => {
			this.data = data;
			this.is_active = data.is_active;
			this.is_verified = data.is_otp_verified;
			this.is_elite = data.plus_membership;
		});
		this.statsForUserDetail({ id: this.userId }).then((data) => {
			this.statsData = data;
		});
	}

	mounted() {
		this.setActiveSidebar("users");
		this.setActiveSubmenu("");
		this.fetchData();
	}
}
</script>

<style scoped lang="scss">
.user-wrapper {
	display: grid;
	grid-template-columns: 6fr 2fr;
	// gap: 20px;
}
.left {
	.top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 20px;
	}
	.content {
		// min-height: 600px;
		border-top: 1px solid #d9e1f4;
		margin-top: 30px;
		display: grid;
		grid-template-columns: 2fr 5fr;
		.profile {
			padding: 20px;
			border-right: 1px solid #d9e1f4;
			display: flex;
			align-items: center;
			flex-direction: column;
			.image {
				img {
					height: 120px;
					width: 120px;
					border-radius: 120px;
				}
			}
			.name {
				text-align: center;
				margin-top: 15px;
			}
			.verified-user {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 15px;
				gap: 10px;
				color: #65b781;
				font-weight: 700;
			}
			.orange {
				color: #d47878;
			}
			.supercoin {
				img {
					height: 16px;
					width: 24px;
				}
			}
		}
		.profile-content {
			display: flex;
			flex-direction: column;
			.item {
				height: 50px;
				border-bottom: 1px solid #d9e1f4;
				display: flex;
				gap: 20px;
				align-items: center;
				padding-left: 20px;
				font-size: 16px;
				font-weight: 700;
				.label {
					width: 250px;
				}
			}
		}
	}
}
.right {
	background-color: #f2f6ff;
	border-radius: 20px;
	padding: 15px;
}
.healthcoin-management {
	padding: 10px;
	padding-top: 15px;
	background-color: #f2f6ff;
	box-shadow: 0px 0px 12px rgba(19, 47, 109, 0.43);
	border-radius: 16px;
	form {
		margin-top: 20px;
		.input-item {
			display: flex;
			flex-direction: column;
			gap: 10px;
			margin-bottom: 20px;
			label {
				font-size: 16px;
				color: #636363;
			}
			select {
				height: 40px;
				border: none;
				background-color: white;
				color: black;
				border-radius: 10px;
				padding-left: 10px;
				padding-right: 10px;
			}
			input {
				height: 40px;
				width: 100%;
				padding-left: 10px;
				border: 1px solid #f1f1f1;
				border-radius: 10px;
			}
		}
	}
}
.stats {
	margin-top: 30px;
	.stat-item {
		display: flex;
		justify-content: space-between;
		padding: 10px;
		padding-left: 10px;
		background-color: white;
		margin-top: 20px;
		border-radius: 10px;
		.left {
			display: flex;
			gap: 15px;
			align-items: center;
		}
		.right {
			display: flex;
			align-items: center;
			color: #5c83f3;
			font-size: 20px;
			font-weight: 700;
			border-radius: none;
		}
	}
}
</style>
