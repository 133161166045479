<template>
  <div>
    <div class="body-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "FullPageLayout",
  components: {
  }
})
export default class FullPageLayout extends Vue {
  get layout(){
    return (this.$route.meta.layout);
  }  
}
</script>

<style scoped lang="scss">
  .body-wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

