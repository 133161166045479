<template>
	<div>
		<DashboardComponent />
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../store/utils";
import { API_HOST } from "../global";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../store/mutation.names";
import { NS_USER } from "../store/namespace.names";
import DashboardComponent from "../components/dashboard/DashboardComponent.vue";

@Component({
	name: "Dashboard",
	components: {
		DashboardComponent,
	},
})
export default class Dashboard extends Vue {
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;

	HOST = API_HOST;

	mounted() {
		this.setActiveSidebar("dashboard");
		this.setActiveSubmenu("");
	}
}
</script>

<style scoped lang="scss"></style>
