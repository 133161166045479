<template>
	<div class="user-wrapper">
		<div class="top">
			<div class="left">Warranty Claim ({{ count }})</div>
			<div class="right"></div>
		</div>
		<div class="content" v-if="results.length > 0">
			<TableWarranty v-if="results" :results="results" />
			<div class="c-pagination" v-if="paginationSteps.length > 1">
				<a class="item" @click.prevent="handleDecrement"> Prev </a>
				<a
					:class="{ active: page == pag, item: true }"
					v-for="(pag, idx) in paginationSteps"
					:key="'pag_' + idx"
					@click.prevent="updatePage(pag)"
				>
					{{ pag }}
				</a>
				<a class="item" @click.prevent="handleIncreament"> Next </a>
			</div>
		</div>
		<div v-else>
			<!-- TODO: use a alert snippet  -->

			<warning message="No content available"></warning>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced, deepCopy } from "../../store/utils";
import { API_HOST } from "../../global";
import { NS_USER, NS_WARRANTY_RETURN } from "../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../store/mutation.names";
import { GET_SEARCH_TEXT } from "../../store/getter.names";
import { FETCH_WARRANTY_OR_RETURN_CALIM } from "../../store/action.names";
import { Icon } from "@iconify/vue2";
import TableWarranty from "../../components/tables/warranty/TableWarranty.vue";

@Component({
	name: "PageWarrantyClaim",
	components: {
		Icon,
		TableWarranty,
	},
})
export default class PageWarrantyClaim extends Vue {
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;
	@Action(namespaced(NS_WARRANTY_RETURN, FETCH_WARRANTY_OR_RETURN_CALIM))
	fetchWarrantyOrReturnClaim;
	@Getter(namespaced(NS_USER, GET_SEARCH_TEXT)) getSearchText;

	HOST = API_HOST;
	page = this.$route.query.page ? this.$route.query.page : 1;
	results = [];
	count = "";
	paginationSteps = [this.page];
	totalPageCount = "";
	current_route = "warranty-claim";
	status = "";

	@Watch("$route")
	handleChangeRoute(val, oldVal) {
		this.setStatus();
		this.page = this.$route.query.page ? this.$route.query.page : 1;
		if (val != oldVal) {
			this.fetchData();
			this.paginationSteps = [this.page];
		}
	}
	@Watch("status")
	activateSubMenu(val, oldVal) {
		this.setActiveSubmenu(this.status);
	}

	@Watch("getSearchText")
	handleChangeSearchText(val, oldVal) {
		if (val != oldVal) {
			this.fetchData();
			this.paginationSteps = [this.page];
			this.page = 1;
		}
	}

	setStatus() {
		let sub_route = "";
		if (this.$route) {
			sub_route = this.$route.params.slug;
		}
		if (sub_route && sub_route != "warranty-claim") {
			this.status = sub_route;
			this.current_route = `warranty-claim/${sub_route}`;
		} else {
			this.status = "";
			this.current_route = "warranty-claim";
		}
	}

	handleDecrement() {
		if (this.page > 1) {
			var query = deepCopy(this.$route.query);
			query["page"] = parseInt(this.page) - 1;
			this.$router.push({ name: "warranty-claim", query: query });
		} else {
			var query = deepCopy(this.$route.query);
			query["page"] = 1;
			this.$router.push({ name: "warranty-claim", query: query });
		}
	}

	handleIncreament() {
		if (this.page < this.totalPageCount) {
			var query = deepCopy(this.$route.query);
			query["page"] = parseInt(this.page) + 1;
			this.$router.push({ name: "warranty-claim", query: query });
		} else {
			var query = deepCopy(this.$route.query);
			query["page"] = this.totalPageCount;
			this.$router.push({ name: "warranty-claim", query: query });
		}
	}

	calculateNext(totalPage) {
		if (totalPage > 1) {
			var next = this.page;
			for (var i = 0; i < totalPage; i++) {
				if (i > 3) {
					break;
				}
				if (this.page < totalPage) {
					next = parseInt(next) + 1;
					if (next > totalPage) {
						break;
					}
					this.paginationSteps.push(next);
				}
			}
		}
	}

	calculatePrev() {
		if (this.page > 1) {
			var counter = 0;
			for (var i = this.page - 1; i > 0; i--) {
				if (counter > 3) {
					break;
				}
				this.paginationSteps.unshift(i);
				counter += 1;
			}
		}
	}

	calculatePage() {
		const totalPage = Math.ceil(this.count / 15);
		this.totalPageCount = totalPage;
		if (totalPage > 1) {
			this.calculateNext(totalPage);
			this.calculatePrev();
		}
	}

	updatePage(pageNo) {
		var query = deepCopy(this.$route.query);
		query["page"] = parseInt(pageNo);
		this.$router.push({ name: "warrenty-claim", query: query });
	}

	fetchData() {
		let params = {
			page: this.page,
			type: "warrenty",
		};
		if (this.status) {
			params.status = this.status;
		}

		if (this.getSearchText.length > 0) {
			params["search_text"] = this.getSearchText;
		}

		this.fetchWarrantyOrReturnClaim(params).then((data) => {
			this.results = data.results;
			this.count = data.count;
			this.calculatePage();
		});
	}

	mounted() {
		this.setActiveSidebar("warranty-claim");
		this.setActiveSubmenu("");
		this.setStatus();
		this.fetchData();
	}
}
</script>

<style scoped lang="scss">
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.left {
		font-weight: 700;
		font-size: 20px;
	}
	.right {
		display: flex;
		gap: 40px;
		.user-filter {
			height: 36px;
			width: 200px;
			background-color: #edf1fe;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 10px;
			padding-right: 10px;
			.label {
				font-weight: 500;
				font-size: 16px;
				color: #5c83f3;
			}
			.dropdown-wrapper {
				.dropdown {
					width: 93px;
					height: 28px;
					background-color: #5c83f3;
					color: white;
					font-weight: 500;
					border-radius: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					position: relative;
				}
				.dropdown-content {
					position: absolute;
					width: 93px;
					margin-top: 5px;
					background-color: white;
					z-index: 999;
					box-shadow: 5px 5px 4px 5px #f1f1f1;
					.item {
						padding: 10px;
						cursor: pointer;
					}
				}
			}
		}
		.add-new-user-btn {
			height: 36px;
			min-width: 150px;
			background-color: #edf1fe;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-left: 10px;
			padding-right: 10px;
			color: #5c83f3;
			cursor: pointer;
			font-weight: 500;
			.add-user-icon {
				font-size: 20px;
				font-weight: 500;
				margin-right: 5px;
			}
		}
	}
}
.content {
	margin-top: 30px;
}
.c-pagination {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	margin-top: 15px;
	a {
		color: #737373;
		font-size: 14px;
		font-weight: 600;
		cursor: pointer;
	}
}
.active {
	color: black !important;
}
</style>
