import Vue from "vue";
import VueRouter from "vue-router";

import Dashboard from "../pages/Dashboard";
import LoginPage from "../pages/LoginPage";
import Users from "../pages/Users";
import Products from "../pages/products/Products";
import ProductAdd from "../pages/products/ProductAdd";
import PageProductEdit from "../pages/products/PageProductEdit";
import UserDetail from "../pages/UserDetail";
import PageAttribute from "../pages/products/attribute/PageAttribute.vue";
import PageAttributeAdd from "../pages/products/attribute/PageAttributeAdd.vue";
import PageAttributeDetail from "../pages/products/attribute/PageAttributeDetail.vue";
import PageCategory from "../pages/products/category/PageCategory.vue";
import PageCategoryDetail from "../pages/products/category/PageCategoryDetail.vue";
import PageBrand from "../pages/products/brand/PageBrand.vue";
import PageAddBrand from "../pages/products/brand/PageAddBrand.vue";
import PageUpdateBrand from "../pages/products/brand/PageUpdateBrand.vue";
import PageProductOutOfStock from "../pages/products/out_of_stock/PageProductOutOfStock.vue";
import PageOrderDetail from "../pages/order/PageOrderDetail.vue";

// order pages
import PageOrder from "../pages/order/PageOrder.vue";
import PageAnalytics from "../pages/analytics/PageAnalytics.vue";
import PageWarrantyClaim from "../pages/warranty/PageWarrantyClaim.vue";
import PageDetailWarrantyClaim from "../pages/warranty/PageDetailWarrantyClaim.vue";
import PageDetailReturnClaim from "../pages/return/PageDetailReturnClaim.vue";
import PageReturnClaim from "../pages/return/PageReturnClaim.vue";
import PageMessages from "../pages/messages/PageMessages.vue";
import PageMessageDetails from "../pages/messages/PageMessageDetails.vue";

import PageCoupons from "../pages/coupons/PageCoupons.vue";
import PageCampaigns from "../pages/campaigns/PageCampaigns.vue";
import PageAddCampaign from "../pages/campaigns/PageAddCampaign.vue";
import PageEditCampaign from "../pages/campaigns/PageEditCampaign.vue";
import PageJobApplication from "../pages/job/PageJobApplication.vue";
import PageApplicationDetails from "../pages/job/PageApplicationDetails.vue";
import PageCustomerReview from "../pages/review/PageCustomerReview.vue";
import PageReview from "../pages/review/PageReview.vue";
import PageDetailReview from "../pages/review/PageDetailReview.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "dashboard",
		component: Dashboard,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},
	{
		path: "/users",
		name: "users",
		component: Users,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},
	{
		path: "/users/:id",
		name: "user-detail",
		component: UserDetail,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},
	{
		path: "/products",
		name: "products",
		component: Products,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},
	{
		path: "/products/add",
		name: "product-add",
		component: ProductAdd,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/products/:id/update",
		name: "product-update",
		component: PageProductEdit,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	//category router
	{
		path: "/products/category",
		name: "category",
		component: PageCategory,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/products/category/:id/",
		name: "category-detail",
		component: PageCategoryDetail,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	// attribute router start
	{
		path: "/products/attribute",
		name: "attribute",
		component: PageAttribute,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/products/attribute/add",
		name: "add-attribute",
		component: PageAttributeAdd,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/products/attribute/:id/",
		name: "attribute-detail",
		component: PageAttributeDetail,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},
	// attribute router end

	// brand router start
	{
		path: "/products/brand/",
		name: "brand",
		component: PageBrand,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},
	{
		path: "/products/brand/add",
		name: "brand-add",
		component: PageAddBrand,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},
	{
		path: "/products/brand/:id/update",
		name: "brand-update",
		component: PageUpdateBrand,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},
	// brand router end

	{
		path: "/products/out-of-stocks/",
		name: "out-of-stocks",
		component: PageProductOutOfStock,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	// =========== order router start ============
	{
		path: "/orders/",
		name: "orders",
		component: PageOrder,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},
	{
		path: "/orders/detail/:id/",
		name: "order-detail",
		component: PageOrderDetail,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/orders/:slug",
		name: "order-status-filter",
		component: PageOrder,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	// {
	//   path: "/orders/search-on-forworder/",
	//   name: "search-on-forworder",
	//   component: PageOrder,
	//   meta: {
	//     layout: "default-layout",
	//     loginRequired: true,
	//   },
	// },

	// order router end

	{
		path: "/business-analytics/",
		name: "business-analytics",
		component: PageAnalytics,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/business-analytics/:slug",
		name: "business-analytics-filter",
		component: PageAnalytics,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/customer-reviews/",
		name: "customer-reviews",
		component: PageCustomerReview,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/customer-reviews/:slug",
		name: "order-status-filter",
		component: PageReview,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/customer-reviews/details/:id/",
		name: "review-details",
		component: PageDetailReview,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/warranty-claim/",
		name: "warranty-claim",
		component: PageWarrantyClaim,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/warranty-claim/:slug",
		name: "warranty-claim-filter",
		component: PageWarrantyClaim,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/warranty-claim/detail/:id/",
		name: "warranty-claim-detail",
		component: PageDetailWarrantyClaim,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/return-claim/",
		name: "return-claim",
		component: PageReturnClaim,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/return-claim/:slug",
		name: "warranty-claim-filter",
		component: PageReturnClaim,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/return-claim/detail/:id/",
		name: "return-claim-detail",
		component: PageDetailReturnClaim,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/job-applications",
		name: "job-applications",
		component: PageJobApplication,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},
	{
		path: "/job-applications/:id/",
		name: "job-applications-detail",
		component: PageApplicationDetails,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/admin-messages",
		name: "admin-messages",
		component: PageMessages,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/admin-messages/:id/",
		name: "admin-message-detail",
		component: PageMessageDetails,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/admin-campaigns",
		name: "admin-campaigns",
		component: PageCampaigns,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},
	{
		path: "/admin-campaigns/add",
		name: "admin-campaigns-add",
		component: PageAddCampaign,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/admin-campaigns/:id/update",
		name: "admin-campaigns-update",
		component: PageEditCampaign,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/admin-documents",
		name: "admin-documents",
		// component: PageOrder,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},
	{
		path: "/admin-blogs",
		name: "admin-blogs",
		// component: PageOrder,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},
	{
		path: "/admin-coupons",
		name: "admin-coupons",
		component: PageCoupons,
		meta: {
			layout: "default-layout",
			loginRequired: true,
		},
	},

	{
		path: "/login",
		name: "login",
		component: LoginPage,
		meta: {
			layout: "fullpage-layout",
			loginRequired: false,
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		document.getElementById("app").scrollIntoView();
	},
});

export default router;
