<template>
  <div class="user-wrapper">
    <div>
      <customer-review></customer-review>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Mutation } from "vuex-class";
import { namespaced } from "../../store/utils";
import { NS_USER } from "../../store/namespace.names";
import {
  SET_ACTIVE_SIDEBAR,
  SET_ACTIVE_SUBMENU,
} from "../../store/mutation.names";
import { Icon } from "@iconify/vue2";
import CustomerReview from "../../components/review/CustomerReview.vue";



@Component({
  name: "PageAnalytics",
  components: {
    CustomerReview,
    Icon,
  },
})
export default class PageAnalytics extends Vue {
  @Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
  @Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;
  current_route = "customer-reviews";
  mounted() {
    this.setActiveSidebar("customer-reviews");
    this.setActiveSubmenu("");
  }
}
</script>

<style scoped lang="scss"></style>
