<template>
	<div class="page-wrapper">
		<div class="top">
			<div class="left">Order Detail</div>
			<div class="right">
				<div class="item-value order-status text-blue">
					Order Status
					<span class="status-display text-white">
						{{ orderDetail.status_display }}
					</span>
				</div>
				<div class="item-value text-blue order-status">
					<a href="#" @click="downloadInvoice()">
						<Icon class="add-user-icon" icon="akar-icons:download" />
						Download Invoice
					</a>
				</div>
			</div>
		</div>
		<div class="content">
			<ValidationObserver>
				<form>
					<div class="left">
						<div class="order-item">
							<label>Payment Form</label>
							<div
								class="rigth-align-text order-item-value cash-on-delivery"
								v-if="orderDetail.cash_on_delivery"
							>
								<span class="green-dot">●</span>
								<b>Cash On Delivery</b>
							</div>
							<div
								class="rigth-align-text order-item-value payment-pending"
								v-else
							>
								<div class="payment-pending-text">
									<div>
										<span class="red-dot">●</span>
										<b>Payment Pending</b>
									</div>
								</div>
							</div>
						</div>
						<div class="order-item">
							<label>Total Price</label>
							<div class="order-item-value">
								<b>ট {{ orderDetail.total_price }}</b>
							</div>
						</div>
						<div class="order-item">
							<label>Shipping Charge</label>
							<div
								class="order-item-value"
								v-if="orderDetail.shipping_price_detail"
							>
								<b>
									ট
									{{ orderDetail.shipping_price_detail.total_shipping_price }}
								</b>
							</div>
						</div>
						<div class="order-item">
							<label>Total Amount</label>
							<div
								class="order-item-value"
								v-if="orderDetail.shipping_price_detail"
							>
								<b>
									ট
									{{
										orderDetail.total_price +
										orderDetail.shipping_price_detail.total_shipping_price
									}}
								</b>
							</div>
						</div>

						<div class="order-item">
							<label>Order Status</label>
							<!-- <div class="rigth-align-text order-item-value">
								<div class="checkbox-wrapper">
									<input
										class="custom-checkbox"
										type="checkbox"
										v-model="orderUpdateForm.is_send_update_to_customer"
									/>
									<b>Send update to customer</b>
								</div>
							</div> -->
						</div>

						<div class="order-item">
							<label>Present Status</label>
							<select
								name="orderStatus"
								v-model="orderUpdateForm.order_present_status"
							>
								<option
									class="order-item-value"
									:value="status_key"
									v-for="(status_value, status_key) in orderStatusChoiceList"
									:key="'status_' + status_key"
								>
									{{ status_value }}
								</option>
							</select>
							<div></div>
							<div class="rigth-align-text">
								<sui-button
									@click.prevent="changeOrderStatus()"
									class="custom-button"
								>
									Change Status
								</sui-button>
							</div>
						</div>

						<div class="order-item">
							<label>Shipping Details</label>
							<div
								class="rigth-align-text order-item-value"
								v-if="orderDetail.user_detail"
							>
								<a v-if="orderDetail.user_detail.plus_membership" href="">
									Elite member
								</a>
							</div>
						</div>

						<div class="order-item">
							<label>Order By</label>
							<div class="order-item-value">
								<div>
									<b v-if="orderDetail.shipping_address_detail">
										{{ orderDetail.shipping_address_detail.full_name }}
									</b>
								</div>
							</div>
						</div>
						<div class="order-item">
							<label>Shipping Address</label>
							<div class="order-item-value">
								<div>
									<b>
										{{ orderDetail.shipping_address_display }}
									</b>
								</div>
							</div>
						</div>
						<div class="order-item">
							<label>Contact Number</label>
							<div
								class="order-item-value"
								v-if="orderDetail.shipping_address_detail"
							>
								<b> {{ orderDetail.shipping_address_detail.phone }}</b>
							</div>
						</div>

						<div class="order-item">
							<label>Email</label>
							<div class="order-item-value" v-if="orderDetail.user_detail">
								<b>{{ orderDetail.user_detail.email }}</b>
							</div>
						</div>

						<div class="reword-customer">
							<!-- <sui-button class="custom-button"> Reword Customer </sui-button> -->
							<modal-reword-customer :coin-user="orderDetail.user">
							</modal-reword-customer>
						</div>

						<div class="order-item">
							<label>Select Forworder</label>
							<!-- <div class="rigth-align-text order-item-value">
								<div class="checkbox-wrapper">
									<input
										class="custom-checkbox"
										type="checkbox"
										id="vehicle2"
										name="vehicle2"
										value="Generate Referance ID"
										v-model="orderUpdateForm.is_generate_ref_id"
									/>
									<b>Generate Reference ID</b>
								</div>
							</div> -->
						</div>

						<div class="order-item">
							<label>Forworder Name</label>
							<select
								name="orderStatus"
								v-model="orderUpdateForm.shipping_provide"
							>
								<option
									class="order-item-value"
									:value="provider.id"
									v-for="(provider, index) in orderProviderChoiceList"
									:key="index"
								>
									{{ provider.provider }}
								</option>
							</select>
						</div>

						<div class="order-item">
							<label>Reference ID</label>
							<sui-form-field>
								<input
									v-model="orderUpdateForm.ref_id"
									placeholder="Referance ID"
								/>
							</sui-form-field>
						</div>

						<div class="order-item">
							<label>Shipping Cost</label>
							<div
								class="order-item-value"
								v-if="orderDetail.shipping_price_detail"
							>
								<b>
									ট {{ orderDetail.shipping_price_detail.total_shipping_price }}
								</b>
							</div>
						</div>

						<div class="order-item">
							<label>COD Cost</label>
							<div class="order-item-value">
								<b> </b>
							</div>
						</div>

						<div class="order-item">
							<label>Invoice number : {{ orderDetail.order_id }}</label>
							<div class="rigth-align-text order-item-value">
								<a href="#">
									<b>
										Date: {{ $dayjs(orderDetail.date).format("YYYY-MM-DD") }}
									</b>
								</a>
							</div>
						</div>

						<div class="order-item-value">
							<b>{{ orderDetail.total_product }} Items</b>
						</div>

						<div>
							<div
								v-for="(cart, cart_index) in orderDetail.carts_detail"
								:key="cart_index"
							>
								<card-order-product-item :res="cart"> </card-order-product-item>
							</div>
						</div>

						<div class="total_price_section">
							<div class=""></div>
							<div></div>
							<div class="price_section">
								<div><b>Total</b></div>
								<div class="right_text">
									<b>ট {{ orderDetail.total_price }}</b>
								</div>
							</div>
						</div>

						<div class="button-group">
							<button
								@click.prevent="handleSubmit"
								class="button-item round-green-btn"
							>
								Save Changes
							</button>
							<!-- <button
								@click.prevent="handleDiscard"
								class="button-item round-outline-orange-btn"
							>
								Discard
							</button> -->
							<button
								@click.prevent="handleDeleteOrder"
								class="button-item round-red-btn"
							>
								Delete this order
							</button>
						</div>

						<!-- <div class="order-logs">
							<p class="log-header">Order Log</p>

							<div class="log-item">
								<span class="log-icon"> ■ </span>
								<span class="log-message"
									>Lorem ipsum dolor sit amet. Sit quidem tenetur in nisi
									laboriosam ea iusto quis sit numquam error ut quas accusantium
									qui iure delectus.</span
								>
							</div>
							<div class="log-item">
								<span class="log-icon"> ■ </span>
								<span class="log-message"
									>Lorem ipsum dolor sit amet. Sit quidem tenetur in nisi
									laboriosam ea iusto quis sit numquam error ut quas accusantium
									qui iure delectus.</span
								>
							</div>
							<div class="log-item">
								<span class="log-icon"> ■ </span>
								<span class="log-message"
									>Lorem ipsum dolor sit amet. Sit quidem tenetur in nisi
									laboriosam ea iusto quis sit numquam error ut quas accusantium
									qui iure delectus.</span
								>
							</div>
						</div> -->
					</div>
					<div class="right">
						<div v-if="orderDetail">
							<div class="wrapper-note">
								<div class="admin-note-header">
									<h2>Order Note</h2>
									<Icon class="minus-icon" icon="akar-icons:minus" />
								</div>
								<div class="admin-note-detail">
									<sui-form class="custom-form">
										<sui-form-field>
											<label class="note-form-level">Admin note</label>
											<textarea v-model="orderUpdateForm.admin_order_note">
											</textarea>
										</sui-form-field>
									</sui-form>
								</div>
							</div>
						</div>
						<div v-if="orderDetail">
							<message-to-customer :selected-order="orderDetail">
							</message-to-customer>
						</div>
					</div>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../../store/utils";
import { API_HOST } from "../../global";
import { NS_USER, NS_ORDERS } from "../../store/namespace.names";
import {
	SET_ACTIVE_SIDEBAR,
	SET_ACTIVE_SUBMENU,
} from "../../store/mutation.names";
import {
	FETCH_ORDER_DETAIL,
	FETCH_ORDER_FORM_OPTIONS_DATA,
	UPDATE_ORDER,
	DELETE_ORDER,
} from "../../store/action.names";
import { Icon } from "@iconify/vue2";
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";

import CardOrderProductItem from "../../components/tables/order/CardOrderProductItem.vue";
import OrderNote from "../../components/tables/order/OrderNote.vue";
import MessageToCustomer from "../../components/tables/order/MessageToCustomer.vue";
import ModalRewordCustomer from "../../components/tables/order/ModalRewordCustomer.vue";

@Component({
	name: "PageOrderDetail",
	components: {
		MessageToCustomer,
		OrderNote,
		CardOrderProductItem,
		ValidationProvider,
		ValidationObserver,
		Icon,
		ModalRewordCustomer,
	},
})
export default class PageOrderDetail extends Vue {
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SIDEBAR)) setActiveSidebar;
	@Mutation(namespaced(NS_USER, SET_ACTIVE_SUBMENU)) setActiveSubmenu;
	@Action(namespaced(NS_ORDERS, FETCH_ORDER_DETAIL)) fetchOrderDetail;
	@Action(namespaced(NS_ORDERS, FETCH_ORDER_FORM_OPTIONS_DATA))
	fetchOrderFormOptionsData;
	@Action(namespaced(NS_ORDERS, UPDATE_ORDER)) updateOrder;
	@Action(namespaced(NS_ORDERS, DELETE_ORDER)) deleteOrder;

	HOST = API_HOST;
	data = null;
	orderId = this.$route.params.id;
	orderDetail = {};
	orderStatusChoiceList = [];
	orderProviderChoiceList = [];

	orderUpdateForm = {
		order_present_status: null,
		is_send_update_to_customer: true,
		shipping_provide: null,
		is_generate_ref_id: true,
		ref_id: "",
		admin_order_note: "",
	};

	changeOrderStatus() {
		let updatedOrderData = {
			id: this.orderDetail.id,
			formData: {
				order_present_status: this.orderUpdateForm.order_present_status,
				is_send_update_to_customer:
					this.orderUpdateForm.is_send_update_to_customer,
			},
		};
		this.updateOrder(updatedOrderData).then((data) => {
			this.$toast.success("Order status updated successfully!", {});
			this.setData(data);
		});
	}

	handleSubmit() {
		var f = this.orderUpdateForm;
		delete f["order_present_status"];
		let updatedOrderData = {
			id: this.orderDetail.id,
			formData: f,
		};
		this.updateOrder(updatedOrderData).then((data) => {
			Vue.$toast.success("Order updated successfully!", {});
			this.setData(data);
		});
	}

	handleDiscard() {
		this.setData(this.orderDetail);
	}

	handleDeleteOrder() {
		this.$confirm("Are you sure to delete this order?").then(() => {
			this.deleteOrder({
				id: this.orderDetail.id,
			}).then((data) => {
				Vue.$toast.error("Order deleted successfully!", {});
				setTimeout(() => {
					console.log("Redirecting .......");
					this.$router.push({ name: "orders" });
				}, 2000);
			});
		});
	}

	fetchData() {
		this.fetchOrderDetail({ id: this.orderId }).then((data) => {
			this.setData(data);
		});
		this.fetchOrderFormOptionsData().then((data) => {
			this.orderStatusChoiceList = data.order_status_list;
			this.orderProviderChoiceList = data.providers_list;
		});
	}

	downloadInvoice() {
		let download_url = `https://healthway.com.bd/invoice/pdf/${this.orderDetail.order_id}`;
		window.location = download_url;
	}

	setData(data) {
		this.orderDetail = data;
		this.orderUpdateForm.order_present_status =
			this.orderDetail.status_detail.type;
		this.orderUpdateForm.admin_order_note = this.orderDetail.admin_order_note;
		if (this.orderDetail.shipping_info_detail) {
			this.orderUpdateForm.shipping_provide =
				this.orderDetail.shipping_info_detail.shipping_provider;
			this.orderUpdateForm.ref_id =
				this.orderDetail.shipping_info_detail.ref_id;
		}
	}

	mounted() {
		this.setActiveSidebar("orders");
		this.setActiveSubmenu("");
		this.fetchData();
	}
}
</script>

<style scoped lang="scss">
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.item-value {
		font-size: 18px;
	}
	.text-blue {
		color: #5b83f3;
	}
	.text-white {
		color: white;
	}
	.left {
		font-weight: 700;
		font-size: 20px;
	}

	.order-status {
		background: #edf1fe;
		padding: 10px 15px;
		border-radius: 10px;
		.status-display {
			margin-left: 10px;
			background: #5b83f3;
			padding: 5px 15px;
			border-radius: 10px;
		}
	}
	.right {
		display: flex;
		gap: 40px;
		.add-new-user-btn {
			height: 36px;
			min-width: 150px;
			background-color: #edf1fe;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-left: 10px;
			padding-right: 10px;
			color: #5c83f3;
			cursor: pointer;
			font-weight: 500;
			.add-user-icon {
				font-size: 20px;
				font-weight: 500;
				margin-right: 5px;
			}
		}
	}
}
.content {
	span {
		form {
			margin-top: 30px;
			display: grid;
			grid-template-columns: 8fr 4fr;
			gap: 20px;
			.left {
				// border: 1px solid red;
				min-height: 300px;
				display: flex;
				flex-direction: column;
				gap: 20px;

				.total_price_section {
					display: grid;
					grid-template-columns: 1fr 5fr 2fr;
					gap: 10px;
					text-decoration: none;
					.price_section {
						display: grid;
						gap: 10px;
						grid-template-columns: 5fr 5fr;
						div {
							display: flex;
						}
						.right_text {
							margin-right: 10px;
							justify-content: right;
						}
					}
				}
				.order-item {
					display: grid;
					grid-template-columns: 1fr 6fr;
					gap: 15px;
					align-items: center;
					span {
						display: flex;
						flex-direction: column;
					}
					label {
						width: 300px;
						font-weight: 600;
						font-size: 18px;
					}
					input {
						height: 40px;
						padding-left: 15px;
						width: 90%;
					}
					select {
						width: 90%;
						height: 40px;
					}
					.gray-background {
						background: #e8e8e8 !important;
						border-radius: 8px;
						padding: 10px 20px;
						width: 90%;
					}
					.order-item-value {
						font-size: 18px;
					}
					.cash-on-delivery {
						display: flex;
						color: #65b781;
						justify-content: right;
						b {
							margin-left: 5px;
						}
					}
					.payment-pending-text {
						display: flex;
						justify-content: right;
						div {
							b {
								margin-left: 5px;
								color: #e98679;
							}
							display: flex;
							justify-content: right;
							display: flex;
							border-style: solid;
							border-width: 3px;
							border-image: linear-gradient(#d23b11, #d23b11, #d23b11, #d23b11)
									60,
								50;
							padding: 10px 35px;
							border-radius: 10px;
						}
					}
					.items {
						display: grid;
						grid-template-columns: 1fr 1fr;
						gap: 20px;
						width: 95%;
						label {
							font-size: 16px;
							font-weight: 400;
						}
					}
					.items3 {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;
						gap: 20px;
						width: 95%;
						margin-bottom: 50px;
						label {
							font-size: 16px;
							font-weight: 400;
						}
					}
					.image-items {
						display: grid;
						grid-template-columns: 1fr;
						gap: 20px;
						margin-top: 20px;
						.item {
							.image-btn {
								display: none;
							}
						}
					}
				}
				.richtext {
					align-items: flex-start;
				}
			}
			.right {
				// border: 1px solid blue;
				min-height: 300px;
			}
		}
	}
}

.btn {
	margin-bottom: 50px;
	button {
		cursor: pointer;
	}
}

.dropdown {
	position: relative;
	width: 100%;
	margin: 0 auto;
}
.dropdown-input,
.dropdown-selected {
	width: 100%;
	padding: 10px 16px;
	border: 1px solid transparent;
	background: #edf2f7;
	line-height: 1.5em;
	outline: none;
	border-radius: 8px;
}
.dropdown-input:focus,
.dropdown-selected:hover {
	background: #fff;
	border-color: #e2e8f0;
}
.dropdown-input::placeholder {
	opacity: 0.7;
}
.dropdown-selected {
	font-weight: bold;
	cursor: pointer;
}
.dropdown-list {
	position: absolute;
	width: 100%;
	max-height: 500px;
	margin-top: 4px;
	overflow-y: auto;
	background: #ffffff;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	z-index: 1000;
}
.dropdown-item {
	display: flex;
	width: 100%;
	padding: 11px 16px;
	cursor: pointer;
}
.dropdown-item:hover {
	background: #edf2f7;
}
.dropdown-item-flag {
	max-width: 24px;
	max-height: 18px;
	margin: auto 12px auto 0px;
}
.rigth-align-text {
	text-align: right;
	width: 90%;
}
.reword-customer {
	text-align: right;
	width: 94%;
}
.custom-button {
	background: #65b781 !important;
	color: white !important;
	border-radius: 10px !important;
}
.custom-primary-checkbox {
	background: #65b781 !important;
	color: white !important;
}

.button-item {
	margin: 0px 10px;
}
.order-logs {
	margin-bottom: 20px;
	.log-header {
		color: #41444c;
		font-size: 18px;
	}
	.log-item {
		padding: 10px 0px;
		.log-icon {
			font-size: 25px;
			color: #ec7e4c;
		}
		.log-message {
			font-size: 18px;
			margin-left: 5px;
			font-weight: 400;
		}
	}
}

.checkbox-wrapper {
	display: flex;
	align-items: center;
	justify-content: right;
	.custom-checkbox {
		margin-right: 10px;
		height: 25px !important;
		width: 25px !important;
		background: #65b781 !important;
	}
}

.wrapper-note {
	background: #f2f6ff;
	.admin-note-header {
		background: #12233a;
		color: white;
		padding: 15px;
		border-radius: 20px 20px 0px 0px;
		display: flex;
		h2 {
			margin-bottom: 0px;
		}
		.minus-icon {
			margin-left: auto;
			display: flex;
			justify-content: center;
			text-align: center;
			font-size: 30px;
		}
	}
	.admin-note-detail {
		.custom-form {
			margin-top: 10px;
			display: grid;
			grid-template-columns: 12fr;
		}
		padding: 20px;
		.note-form-level {
			font-size: 18px;
			color: #86878a;
		}
	}
}
</style>
